import React, { useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import axios from 'axios';
import { FaVideo } from 'react-icons/fa';
import EdictCourseLayout from '../../components/admin/EdictCourseLayout';

const EdictPromotion = () => {
  const params = useParams();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const { id } = params;

  const [promotion, setPromotion] = useState();
  const [uploadLoading, setuploadLoading] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
    refetch,
  } = useGetCourseQuery(id);

  useEffect(() => {
    if (getSuccess) {
      setPromotion(data.promotion);
    }
  }, [getSuccess]);

  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();

  const updateField = async () => {
    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'promotion',
        value: promotion,
      }).unwrap();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  const uploadVideo = async (file) => {
    const formData = new FormData();
    formData.append('video', file);
    setuploadLoading(true);

    try {
      const res = await axios.post(
        'http://85.31.236.195:5000/api/upload-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res) {
        setPromotion(res.data.fileUrl);
      }

      setuploadLoading(false);
    } catch (error) {
      setuploadLoading(false);
      console.error('Erreur lors du téléchargement:', error);
    }
  };
  return (
    <EdictCourseLayout id={id}>
      {uploadLoading ? (
        <div className=' pt-4 w-[20%]'>
          <Spinner />
        </div>
      ) : (
        <div className=' w-full '>
          <label>Uploader une video: </label>
          <label
            htmlFor={`video-upload`}
            className='cursor-pointer mt-2 w-full flex items-center justify-center px-4 py-2 bg-gray-200  rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:bg-gray-400 transition'
          >
            <FaVideo className='mr-2' />
            {promotion ? 'Modifier la vidéo' : '+ vidéo'}
          </label>
          <input
            id={`video-upload`}
            type='file'
            accept='video/*'
            className='hidden'
            onChange={(event) => {
              const file = event.target.files[0];
              if (file) {
                uploadVideo(file);
              }
            }}
          />

          <button
            onClick={updateField}
            className=' px-4 py-2 mt-5 bg-gray-200 rounded-lg flex items-center space-x-1'
          >
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                class='size-4'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='m4.5 12.75 6 6 9-13.5'
                />
              </svg>
            </span>
            <span>Enregistrer</span>
          </button>
        </div>
      )}
    </EdictCourseLayout>
  );
};

export default EdictPromotion;

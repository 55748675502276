import React from 'react';
import Title from '../utilities/Title';
import CertificatItem from './CertificatItem';
import certificatImg from '../../assets/certificat.jpeg';

const Certificat = ({ data }) => {
  return (
    <div className=' my-5'>
      <Title text='Certificat à partager' />
      <div className=' flex-col flex lg:flex-row space-x-0 lg:space-x-4 py-2'>
        <img src={certificatImg} alt='certicat' className=' rounded-md h-36' />

        <div>
          <h4 className=' font-medium'>Certificat d’accomplissement</h4>
          <ul className='flex flex-col space-y-1 my-2'>
            <CertificatItem
              text=' Affichez sur votre profil LinkedIn dans la rubrique “Licences et
                certificats”'
            />
            <CertificatItem
              text='Téléchargez ou imprimez au format PDF pour partager avec
                d’autres personnes'
            />
            <CertificatItem text='Partagez comme image en ligne pour afficher votre compétence' />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Certificat;

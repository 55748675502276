import React from 'react';
import { useState } from 'react';
import FullButton from '../utilities/FullButton';
import { useNavigate } from 'react-router-dom';

const HeaderCourse = ({ setTitle, title }) => {
  const navigate = useNavigate();
  return (
    <div className=' space-x-10 mb-3 flex items-center '>
      <input
        type='text'
        placeholder='Rechercher un cours'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className='p-2 border rounded flex-1'
      />
      <button
        className=' md:hidden text-[]  font-medium px-4 py-2 rounded-md bg-[#d1d5db]'
        onClick={() => navigate('/admin/create-cours/title')}
      >
        +
      </button>

      <button
        className=' hidden md:block text-[]  font-medium px-4 py-2 rounded-md bg-[#d1d5db]'
        onClick={() => navigate('/admin/create-cours/title')}
      >
        + Cours
      </button>
    </div>
  );
};

export default HeaderCourse;

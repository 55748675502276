import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import { useGetNewStudentsAssessmentsQuery } from '../../redux/slices/studentAssementApiSlice';
import Spinner from '../../components/spinner/Spinner';
import AdminLayout from '../../components/admin/AdminLayout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const StudentEvaluations = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const navigate = useNavigate();
  const {
    data = [],
    isLoading,
    isError,
    isSuccess,
  } = useGetNewStudentsAssessmentsQuery(token);

  if (isLoading) {
    return (
      <div className=' h-[90vh] flex items-center justify-center w-full'>
        <Spinner />
      </div>
    );
  }

  return (
    <AdminLayout>
      <div className=' w-full'>
        <h2 className='text-2xl font-semibold text-gray-800 mb-6'>
          Évaluations des Étudiants
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
          {data.length > 0 ? (
            data.map((assessment) => (
              <div
                key={assessment._id}
                className='bg-gray-100 p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-200'
              >
                <h3 className='text-lg font-semibold text-gray-700 mb-2'>
                  {assessment.user?.name || 'Nom Indisponible'}
                </h3>
                <p className='text-sm text-gray-500 mb-4'>
                  Cours :{' '}
                  {assessment.assessment.course?.title || 'Cours Indisponible'}
                </p>
                <p className='text-sm text-gray-500 mb-4 capitalize'>
                  Type : {assessment.type}
                </p>
                <div className='flex items-center justify-between'>
                  <span className='text-sm text-gray-600'>
                    Date d'envoi :{' '}
                    {new Date(assessment.createdAt).toLocaleDateString()}
                  </span>
                  <span>
                    {assessment.completed ? (
                      <FaCheckCircle className='text-green-500' />
                    ) : (
                      <FaTimesCircle className='text-red-500' />
                    )}
                  </span>
                </div>
                <div className='mt-4'>
                  <button
                    onClick={() =>
                      navigate(`/admin/evaluation/${assessment._id}`)
                    }
                    className='w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200'
                  >
                    Voir Détails
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className='col-span-full text-start text-gray-500'>
              Aucune évaluation disponible
            </p>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default StudentEvaluations;

import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  useAddFaqsMutation,
  useDeleteFaqsMutation,
  useGetFaqsQuery,
} from '../../redux/slices/faqsApiSlice';
import FaqsCard from '../../components/faqs/FaqsCard';
import EdictCourseLayout from '../../components/admin/EdictCourseLayout';

const EdictEvaluation = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const { id } = useParams();

  const [addFaqs, { isLoading, isSuccess, isError }] = useAddFaqsMutation();

  const [deleteFaqs, { isSuccess: deleteSuccess }] = useDeleteFaqsMutation();
  const {
    data: FAQ,
    isLoading: loading,
    isSuccess: success,
    refetch,
  } = useGetFaqsQuery({ token, id });

  const [faqs, setFaqs] = useState([]);

  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');

  useEffect(() => {
    if (success) {
      setFaqs(FAQ);
    }
  }, [success, FAQ]);

  useEffect(() => {
    if (deleteSuccess) {
      refetch();
    }
  }, [deleteSuccess, refetch]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      setResponse('');
      setQuestion('');
    }
  }, [isSuccess]);

  const addComments = async () => {
    try {
      await addFaqs({ question, response, courseId: id, token }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHandler = async (fasqId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      try {
        await deleteFaqs({ token, fasqId }).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <main className='flex-1'>
      <EdictCourseLayout id={id}>
        <div className='w-full'>
          <div className='p-6 bg-white rounded-lg shadow-sm'>
            <h4 className='text-xl font-semibold mb-4'>Question & Réponse</h4>

            <div className='flex flex-col mb-4'>
              <label htmlFor='question' className='mb-2 font-medium'>
                Question
              </label>
              <input
                type='text'
                id='question'
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder='Saisissez la question'
                className='p-2 border rounded-lg mb-4'
              />

              <label htmlFor='response' className='mb-2 font-medium'>
                Réponse
              </label>

              <ReactQuill
                theme='snow'
                value={response}
                onChange={(value) => setResponse(value)}
                className='mb-4'
              />

              <button
                onClick={addComments}
                className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
              >
                Ajouter FAQ
              </button>
            </div>
          </div>
          <div className=' w-full mt-2'>
            <h3 className='text-lg font-medium mb-4'>FAQs</h3>
            <ul className=''>
              {faqs.map((question, index) => (
                <FaqsCard
                  isAdmin={true}
                  onDelete={deleteHandler}
                  key={index}
                  question={question}
                />
              ))}
            </ul>
          </div>
        </div>
      </EdictCourseLayout>
    </main>
  );
};

export default EdictEvaluation;

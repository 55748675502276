const FullButton = ({ onClick, type, text }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className='bg-[#00075e] px-5 py-2 rounded-md text-white'
    >
      {text}
    </button>
  );
};

export default FullButton;

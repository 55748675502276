import React from 'react';
import Title from '../utilities/Title';
import { Link } from 'react-router-dom';
import ConnexeItem from './ConnexeItem';
import { formatTime } from '../../utils/utils';

const ConnexeCours = ({ courseBycategory }) => {
  return (
    <div className=' py-4'>
      <Title text='Cours connexes' />

      {courseBycategory.map((course, index) => (
        <ConnexeItem
          imageUrl={course.imageUrl}
          title={course.title}
          category={course.category}
          temps={formatTime(course.time)}
          key={course._id}
          courseId={course._id}
        />
      ))}
    </div>
  );
};

export default ConnexeCours;

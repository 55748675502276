import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import ErrorMessage from '../message/ErrorMessage';
import {
  useGetNewStudentAssessmentQuery,
  useUpdateStudentAssessmentMutation,
} from '../../redux/slices/studentAssementApiSlice';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';

const AssessmentDetails = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();

  const [score, setScore] = useState('');
  const { data, isLoading, isSuccess, isError } =
    useGetNewStudentAssessmentQuery({ token, id });

  const [
    updateStudentAssessment,
    { isSuccess: success, isError: error, isLoading: loading },
  ] = useUpdateStudentAssessmentMutation();

  useEffect(() => {
    if (error) {
      setErrorMessage("Erreur de mise a jour de l'evaluation");
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      navigate(-1);
    }
  }, [success]);

  const handleScoreSubmit = async () => {
    try {
      await updateStudentAssessment({ score, token, id }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading)
    return (
      <div className=' w-full h-[80vh] flex justify-center items-center '>
        <Spinner />
      </div>
    );
  if (isError)
    return (
      <div className=' min-h-[80vh] items-center justify-center flex '>
        <div>
          <ErrorMessage message={"Erreur de recuperation de l'evaluation"} />{' '}
        </div>
      </div>
    );

  return (
    <AdminLayout>
      <div className=' mb-8 w-full p-8 bg-gray-50 '>
        <h2 className='text-2xl font-semibold text-gray-800 mb-6'>
          Détails de l'Évaluation
        </h2>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {data && (
          <div className='space-y-6'>
            <div>
              <h3 className='text-xl font-semibold text-gray-700'>
                Évaluation de {data.user?.name || 'Étudiant(e)'}
              </h3>
              <p className='text-gray-600'>
                Cours : {data.assessment?.course?.title || 'N/A'}
              </p>
              <p className='text-gray-600 capitalize'>Type : {data.type}</p>
              <p className='text-gray-600'>
                Date : {new Date(data.createdAt).toLocaleDateString()}
              </p>
              <p className='text-gray-600'>
                Durée limite : {data.assessment.timeLimit || 'Pas de limite'}{' '}
                minutes
              </p>
            </div>

            <div className=' p-4 rounded-lg shadow-inner'>
              <h4 className='text-lg font-semibold text-gray-700 mb-4'>
                Réponses de l'Étudiant(e)
              </h4>
              {data.dropDownQuestions &&
                data.dropDownQuestions.map((question, index) => (
                  <div key={index} className='mb-4'>
                    <p className='font-semibold text-gray-700'>
                      Question : {question.question}
                    </p>
                    <p className='text-gray-600'>
                      Réponse : {question.userAnswer}
                    </p>
                  </div>
                ))}
              {data.multiChoice && data.multiChoice.question && (
                <div className='mb-4'>
                  <p className='font-semibold text-gray-700'>
                    Question (choix multiples) : {data.multiChoice.question}
                  </p>
                  <p className='text-gray-600'>
                    Réponses sélectionnées :{' '}
                    {data.multiChoice.userAnswer.join(', ')}
                  </p>
                </div>
              )}
              {data.answer && (
                <div className='mb-4'>
                  <p className='font-semibold text-gray-700'>Réponse libre :</p>
                  <p className='text-gray-600'>{data.answer}</p>
                </div>
              )}
            </div>

            <div className='flex flex-col sm:flex-row sm:items-center gap-4'>
              <input
                type='number'
                value={score}
                onChange={(e) => setScore(e.target.value)}
                placeholder='Entrez le score'
                className='p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#28467d]'
              />

              {loading ? (
                <Spinner />
              ) : (
                <button
                  onClick={handleScoreSubmit}
                  className='bg-[#244177] text-white px-4 py-2 rounded-lg hover:bg-[#213b6c] transition-colors duration-200'
                >
                  Enregistrer le Score
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AssessmentDetails;

import { useState } from 'react';
import AdminCoursItem from '../../components/admin/AdminCoursItem';
import Pagination from '../../components/pagination/Pagination';
import HeaderCourse from '../../components/admin/HeaderCourse';
import { useGetTutorCoursesQuery } from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import AuthLayout from '../../components/auth/AuthLayout';
import { useSelector } from 'react-redux';

const TutorCourse = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const [title, setTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const { data, isLoading, isSuccess, refetch } = useGetTutorCoursesQuery({
    currentPage,
    title,
    token,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <main>
      <AuthLayout>
        <div className=' mt-3 md:mt-0 mb-3 w-full'>
          <HeaderCourse title={title} setTitle={setTitle} />
          {errorMessage && (
            <div className='w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
              <span className='flex text-red-600'>{errorMessage}</span>
            </div>
          )}
          {isLoading ? (
            <Spinner />
          ) : data?.courses?.length > 0 ? (
            <div className=' w-full grid-rows-2  grid-cols-1 grid md:grid-cols-2 xl:grid-cols-3 gap-6'>
              <>
                {data?.courses?.map((item) => (
                  <AdminCoursItem
                    refetch={refetch}
                    item={item}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    key={item.id}
                  />
                ))}
              </>
            </div>
          ) : (
            <div>
              <h2>Aucune cours dispobile</h2>
            </div>
          )}

          {data && data.courses && data.courses.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={data?.pagination?.totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </AuthLayout>
    </main>
  );
};

export default TutorCourse;

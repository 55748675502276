import AuthLayout from '../../components/auth/AuthLayout';
import CertificatCard from '../../components/certificat/CertificatCard';
import { useSelector } from 'react-redux';
import { useGetStudentCertificatsQuery } from '../../redux/slices/certicatApiSlice';

const CourseCertificat = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const {
    data: certificats = [],
    isSuccess: certificatsSuccess,
    isLoading: certificatsLoading,
    refetch: certificatsRefetch,
  } = useGetStudentCertificatsQuery({ token, studentId: user?._id });

  const backUrl = '/admin/etudiants';
  return (
    <AuthLayout backUrl={backUrl}>
      <div className='mb-8 w-full'>
        <h2 className=' text-xl  md:text-3xl font-bold mb-6 text-gray-800'>
          Certificats Obtenus
        </h2>
        {certificats.length === 0 ? (
          <p className='text-start text-gray-500'>
            Aucun certificat obtenu pour le moment.
          </p>
        ) : (
          <div className='grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {certificats.map((certificat) => (
              <CertificatCard key={certificat._id} certificat={certificat} />
            ))}
          </div>
        )}
      </div>
    </AuthLayout>
  );
};

export default CourseCertificat;

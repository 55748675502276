import { useEffect, useState } from 'react';
import DetailsBanner from '../../components/details/DetailsBanner';
import DetailsLayout from '../../components/details/DetailsLayout';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { setSideBar } from '../../redux/slices/stateSlice';
import {
  useGetCourseQuery,
  useGetCoursesQuery,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useGetReviewsQuery } from '../../redux/slices/reviewApiSlice';

const CoursDetails = () => {
  const { id } = useParams();
  const {
    data,
    isSuccess,
    isLoading,
    refetch,
    isError,
    isLoading: loading,
  } = useGetCourseQuery(id);
  const {
    data: reviews,
    isLoading: reviewsLoading,
    isSuccess: reviewSuccess,
    isError: errorLoading,
  } = useGetReviewsQuery(data?._id);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 1024) {
        dispatch(setSideBar(false));
      } else {
        dispatch(setSideBar(true));
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch, location.pathname]);

  const {
    data: courses,
    isError: courseError,
    isLoading: courseLoading,
    isSuccess: courseSuccess,
  } = useGetCoursesQuery({ currentPage: '', title: '' });

  const courseByCategory =
    courses && data && courses.courses
      ? courses.courses
          .filter((c) => c.category === data.category && c._id !== data._id)
          .slice(0, 3)
      : [];

  const otherCourses =
    courses && data && courses.courses
      ? courses.courses.filter((c) => c._id !== data._id).slice(0, 3)
      : [];

  return (
    <>
      {isLoading ? (
        <div className=' h-[40vh] flex justify-center items-center w-full'>
          <Spinner />
        </div>
      ) : isSuccess ? (
        <main>
          <div>
            <DetailsBanner reviews={reviews} data={data} />
            <DetailsLayout
              reviews={reviews}
              data={data}
              courseBycategory={courseByCategory}
              otherCourses={otherCourses}
            />
          </div>
        </main>
      ) : isError ? (
        <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md mb-4 shadow-md'>
          <p className='font-semibold'>Erreur de chargement</p>
          <p>
            Une erreur est survenue lors du chargement des données. Veuillez
            réessayer plus tard.
          </p>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CoursDetails;

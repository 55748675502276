import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import AuthLayout from '../../components/auth/AuthLayout';
import { useGetAssessmentNotedQuery } from '../../redux/slices/studentAssementApiSlice';
import { useSelector } from 'react-redux';
import Spinner from '../../components/spinner/Spinner';

const CoursesExams = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const {
    data = [],
    isFetching,
    isSuccess,
    isError,
    isLoading,
  } = useGetAssessmentNotedQuery({ token, id: user?._id });

  if (isLoading) {
    return (
      <div className=' h-[90vh] flex items-center justify-center w-full'>
        <Spinner />
      </div>
    );
  }

  const backUrl = '/admin/etudiants';
  return (
    <AuthLayout backUrl={backUrl}>
      <div className='mb-3 mt-2 md:mt-0 w-full'>
        <div className='w-full'>
          <h2 className=' text-xl  md:text-3xl font-bold mb-6 text-gray-800'>
            Examens Complétés
          </h2>
          {data.length === 0 ? (
            <p className='text-start text-gray-500'>
              Aucun examen complété pour le moment.
            </p>
          ) : (
            <div className='grid gap-6 grid-rows-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
              {data?.map((exam) => (
                <div
                  key={exam?._id}
                  className='bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300 ease-in-out'
                >
                  <div className='flex justify-between items-center mb-4'>
                    <h3 className='text-xl font-semibold text-gray-700'>
                      {exam?.assessment?.course?.title.length > 18
                        ? exam?.assessment?.course?.title.substring(0, 18) +
                          '...'
                        : exam?.assessment?.course.title}
                    </h3>
                    {exam?.score >= 50 ? (
                      <FaCheckCircle
                        className='text-green-500 h-6 w-6'
                        title='Réussi'
                      />
                    ) : (
                      <FaTimesCircle
                        className='text-red-500 h-6 w-6'
                        title='Échec'
                      />
                    )}
                  </div>
                  <p className='text-gray-500 mb-2'>
                    Date : {new Date(exam.createdAt).toLocaleDateString()}
                  </p>

                  <div className='flex items-center'>
                    <span
                      className={`inline-flex items-center justify-center px-3 py-1 rounded-full text-sm font-semibold ${
                        exam.score >= 80
                          ? 'bg-green-100 text-green-700'
                          : exam.score >= 50
                          ? 'bg-yellow-100 text-yellow-700'
                          : 'bg-red-100 text-red-700'
                      }`}
                    >
                      Score : {exam?.score} / 100
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default CoursesExams;

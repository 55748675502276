import React from 'react';
import SuccessMessage from '../message/SuccessMessage';
import ErrorMessage from '../message/ErrorMessage';
import { FaUpload } from 'react-icons/fa';
import Spinner from '../spinner/Spinner';
import FullButton from '../utilities/FullButton';

function UserForm({
  successMessage,
  error,
  setName,
  setPassword,
  setConfirmPassword,
  confirmPassword,
  password,
  setEmail,
  job,
  setJob,
  name,
  handleImageChange,
  handleSubmit,
  handleIconClick,
  imageUrl,
  email,
  fileInputRef,
  uploadLoading,
  title,
}) {
  return (
    <div className='w-full p-4 rounded-lg mb-4 shadow-sm bg-white'>
      <h1 className='text-2xl font-bold mb-4'>{title} </h1>
      {successMessage ? <SuccessMessage message={successMessage} /> : ''}
      {error && <ErrorMessage message={error} />}

      <form onSubmit={handleSubmit} className='space-y-4'>
        <div>
          <label htmlFor='name' className='block font-medium'>
            Nom :
          </label>
          <input
            type='text'
            id='name'
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className='w-full p-2 border rounded-md'
          />
        </div>
        <div>
          <label htmlFor='email' className='block font-medium'>
            Email :
          </label>
          <input
            type='email'
            id='email'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className='w-full p-2 border rounded-md'
          />
        </div>
        <div>
          <label htmlFor='email' className='block font-medium'>
            Spécialité :
          </label>
          <input
            type='text'
            id='job'
            name='job'
            value={job}
            onChange={(e) => setJob(e.target.value)}
            required
            className='w-full p-2 border rounded-md'
          />
        </div>

        <div>
          <label htmlFor='email' className='block font-medium'>
            Mot de passe:
          </label>
          <input
            type='password'
            id='password'
            name='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className='w-full p-2 border rounded-md'
          />
        </div>

        <div>
          <label htmlFor='email' className='block font-medium'>
            Confirmation du mot de passe:
          </label>
          <input
            type='password'
            id='password'
            name='password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className='w-full p-2 border rounded-md'
          />
        </div>

        <div className='flex items-center'>
          <label htmlFor='profileImage' className='block font-medium mr-2'>
            Image de profil :
          </label>
          <button
            type='button'
            onClick={handleIconClick}
            className='flex items-center justify-center w-10 h-10 bg-black text-white rounded-full'
          >
            <FaUpload />
          </button>
          <input
            type='file'
            id='profileImage'
            name='profileImage'
            accept='image/*'
            ref={fileInputRef}
            onChange={handleImageChange}
            className='hidden' // Hide the input
          />
        </div>
        {imageUrl && (
          <div className='mt-4'>
            <img
              src={imageUrl}
              alt='Profile Preview'
              className='w-32 h-32 object-cover rounded-md border'
            />
          </div>
        )}

        {uploadLoading && (
          <div className=' w-[16%]'>
            <Spinner />
          </div>
        )}

        <FullButton type='submit' text='Ajouter' />
      </form>
    </div>
  );
}

export default UserForm;

const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours} h ${
      remainingMinutes > 0 ? `${remainingMinutes} min` : ''
    }`;
  }
  return `${remainingMinutes} min`;
};

const getInitials = (name) => {
  if (!name) return 'U';
  return name
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toUpperCase();
};

function formatDate(dateString) {
  const date = new Date(dateString);

  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  return date.toLocaleDateString('fr-FR', options);
}

const formatUploadAwsDuration = (seconds) => {
  seconds = Math.floor(seconds);
  if (seconds < 60) {
    return 1;
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return minutes;
  }
};

const uploadVideoAwsDuration = async (videoUrl) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.preload = 'metadata';

    video.addEventListener('loadedmetadata', () => {
      const duration = formatUploadAwsDuration(video.duration);
      resolve(duration);
    });

    video.addEventListener('error', (error) => {
      reject('Erreur lors du chargement de la vidéo');
    });
  });
};

const YouTubeVideoDuration = async (videoId, apiKey) => {
  try {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`
    );
    const data = await response.json();

    if (data.items && data.items.length > 0) {
      const isoDuration = data.items[0].contentDetails.duration;
      return formatYoutubeDuration(isoDuration);
    }
  } catch (error) {
    console.error('Error fetching video duration:', error);
    return null;
  }
};

const formatYoutubeDuration = (isoDuration) => {
  const regex = /PT(\d+H)?(\d+M)?(\d+S)?/;
  const matches = isoDuration.match(regex);
  const hours = matches[1] ? parseInt(matches[1].replace('H', '')) : 0;
  const minutes = matches[2] ? parseInt(matches[2].replace('M', '')) : 0;
  const seconds = matches[3] ? parseInt(matches[3].replace('S', '')) : 0;

  // Calcul total en minutes
  const totalMinutes = hours * 60 + minutes + Math.ceil(seconds / 60);

  return totalMinutes;
};

const formatMinutesToHourMinute = (minutes) => {
  if (minutes < 60) {
    return `${minutes}min`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return remainingMinutes > 0
    ? `${hours}h ${remainingMinutes}min`
    : `${hours}h`;
};

function formatRelativeTimeManual(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const secondsElapsed = Math.floor((now - date) / 1000);

  const units = [
    { name: 'an', seconds: 31536000 },
    { name: 'jour', seconds: 86400 },
    { name: 'heure', seconds: 3600 },
    { name: 'minute', seconds: 60 },
    { name: 'seconde', seconds: 1 },
  ];

  for (const unit of units) {
    const elapsed = Math.floor(secondsElapsed / unit.seconds);
    if (elapsed >= 1) {
      if (elapsed === 1) {
        return `il y a ${elapsed} ${unit.name}`;
      } else {
        const unitName = unit.name === 'an' ? 'ans' : `${unit.name}s`;
        return `il y a ${elapsed} ${unitName}`;
      }
    }
  }

  return "à l'instant";
}

function getYouTubeVideoId(url) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|\S+\?v=)|(?:youtu\.be\/))([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export {
  formatTime,
  getInitials,
  formatDate,
  uploadVideoAwsDuration,
  YouTubeVideoDuration,
  formatMinutesToHourMinute,
  formatRelativeTimeManual,
  getYouTubeVideoId,
};

import { REVIEW_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const reviewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addReview: builder.mutation({
      query: (data) => ({
        url: REVIEW_URL,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getReviews: builder.query({
      query: (id) => ({
        url: REVIEW_URL + '/' + id,
        method: 'GET',
      }),
    }),

    deleteReview: builder.mutation({
      query: ({ token, id }) => ({
        url: REVIEW_URL + '/' + id,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    addOrUpdateUnlike: builder.mutation({
      query: ({ token, courseId, reviewId }) => ({
        url: `${REVIEW_URL}/${courseId}/review/${reviewId}/unlike`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    addOrUpdateLike: builder.mutation({
      query: ({ token, courseId, reviewId }) => ({
        url: `${REVIEW_URL}/${courseId}/review/${reviewId}/like`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useAddReviewMutation,
  useGetReviewsQuery,
  useDeleteReviewMutation,
  useAddOrUpdateUnlikeMutation,
  useAddOrUpdateLikeMutation,
} = reviewApiSlice;

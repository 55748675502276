const AuthInput = ({ label, type, value, setValue, placeholder }) => {
  return (
    <div>
      <label htmlFor='email' className='sr-only'>
        {label}
      </label>
      <input
        id={type}
        name={type}
        type={type}
        required
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm'
        placeholder={placeholder}
      />
    </div>
  );
};

export default AuthInput;

import AdminLayout from '../../components/admin/AdminLayout';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserForm from '../../components/admin/UserForm';
import { useAddUsersMutation } from '../../redux/slices/userApiSlice';

const AddInstructor = () => {
  const [addUsers, { isLoading, isError, isSuccess }] = useAddUsersMutation();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [job, setJob] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
    if (isSuccess) {
      setName('');
      setEmail('');
      setImageUrl('');
      setConfirmPassword('');
      setPassword('');
      setJob('');
      setSuccessMessage('Instructeur ajouté avec success');
    }
  }, [isSuccess]);

  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.trim() !== confirmPassword.trim()) {
      setError('Mot de passe non conforme');
      return;
    }

    try {
      const data = {
        name,
        email,
        imageUrl,
        password,
        job,
        token,
        type: 'instructors',
        isTeacher: true,
        isStudent: false,
      };

      await addUsers(data).unwrap();
    } catch (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
      setError(error.data.message);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploadLoading(true);

    try {
      const response = await axios.post(
        `http://85.31.236.195:5000/api/upload-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setUploadLoading(false);
      setImageUrl(response.data.imagePath);
    } catch (error) {
      setUploadLoading(false);
      setError("Le téléchargement de l'image a échoué.");
      throw error;
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  if (user && user.isAdmin) {
    return (
      <main className={`flex-1`}>
        <AdminLayout>
          <div className='w-full'>
            <UserForm
              title='Ajouter un formateur'
              successMessage={successMessage}
              error={error}
              setName={setName}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              confirmPassword={confirmPassword}
              password={password}
              setEmail={setEmail}
              email={email}
              job={job}
              setJob={setJob}
              name={name}
              handleImageChange={handleImageChange}
              handleSubmit={handleSubmit}
              handleIconClick={handleIconClick}
              imageUrl={imageUrl}
              fileInputRef={fileInputRef}
              uploadLoading={uploadLoading}
            />
          </div>
        </AdminLayout>
      </main>
    );
  } else {
    navigate('/');
  }
};

export default AddInstructor;

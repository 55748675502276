import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '../../components/message/ErrorMessage';
import Pagination from '../../components/pagination/Pagination';
import SearchCard from '../../components/search/SearchCard';
import Spinner from '../../components/spinner/Spinner';
import { useGetCoursesQuery } from '../../redux/slices/courseApiSlice';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { setSideBar } from '../../redux/slices/stateSlice';

const Search = () => {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isSuccess } = useGetCoursesQuery({
    currentPage,
    title,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className=' min-h-[85vh] max-w-2xl py-6 m-auto w-full'>
      {isLoading ? (
        <Spinner />
      ) : data && data.courses && data.courses.length > 0 ? (
        <div className='w-full grid-flow-row grid lg:grid-cols-2  gap-4'>
          {data.courses.map((item) => (
            <SearchCard item={item} key={item.id} />
          ))}
        </div>
      ) : (
        <div className='w-full'>
          <ErrorMessage message='Aucun Cours trouvé' />
        </div>
      )}

      {data && data.courses && data.courses.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={data?.pages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default Search;

import { useEffect, useState } from 'react';
import StarSelect from '../learning/StarSelect';

const ReviewSection = ({ reviews }) => {
  const [ratingFilter, setRatingFilter] = useState('Toutes les notes');
  const [sortOption, setSortOption] = useState('Recommandé');
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [visibleReviewsCount, setVisibleReviewsCount] = useState(5);

  const handleSortChange = (e) => setSortOption(e.target.value);

  const filterAndSortReviews = () => {
    let filtered = [...(reviews?.reviews[0]?.reviews || [])];

    // Filtrer par note
    if (ratingFilter !== 'Toutes les notes') {
      filtered = filtered.filter(
        (review) => review.star === parseInt(ratingFilter, 10)
      );
    }

    // Trier les avis
    if (sortOption === 'Les plus récents') {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortOption === 'Les plus anciens') {
      filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }

    setFilteredReviews(filtered);
  };

  const loadMoreReviews = () => setVisibleReviewsCount((prev) => prev + 5);

  useEffect(() => {
    filterAndSortReviews();
  }, [reviews, ratingFilter, sortOption]);

  return (
    <div className='w-full pt-2'>
      <div className='pb-4  flex-col md:flex-row md:justify-between flex md:items-center'>
        <div className='space-x-3 mb-2 md:mb-0 flex items-center'>
          <span>Filtrer par :</span>
          <StarSelect
            isDetailsPage={true}
            isfilter={true}
            rating={ratingFilter}
            setRating={setRatingFilter}
          />
        </div>
        <button className='px-4 py-2  rounded-full hover:bg-gray-200 transition-all flex space-x-1 items-center'>
          <span>Classer par :</span>
          <select
            value={sortOption}
            onChange={handleSortChange}
            className='border-none bg-transparent outline-none'
          >
            <option value='Recommandé'>Trier par recommandé</option>
            <option value='Les plus récents'>Trier par plus récents</option>
            <option value='Les plus anciens'>Trier par plus anciens</option>
          </select>
        </button>
      </div>

      {filteredReviews.slice(0, visibleReviewsCount).map((review, index) => (
        <div
          key={index}
          className='flex space-x-4 mb-4 p-4 border-2 border-gray-300 rounded-lg'
        >
          <div className='bg-gray-300 rounded-full w-10 h-10 flex items-center justify-center mr-4'>
            {review.user.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </div>
          <div>
            <h4 className='font-bold'>{review.user.name}</h4>
            <p className='text-sm text-gray-500'>
              {review.user.isStudent ? 'Étudiant' : 'Formateur'}
            </p>
            <div className='flex items-center mt-2'>
              <div className='flex space-x-1 text-yellow-500'>
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    fill='currentColor'
                    className={`w-5 h-5 ${
                      i < review.star ? 'text-yellow-500' : 'text-gray-300'
                    }`}
                  >
                    <path
                      fillRule='evenodd'
                      d='M12 17.27l6.18 3.64-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.64-1.64 7.03L12 17.27z'
                      clipRule='evenodd'
                    />
                  </svg>
                ))}
              </div>
              <span className='ml-2 text-sm text-gray-500'>
                {new Date(review.createdAt).toLocaleDateString()}
              </span>
            </div>
            <p className='mt-2'>{review.comment}</p>
            <div className='flex space-x-4 text-sm text-gray-500 mt-2'>
              <div className='flex items-center space-x-1'>
                <span>👍</span>
                <span>{review.like.length}</span>
              </div>
              <div className='flex items-center space-x-1'>
                <span>👎</span>
                <span>{review.unLike.length}</span>
              </div>
            </div>
          </div>
        </div>
      ))}

      {filteredReviews.length > visibleReviewsCount && (
        <div>
          <button
            onClick={loadMoreReviews}
            className='hover:bg-blue-100 font-normal rounded-full text-blue-950 px-4 py-1'
          >
            Afficher plus d'avis
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewSection;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ConnexeItem = ({ courseId, imageUrl, title, category, temps }) => {
  const [isHover, setisHover] = useState(false);
  return (
    <div className='  border-b-2  relative py-2 grid grid-cols-5 gap-4'>
      <Link
        onMouseEnter={() => setisHover(true)}
        onMouseLeave={() => setisHover(false)}
        to={`/cours/${courseId}`}
        className=' h-[120px] relative col-span-2'
      >
        <div
          className={`${
            isHover
              ? 'block duration-500 transition-all'
              : 'hidden duration-500 transition-all'
          }  transition-all duration-500 border-white border-[2px]  bg-[#0000004e] rounded-full  p-2 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] absolute`}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className=' h-6  text-white'
          >
            <path
              fillRule='evenodd'
              d='M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <img
          src={imageUrl}
          alt='images'
          className=' w-full object-cover h-full '
        />
      </Link>

      <div className=' flex   flex-col col-span-3'>
        <h5>{category}</h5>
        <Link
          className=' font-semibold hover:text-[#0b0272] hover:underline hover:decoration-[#0b0272] decoration-black'
          to='/'
        >
          {title}
        </Link>
        <p className='text-sm text-gray-500'>{temps}</p>
      </div>
    </div>
  );
};

export default ConnexeItem;

const StarSelect = ({
  rating,
  setRating,
  isfilter = false,
  isDetailsPage = false,
}) => {
  return (
    <div className={`flex ${isDetailsPage ? '' : 'mb-4'} `}>
      <select
        value={rating}
        onChange={(e) => setRating(e.target.value)}
        className={`${
          isDetailsPage
            ? 'border-[1px] border-gray-500 bg-transparent outline-none rounded-full '
            : 'border-gray-300'
        }  border  px-4 py-2`}
      >
        {isfilter ? (
          <option value='Toutes les notes'>Toutes les notes</option>
        ) : (
          <option value=''>Selectionner le nombre d'étoile</option>
        )}

        <option value='5'>5 étoiles</option>
        <option value='4'>4 étoiles</option>
        <option value='3'>3 étoiles</option>
        <option value='2'>2 étoiles</option>
        <option value='1'>1 étoile</option>
      </select>
    </div>
  );
};

export default StarSelect;

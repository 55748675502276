import { useEffect } from 'react';

const PDFViewer = ({ lesson, onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, [lesson, onComplete]);

  return (
    <iframe
      src={lesson.parts[0].document}
      style={{ width: '100%', height: '600px' }}
      frameBorder='0'
      title='PDF Viewer'
    ></iframe>
  );
};
export default PDFViewer;

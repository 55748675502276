import { getInitials } from '../../utils/utils';
import Title from '../utilities/Title';

const AutorProfil = ({ data }) => {
  return (
    <div>
      <Title text='Formateur' />
      <div className='my-2 flex items-center'>
        {data.user.imageUrl ? (
          <img
            src={data.user.imageUrl}
            alt='profile'
            className='h-20 w-20 rounded-full'
          />
        ) : (
          <div className=' flex items-center space-x-2'>
            <div className='w-20 h-20 cursor-pointer rounded-full bg-gray-500 flex items-center justify-center text-white'>
              {getInitials(data.user.name)}
            </div>
            <div className=' leading-[1.4] flex flex-col items-start'>
              <span className=' font-medium  text-[1.1rem] capitalize'>
                {data.user.name}
              </span>
              <span className=' text-[0.8rem] text-gray-500'>
                {data.user.email}
              </span>
            </div>
          </div>
        )}

        <div className='ml-4'>
          <h5 className='font-semibold text-lg'>
            {data?.user?.name} {data?.user?.userName}
          </h5>
          <p className='text-gray-600'>CEISNA Experte</p>
        </div>
      </div>
    </div>
  );
};

export default AutorProfil;

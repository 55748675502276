import React from 'react';
import Title from '../utilities/Title';
import CompetenceList from './CompetenceList';

const CourseCompetences = ({ data }) => {
  return (
    <div className=' py-1'>
      <Title text='Compétences couvertes par ce cours' />
      <ul className='  gap-4 mt-2 flex flex-wrap'>
        {data &&
          data.skills.length > 0 &&
          data.skills.map((s, index) => {
            return <CompetenceList key={index} competence={s} />;
          })}
      </ul>
    </div>
  );
};

export default CourseCompetences;

import React from 'react';
import TabItem from './TabItem';

const tabItems = [
  { text: 'Contenu du cours' },
  { text: 'Question & Réponse' },
  { text: 'Notes' },
  { text: 'Évaluation' },
];

const TabList = ({ id, tabIndex }) => {
  return (
    <div className='flex justify-center items-center border-b-[1px] border-gray-300'>
      <ul className='flex items-center space-x-5'>
        {tabItems.map((item, index) => (
          <TabItem
            tabIndex={tabIndex}
            key={index}
            {...item}
            index={index}
            id={id}
          />
        ))}
      </ul>
    </div>
  );
};

export default TabList;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const NotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className='min-h-[85vh] flex flex-col items-center justify-center bg-gray-50 p-6'>
      <div className=' text-center flex flex-col justify-center items-center  max-w-lg'>
        <h1 className='text-6xl font-extrabold text-[#1e1a65] mb-4'>404</h1>
        <h2 className='text-3xl font-semibold text-gray-800 mb-2'>
          Page non trouvée
        </h2>
        <p className='text-lg text-gray-600 mb-8'>
          Oups ! La page que vous recherchez n'existe pas ou a été déplacée.
        </p>
        <button
          onClick={goHome}
          className='flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold px-5 py-3 rounded-full shadow-md transition-colors duration-300 ease-in-out'
        >
          <FaHome className='h-6 w-6' />
          Retour à l’accueil
        </button>
      </div>
    </div>
  );
};

export default NotFound;

import { createSlice } from '@reduxjs/toolkit';

const savedCourse = localStorage.getItem('course')
  ? JSON.parse(localStorage.getItem('course'))
  : null;

const initialState = {
  sideBarOpen: false,
  isAuthenticated: false,
  user: null,
  course: savedCourse,
};

const stateSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setSideBar: (state, action) => {
      state.sideBarOpen = action.payload;
    },

    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setCourse: (state, action) => {
      state.course = action.payload;
      localStorage.setItem('course', JSON.stringify(state.course));
    },
  },
});

// Export des actions
export const { setSideBar, setAuthentication, setUser, setCourse } =
  stateSlice.actions;

// Export du reducer
export default stateSlice.reducer;

import { useEffect } from 'react';

const CanvaView = ({ lesson, onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, [lesson, onComplete]);

  if (!lesson?.parts?.[0]?.canva) {
    return (
      <p className='text-red-500'>
        Le contenu de la leçon n'est pas disponible.
      </p>
    );
  }

  return (
    <div
      className='relative w-full h-[600px] shadow-lg mt-6 mb-4 overflow-hidden rounded-lg'
      style={{ willChange: 'transform' }}
    >
      <iframe
        loading='lazy'
        src={lesson.parts[0].canva}
        className='absolute w-full h-full top-0 left-0 border-none'
        allow='fullscreen'
      ></iframe>
    </div>
  );
};

export default CanvaView;

import { ASSESSMENT_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const assementCouresSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAssement: builder.mutation({
      query: (data) => ({
        url: ASSESSMENT_URL,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getAssement: builder.query({
      query: ({ token, id }) => ({
        url: ASSESSMENT_URL + '/' + id,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getAssements: builder.query({
      query: ({ token, id }) => ({
        url: ASSESSMENT_URL + '/' + id + '/list',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    deleteAssements: builder.mutation({
      query: (data) => ({
        url: ASSESSMENT_URL + '/' + data.evId,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    updateAssements: builder.mutation({
      query: (data) => ({
        url: ASSESSMENT_URL + '/' + data.evId,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useAddAssementMutation,
  useGetAssementQuery,
  useGetAssementsQuery,
  useDeleteAssementsMutation,
  useUpdateAssementsMutation,
} = assementCouresSlice;

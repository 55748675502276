import { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const QuizContent = ({ lesson, onComplete }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = () => {
    setSubmitted(true);
    setIsCorrect(selectedAnswer === lesson.parts[0].answer);
  };

  return (
    <div className='min-h-[50vh] flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-200 p-6'>
      <div className='max-w-xl w-full bg-white p-8 rounded-2xl shadow-lg border border-gray-200'>
        <h2 className='text-3xl font-extrabold text-gray-800 mb-4 text-center'>
          Quiz
        </h2>
        <p className='text-xl font-medium text-gray-700 mb-8 text-center'>
          {lesson.parts[0].question}
        </p>

        <div className='space-y-4'>
          {lesson.parts[0].choices.map((option, index) => (
            <label
              key={index}
              className={`block p-4 border rounded-lg cursor-pointer transition transform hover:scale-105 duration-150 ${
                selectedAnswer === option
                  ? 'bg-blue-500 text-white border-blue-500'
                  : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-blue-50'
              } ${
                submitted && option === lesson.parts[0].answer
                  ? 'bg-green-500 text-white'
                  : ''
              }`}
            >
              <input
                type='radio'
                name='answer'
                value={option}
                checked={selectedAnswer === option}
                onChange={() => setSelectedAnswer(option)}
                className='hidden'
              />
              {option}
            </label>
          ))}
        </div>

        <button
          onClick={handleSubmit}
          className='w-full mt-8 py-3 px-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:opacity-50'
          disabled={isCorrect}
        >
          {submitted ? 'Réponse Soumise' : 'Soumettre'}
        </button>

        {submitted && (
          <div className='mt-6 text-center'>
            {isCorrect ? (
              <div className='flex items-center justify-center text-green-600 space-x-2'>
                <FaCheckCircle className='h-6 w-6' />
                <p className='text-lg font-medium'>Bonne réponse !</p>
              </div>
            ) : (
              <div className='flex items-center justify-center text-red-600 space-x-2'>
                <FaTimesCircle className='h-6 w-6' />
                <p className='text-lg font-medium'>
                  Mauvaise réponse. Essayez encore !
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizContent;

import Auth from '../pages/auth/Auth';
import CoursDetails from '../pages/coursDetails/CoursDetails';
import Home from '../pages/home/Home';
import CourseTitleStep from '../pages/admin/CourseTitleStep';
import CurriculumBuilder from '../pages/admin/CurriculumBuilder';
import Dashboard from '../pages/admin/Dashboard';
import Students from '../pages/admin/Students';
import Teacher from '../pages/admin/Teacher';
import AdminCourses from '../pages/admin/AdminCourses';
import EditProfile from '../pages/auth/EditProfile';
import CoursCategorie from '../pages/admin/CoursCategorie';
import EdictTitle from '../pages/admin/EdictTitle';
import EdictCategorie from '../pages/admin/EdictCategorie';
import EdictCompetence from '../pages/admin/EdictCompetence';
import EdictLanguage from '../pages/admin/EdictLanguage';
import EdictCertificat from '../pages/admin/EdictCertificat';
import EdictEvaluation from '../pages/admin/EdictEvaluation';
import EdictPrice from '../pages/admin/EdictPrice';
import EdictPromotional from '../pages/admin/EdictPromotional';
import EdictDificulty from '../pages/admin/EdictDificulty';
import MyCourse from '../pages/auth/MyCourse';
import EdictICourseImage from '../pages/admin/EdictICourseImage';
import EdictDescription from '../pages/admin/EdictDescription';
import EdictCoursTime from '../pages/admin/EdictCoursTime';
import Payment from '../pages/payment/Payment';
import AddInstructor from '../pages/admin/AddInstructor';
import AddStudent from '../pages/admin/AddStudent';
import StudentCours from '../pages/admin/StudentCours';
import InstructorCourse from '../pages/admin/InstructorCourse';
import EdictStudent from '../pages/admin/EdictStudent';
import EdictInstructor from '../pages/admin/EdictInstructor';
import StudentExams from '../pages/admin/StudentExams';
import StudentCertificates from '../pages/admin/StudentCertificates';
import CoursesExams from '../pages/auth/CoursesExams';
import CourseCertificat from '../pages/auth/CourseCertificat';
import CourseLearning from '../pages/auth/CourseLearning';
import EdictFaqs from '../pages/admin/EdictFaqs';
import FaqDetail from '../pages/auth/FaqDetail';
import NotFound from '../pages/404/NotFound';
import Evaluation from '../pages/auth/Evaluation';
import EndAssements from '../pages/auth/EndAssements';
import Search from '../pages/search/Search';
import EdictPromotion from '../pages/admin/EdictPromotion';
import StudentCourseLearning from '../pages/admin/StudentCourseLearning';
import StudentEvaluations from '../pages/admin/StudentEvaluations';
import AssessmentDetails from '../components/admin/StudentAssessmentDetails';
import BecomeTutor from '../pages/becomeTutor/BecomeTutor';
import ResetPassword from '../pages/auth/ResetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword.js';
import TutorCourse from '../pages/tutor/TutorCourse.js';

const useRouter = () => {
  const publicPath = [
    { path: '/', Component: Home },
    { path: '/auth', Component: Auth },
    { path: '/cours/:id', Component: CoursDetails },
    { path: '/payement/student/:id', Component: Payment },
    { path: '/search', Component: Search },
    { path: '/devenir-tuteur', Component: BecomeTutor },
    { path: '/password-reset/:token', Component: ResetPassword },
    { path: '/forgot-password', Component: ForgotPassword },
    { path: '*', Component: NotFound },
  ];

  const adminPath = [
    { path: '/admin/dasboard', Component: Dashboard },
    { path: '/admin/etudiants', Component: Students },
    { path: '/admin/evalution', Component: StudentEvaluations },
    { path: '/admin/evaluation/:id', Component: AssessmentDetails },
    { path: '/admin/etudiants/cours/:id', Component: StudentCourseLearning },
    { path: '/admin/formateurs', Component: Teacher },
    { path: '/admin/courses', Component: AdminCourses },
    { path: '/admin/tutor/course', Component: TutorCourse },
    { path: '/admin/create-cours/title', Component: CourseTitleStep },
    { path: '/admin/create-cours/categorie/:id', Component: CoursCategorie },
    {
      path: '/admin/create-cours/programme/edict/:id',
      Component: CurriculumBuilder,
    },
    { path: '/admin/profile', Component: EditProfile },

    { path: '/admin/create-cours/title/edict/:id', Component: EdictTitle },
    {
      path: '/admin/create-cours/categorie/edict/:id',
      Component: EdictCategorie,
    },

    {
      path: '/admin/create-cours/competence/edict/:id',
      Component: EdictCompetence,
    },
    {
      path: '/admin/create-cours/language/edict/:id',
      Component: EdictLanguage,
    },
    {
      path: '/admin/create-cours/certificat/edict/:id',
      Component: EdictCertificat,
    },
    {
      path: '/admin/create-cours/evaluation/edict/:id',
      Component: EdictEvaluation,
    },

    {
      path: '/admin/create-cours/price/edict/:id',
      Component: EdictPrice,
    },
    {
      path: '/admin/create-cours/promotionnal/edict/:id',
      Component: EdictPromotional,
    },
    {
      path: '/admin/create-cours/dificulty/edict/:id',
      Component: EdictDificulty,
    },
    {
      path: '/admin/my-courses',
      Component: MyCourse,
    },
    {
      path: '/admin/create-cours/image/edict/:id',
      Component: EdictICourseImage,
    },
    {
      path: '/admin/create-cours/description/edict/:id',
      Component: EdictDescription,
    },
    {
      path: '/admin/create-cours/promotion/edict/:id',
      Component: EdictPromotion,
    },

    {
      path: '/admin/create-cours/time/edict/:id',
      Component: EdictCoursTime,
    },
    {
      path: '/admin/new/instructor',
      Component: AddInstructor,
    },
    {
      path: '/admin/new/student',
      Component: AddStudent,
    },

    {
      path: '/admin/student/courses/:id',
      Component: StudentCours,
    },
    {
      path: '/admin/instructor/courses/:id',
      Component: InstructorCourse,
    },
    {
      path: '/admin/create-cours/student/edict/:id',
      Component: EdictStudent,
    },
    {
      path: '/admin/create-cours/instructor/edict/:id',
      Component: EdictInstructor,
    },
    {
      path: '/admin/create-cours/faqs/edict/:id',
      Component: EdictFaqs,
    },
    {
      path: '/admin/student/exams/:id',
      Component: StudentExams,
    },
    {
      path: '/admin/user/certificat/:id',
      Component: StudentCertificates,
    },
    {
      path: '/student/exams/',
      Component: CoursesExams,
    },
    {
      path: '/student/certificat/',
      Component: CourseCertificat,
    },
    {
      path: '/course/learning/:id',
      Component: CourseLearning,
    },
    {
      path: '/faqs/:id',
      Component: FaqDetail,
    },
    {
      path: '/evaluation/:id',
      Component: Evaluation,
    },

    {
      path: '/evaluation/ended/',
      Component: EndAssements,
    },
  ];

  return {
    publicPath,
    adminPath,
  };
};

export default useRouter;

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthInput from '../../components/auth/AuthInput';
import ErrorMessage from '../../components/message/ErrorMessage';
import SuccessMessage from '../../components/message/SuccessMessage';
import {
  useResetPasswordMutation,
  useValidateResetTokenQuery,
} from '../../redux/slices/userApiSlice';
import Spinner from '../../components/spinner/Spinner';
import FullButton from '../../components/utilities/FullButton';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const {
    data,
    isLoading: validateLoading,
    isSuccess: validateSuccess,
    isError: validateError,
  } = useValidateResetTokenQuery(token);

  const [resetPassword, { isError, isLoading, isSuccess }] =
    useResetPasswordMutation();

  // useEffect(() => {
  //   if (isError) {
  //     setErrorMessage('Erreur lors de la modification du mot de passe.');

  //     setTimeout(() => {
  //       setErrorMessage('');
  //     }, 5000);
  //   }
  // }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/auth');
    }
  }, [isSuccess]);

  if (validateLoading) {
    return (
      <div className='min-h-[85vh] flex items-center justify-center bg-gray-100'>
        <Spinner />
      </div>
    );
  }

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    if (
      !password ||
      !confirmPassword ||
      password.trim() !== confirmPassword.trim()
    ) {
      setErrorMessage('Le mot de passe ne respecte pas les critères requis.');
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    } else {
      try {
        await resetPassword({ password, token }).unwrap();
      } catch (error) {
        if (isError) {
          setErrorMessage(error.data.message);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        }
      }
    }
  };

  return (
    <div className='min-h-[78vh] flex items-center justify-center bg-gray-100'>
      <div className='w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md'>
        <div className=' '>
          {validateError && (
            <ErrorMessage message={'Le token est invalide ou expiré.'} />
          )}
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {successMessage && <SuccessMessage message={successMessage} />}
          <h2 className='text-center text-2xl font-extrabold text-gray-900'>
            modifier le mot de passe
          </h2>
        </div>

        <form
          className='mt-8 flex flex-col  space-y-2'
          onSubmit={resetPasswordHandler}
        >
          <AuthInput
            setValue={setPassword}
            value={password}
            type='password'
            label=' Mot de passe'
            placeholder='Mot de passe'
          />
          <AuthInput
            setValue={setConfirmPassword}
            value={confirmPassword}
            type='password'
            label=' confirmation mot de passe'
            placeholder='confirmer le mot de passe'
          />

          {isLoading ? (
            <Spinner />
          ) : (
            <FullButton text={`Reintialiser `} type='submit' />
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

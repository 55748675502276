import { Link, Navigate, useLocation } from 'react-router-dom';
import AdminItem from '../admin/AdminItem';
import {
  FaTachometerAlt,
  FaChalkboardTeacher,
  FaUserGraduate,
  FaBook,
  FaUser,
  FaSignOutAlt,
  FaCertificate,
  FaFileAlt,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSideBar } from '../../redux/slices/stateSlice';
import { useEffect } from 'react';
import { logout } from '../../redux/slices/authSlice';
import { useLogOutMutation } from '../../redux/slices/userApiSlice';

const Title = ({ texte }) => {
  return <h2 className='text-lg font-bold  mb-4'>{texte}</h2>;
};

const AuthLayout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const [logOut, { isError, error, isLoading, isSuccess }] =
    useLogOutMutation();

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  const conentItems = [
    {
      url: '/admin/dasboard',
      texte: 'Tableau de bord',
      icon: <FaTachometerAlt />,
    },
    {
      url: '/admin/formateurs',
      texte: 'Formateurs',
      icon: <FaChalkboardTeacher />,
    },
    {
      url: '/admin/etudiants',
      texte: 'Étudiant(e)s',
      icon: <FaUserGraduate />,
    },
    { url: '/admin/courses', texte: 'Cours', icon: <FaBook /> },
  ];

  const itemsPrice = [
    { url: '/admin/profile', texte: 'Profil', icon: <FaUser /> },
  ];

  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(logout());
    }
  }, [isSuccess]);

  const logOutHandler = async () => {
    try {
      await logOut().unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const studentItems = [
    {
      url: `/admin/my-courses`,
      texte: 'Mes cours',
      icon: <FaBook />,
    },
    {
      url: `/student/exams/`,
      texte: 'Examens',
      icon: <FaFileAlt />,
    },
    {
      url: `/student/certificat/`,
      texte: 'Certificats',
      icon: <FaCertificate />,
    },
  ];

  if (!user) {
    return <Navigate to='/auth' />;
  }

  return (
    <div className='flex flex-col  md:flex-row mt-[4.5rem] md:space-x-10 pb-6 w-[90%] mx-auto  md:container m-auto min-h-[78vh] '>
      <div>
        <div className='md:w-64 w-full bg-[#ffffff]   p-4'>
          <Title texte={'Administration Centrale'} />
          {user && user.isAdmin && (
            <ul className=' w-full'>
              {conentItems.map((item, index) => (
                <AdminItem key={index} {...item} />
              ))}
            </ul>
          )}
          {user && !user.isAdmin && (
            <ul className=' w-full'>
              {studentItems.map((item, index) => (
                <AdminItem key={index} {...item} />
              ))}

              {user && user.isTeacher && (
                <AdminItem
                  url='/admin/tutor/course'
                  texte='Formations'
                  icon={<FaChalkboardTeacher />}
                />
              )}
            </ul>
          )}

          <Title texte='Compte' />
          <ul>
            {itemsPrice.map((item, index) => (
              <AdminItem key={index} {...item} />
            ))}
            <li className='w-full'>
              <button
                onClick={logOutHandler}
                className={`
          
         transition-all px-3 py-[0.3rem] w-full hover:bg-gray-100 cursor-pointer flex items-center`}
              >
                <span className='mr-2'>
                  <FaSignOutAlt />
                </span>
                Deconnexion
              </button>
            </li>
          </ul>
        </div>
      </div>

      {children}
    </div>
  );
};

export default AuthLayout;

import { useState } from 'react';
import FullButton from '../utilities/FullButton';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DropdownMenu from '../dropdowMenu/DropdownMenu';
import logo from '../../assets/logo.webp';
import {
  FaBook,
  FaCertificate,
  FaChalkboardTeacher,
  FaFileAlt,
  FaHome,
  FaSignOutAlt,
  FaTachometerAlt,
  FaUser,
  FaUserGraduate,
} from 'react-icons/fa';
import { useLogOutMutation } from '../../redux/slices/userApiSlice';

const MobileMenu = ({ toggleMobileMenu, menuOpen }) => {
  const { user } = useSelector((state) => state.auth);
  const [logOut, { isError, error, isLoading, isSuccess }] =
    useLogOutMutation();
  const logOutHandler = async () => {
    try {
      await logOut().unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  const conentItems = [
    {
      url: '/',
      text: 'Accueil',
      icon: <FaHome className='text-2xl' />,
    },
    {
      url: '/admin/my-courses',
      text: 'Mes cours',
      icon: <FaBook />,
    },
    {
      url: '/student/exams/',
      text: 'Examens',
      icon: <FaFileAlt />,
    },
    {
      url: '/student/certificat/',
      text: 'Certificats',
      icon: <FaCertificate />,
    },
  ];
  return (
    <div
      onClick={toggleMobileMenu}
      className={`fixed inset-y-0 left-0 w-[100vw] bg-[#00000030] shadow-lg transform transition-transform duration-300 ease-in-out ${
        menuOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className={`fixed inset-y-0 left-0 w-64 bg-white shadow-lg  `}>
        <button onClick={toggleMobileMenu} className='p-4 text-right'>
          &times;
        </button>
        <nav className='flex flex-col p-4 space-y-4'>
          {conentItems.map((item) => (
            <Link
              className={`transition-all px-3 py-[0.1rem] w-full hover:bg-gray-100 cursor-pointer flex items-center`}
              to={item.url}
            >
              <span className='mr-2'>{item.icon}</span>
              {item.text}
            </Link>
          ))}
          {user && (
            <button
              onClick={logOutHandler}
              className={`transition-all px-3 py-[0.3rem] w-full hover:bg-gray-100 cursor-pointer flex items-center`}
            >
              <span className='mr-2'>
                <FaSignOutAlt />
              </span>
              Deconnexion
            </button>
          )}
        </nav>
      </div>
    </div>
  );
};
const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [title, setTitle] = useState('');

  const authHandler = () => {
    navigate('/auth');
  };

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    if (title.trim() !== '') {
      console.log('search', title);
      navigate(`/search?title=${title}`, { replace: true });
    }
  };

  return (
    <header className='z-[999]   items-center sticky top-0 pb-1 border-b-2 bg-white h-full shadow-md'>
      {/* Mobile header */}
      <div className='px-4 md:hidden h-full flex justify-between items-center'>
        <div className='flex   space-x-4  h-full items-center'>
          <button onClick={toggleMobileMenu}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          </button>
          <Link to='/'>
            <img src={logo} className=' h-7' alt='logo' />
          </Link>
        </div>
        <div className='flex items-center space-x-2'>
          {user ? (
            user?.imageUrl ? (
              <img
                src={user.imageUrl}
                alt='User Profile'
                className='w-8 h-8 rounded-full'
              />
            ) : (
              <DropdownMenu h='h-8' w='w-8' user={user} />
            )
          ) : (
            <FullButton text='Connexion' onClick={authHandler} />
          )}
        </div>
      </div>

      <MobileMenu toggleMobileMenu={toggleMobileMenu} menuOpen={menuOpen} />

      <nav className='hidden md:flex w-[90%] mx-auto h-full items-center justify-between'>
        <Link to='/'>
          <img src={logo} alt='logo' />
        </Link>
        <form
          onSubmit={searchHandler}
          className={`${
            focused ? 'border-gray-600' : ''
          } bg-[#f5f7f9] rounded-md border-[2px] flex items-center`}
        >
          <span
            className={`${
              focused ? 'opacity-0 hidden duration-500 transition-all' : ''
            } pl-2`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
              />
            </svg>
          </span>
          <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={`${
              focused ? 'pl-3' : ''
            } text-black outline-none bg-transparent py-2 w-[420px] placeholder-gray-600`}
            type='text'
            placeholder='Rechercher du contenu de formation'
          />
          <button
            type='submit'
            className={`${
              focused || title.trim() !== ''
                ? 'block'
                : 'opacity-0 hidden transition-all duration-500'
            } px-3 py-2 border-l-[2px] border-gray-600`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='h-6'
            >
              <path
                fillRule='evenodd'
                d='M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </form>
        <div className='flex items-center space-x-2'>
          {user ? (
            <DropdownMenu h='h-10' w='w-10' user={user} />
          ) : (
            <FullButton text='Connexion' onClick={authHandler} />
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;

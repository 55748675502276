import React, { useEffect, useRef } from 'react';
import { getYouTubeVideoId } from '../../utils/utils';

const YouTubeVideo = ({ onComplete, lesson }) => {
  const playerRef = useRef(null);
  const vId = getYouTubeVideoId(lesson.parts[0].youtubeLink);

  useEffect(() => {
    const onPlayerReady = (event) => {
      event.target.playVideo();
    };

    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        onComplete();
      }
    };

    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

      window.onYouTubeIframeAPIReady = createPlayer;
    };

    const createPlayer = () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }

      playerRef.current = new window.YT.Player('youtube-player', {
        videoId: vId,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    loadYouTubeAPI();

    // Recrée le lecteur lorsque la vidéo change
    if (window.YT && window.YT.Player) {
      createPlayer();
    }

    // Nettoyage en détruisant le lecteur quand le composant est démonté
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [lesson]);

  return (
    <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
      <div id='youtube-player' className='w-full h-full'></div>
    </div>
  );
};

export default YouTubeVideo;

const BlackButton = ({ onClick, text }) => {
  return (
    <button
      onClick={onClick}
      className='mt-5 px-4 py-2 bg-[#000] text-white rounded-lg'
    >
      {text}
    </button>
  );
};

export default BlackButton;

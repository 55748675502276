import { useGetAssementsQuery } from '../../redux/slices/assementApiSlice';
import { formatRelativeTimeManual } from '../../utils/utils';

const StudentEvaluationProgram = ({ user, token, userId, id }) => {
  const {
    data: assessment,
    isLoading: loading,
    isSuccess: success,
    isError: error,
    refetch,
  } = useGetAssementsQuery({ token, id });

  return (
    <div className='container py-10 mx-auto px-4'>
      <h2 className='font-semibold text-2xl mb-4'>Programme d'Évaluation</h2>
      <p className='mb-4'>
        Voici la liste des évaluations prévues pour ce cours. Veuillez consulter
        les ressources associées pour vous préparer.
      </p>

      <div className='space-y-6'>
        {assessment?.map((evaluation) => (
          <div
            key={evaluation._id}
            className='border border-gray-300 p-4 rounded-lg shadow-sm'
          >
            <p className='text-sm text-gray-500 mb-2'>
              Date : {formatRelativeTimeManual(evaluation.createdAt)}
            </p>
            <p className='mb-2'>{evaluation.description}</p>

            <div className='mt-2'>
              <p className='font-medium'>Commencez votre évaluation :</p>
              <button
                disabled={evaluation.studentId.includes(userId)}
                className={`${
                  evaluation.studentId.includes(userId)
                    ? ' bg-gray-300'
                    : 'bg-black'
                }    text-white px-4 py-2 rounded-md `}
              >
                {evaluation.studentId.includes(userId)
                  ? 'Dejà evalué'
                  : 'Démarrer'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentEvaluationProgram;

import { useEffect, useState } from 'react';
import Spinner from '../spinner/Spinner';

const MultipleChoiceComponent = ({
  type,
  content,
  choices,
  onSubmit,
  isTimeOut,
  onSubmitHandle,
  loading,
}) => {
  const [selectedChoices, setSelectedChoices] = useState([]);

  const handleSelect = (choice) => {
    setSelectedChoices((prevSelected) =>
      prevSelected.includes(choice)
        ? prevSelected.filter((item) => item !== choice)
        : [...prevSelected, choice]
    );
  };

  const submitHandler = () => {
    onSubmit({
      type,
      multiChoice: { question: content, userAnswer: selectedChoices },
    });
  };

  useEffect(() => {
    if (isTimeOut) {
      submitHandler();
    }
  }, [isTimeOut]);

  return (
    <div className=''>
      <h2 className='text-xl font-semibold mb-4'>Question à Choix Multiple</h2>
      <p className='text-gray-700 mb-4'>{content}</p>
      <div className='grid grid-cols-2 gap-4'>
        {choices.map((choice, index) => (
          <label key={index} className='flex items-center gap-2'>
            <input
              type='checkbox'
              value={choice}
              checked={selectedChoices.includes(choice)}
              onChange={() => handleSelect(choice)}
              className='form-checkbox text-blue-600'
            />
            <span>{choice}</span>
          </label>
        ))}
      </div>

      {loading ? (
        <div className=' w-[12%]'>
          <Spinner />
        </div>
      ) : (
        <button
          onClick={() => {
            onSubmitHandle({
              type,
              multiChoice: { question: content, userAnswer: selectedChoices },
            });
          }}
          className='bg-black text-white px-4 py-2 ml-2'
          disabled={selectedChoices.length === 0}
        >
          Soumettre
        </button>
      )}
    </div>
  );
};

export default MultipleChoiceComponent;

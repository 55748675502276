import { formatTime } from '../../utils/utils';
import React from 'react';
import { Link } from 'react-router-dom';

const CourseCard = ({ item }) => {
  return (
    <Link
      to={`/cours/${item._id}`}
      className='bg-white relative h-[320px] w-full rounded-md overflow-hidden  transition-transform transform hover:scale-105 '
    >
      <div className='h-[150px]'>
        <img
          src={item.imageUrl}
          alt='Cours image'
          className='object-cover h-full w-full transition-transform duration-300 transform hover:scale-105'
        />
      </div>
      <div className='flex items-center justify-between px-2'>
        <h3 className='text-lg font-semibold text-gray-800 hover:text-[#00075e] transition-colors duration-300'>
          {item.title}
        </h3>
        {item.populaire && (
          <span className='bg-[#00075e] absolute top-0 right-0 text-white text-xs px-2 py-1 rounded'>
            Populaire
          </span>
        )}
      </div>
      <div className='text-sm w-full px-2 flex items-center text-gray-500'>
        <p
          dangerouslySetInnerHTML={{
            __html:
              item?.description && item.description.length > 40
                ? item.description
                    .replace(/<\/?p[^>]*>/g, ' ')
                    .replace(/<br\s*\/?>/g, ' ')
                    .substring(0, 40) + '...'
                : item?.description || '',
          }}
          className='transition-opacity duration-300 hover:opacity-80'
        />
      </div>
      <p className='text-sm text-gray-500 px-2'>{formatTime(item.time)}</p>
      <p className='text-sm text-gray-700 mt-2 px-2'>
        Par : {item?.user?.name}
      </p>
    </Link>
  );
};

export default CourseCard;

import StarSelect from './StarSelect';

const CommentForm = ({
  setNewComment,
  star,
  setStar,
  newComment,
  handleAddComment,
  isSelect = false,
}) => {
  return (
    <form onSubmit={handleAddComment} className=' lg:w-[50%] mt-6'>
      <h4 className='text-lg font-semibold mb-2'>Ajouter un commentaire</h4>

      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder='Votre commentaire'
        className='w-full resize-none p-3 border border-gray-300 rounded-lg mb-4'
      ></textarea>
      {isSelect && <StarSelect rating={star} setRating={setStar} />}
      <button type='submit' className='bg-black text-white px-4 py-2 ml-2'>
        Publier
      </button>
    </form>
  );
};

export default CommentForm;

import { useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FaImage } from 'react-icons/fa';
import axios from 'axios';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';
import EdictCourseLayout from '../../components/admin/EdictCourseLayout';

const EdictICourseImage = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;

  const params = useParams();
  const { id } = params;
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [uploadLoading, setuploadLoading] = useState(false);

  const [updateCourseField, { isLoading: updateLoading }] =
    useUpdateCourseFieldMutation();
  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
  } = useGetCourseQuery(id);

  useEffect(() => {
    if (getSuccess && data?.imageUrl) {
      setImageUrl(data.imageUrl);
    }
  }, [getSuccess, location.pathname, data]);

  const updateField = async () => {
    try {
      await updateCourseField({
        id,
        token: token,
        field: 'imageUrl',
        value: String(imageUrl),
      }).unwrap();
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.data?.message || 'Erreur lors de la mise à jour');
      setTimeout(() => setErrorMessage(''), 5000);
    }
  };

  useEffect(() => {
    if (hasUserInteracted) {
      updateField();
      setHasUserInteracted(false);
    }
  }, [imageUrl, hasUserInteracted]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setErrorMessage('');

      try {
        const newImageUrl = await uploadImage(file);
        setImageUrl(newImageUrl);
        setHasUserInteracted(true);
      } catch (error) {
        setErrorMessage("Erreur de téléchargement de l'image");
      }
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    setuploadLoading(true);

    try {
      const response = await axios.post(
        `https://api.academieceisna.org/api/upload-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setuploadLoading(false);

      return response.data.imagePath;
    } catch (error) {
      setuploadLoading(false);
      console.error('Erreur lors du téléchargement :', error);
      setErrorMessage("Le téléchargement de l'image a échoué.");
      throw error;
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <EdictCourseLayout id={id}>
        <div className='w-full'>
          <h4 className='text-[1.1rem] mb-2'>Ajouter une image au cours</h4>

          {imageUrl && (
            <div className='mb-4'>
              <img
                src={imageUrl}
                alt='Course Image'
                className='w-64 h-40 object-cover rounded-lg'
              />
            </div>
          )}

          <button
            onClick={handleUploadButtonClick}
            className='px-4 py-2 bg-white text-[#00075e] border border-[#00075e] rounded-lg flex items-center gap-2 hover:bg-[#00075e] hover:text-white transition'
          >
            <FaImage /> {/* Icône d'image */}
            Sélectionner une image
          </button>

          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept='image/*'
          />

          {uploadLoading > 0 && (
            <div className=' pt-4 w-[20%]'>
              <Spinner />
            </div>
          )}

          {errorMessage && (
            <div className='text-red-500 mt-2'>{errorMessage}</div>
          )}
        </div>
      </EdictCourseLayout>
    </div>
  );
};

export default EdictICourseImage;

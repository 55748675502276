import { Link } from 'react-router-dom';
import { formatTime } from '../../utils/utils';
import { FaStar } from 'react-icons/fa';

const StudentCoursItem = ({ item }) => {
  return (
    <Link
      to={`/admin/etudiants/cours/${item._id}`}
      className='relative bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition duration-300 ease-in-out'
    >
      <div className='relative h-40'>
        <img
          src={item?.imageUrl}
          alt='Cours image'
          className='object-cover w-full h-full'
        />
        {item?.populaire && (
          <span className='absolute top-2 left-2 bg-purple-600 text-white text-xs font-semibold px-3 py-1 rounded-lg shadow-lg'>
            Populaire
          </span>
        )}
      </div>

      <div className='p-4'>
        <div className='flex items-center justify-between mb-2'>
          <h3 className='text-xl font-semibold text-gray-800 truncate'>
            {item.title}
          </h3>
          <FaStar className='text-yellow-400' />
        </div>

        <p
          dangerouslySetInnerHTML={{
            __html:
              item?.description && item.description.length > 50
                ? item.description.substring(0, 50) + '...'
                : item?.description,
          }}
          className='text-sm text-gray-500 mb-4'
        />

        <p className='text-xs text-gray-600'>
          Durée : {formatTime(item?.time)}
        </p>
        <p className='text-xs text-gray-600 mt-1'>Par : {item?.user?.name}</p>

        {/* Barre de progression */}
        <div className='mt-4'>
          <div className='flex justify-between text-xs text-gray-500 mb-1'>
            <span>Progression</span>
            <span>{item?.progressPercentage}%</span>
          </div>
          <div className='w-full bg-gray-200 rounded-full h-2'>
            <div
              className='bg-green-500 h-2 rounded-full'
              style={{ width: `${item?.progressPercentage}%` }}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StudentCoursItem;

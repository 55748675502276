import React from 'react';

const ErrorMessage = ({ message }) => {
  return (
    <div className='bg-red-500 text-white py-2 px-4 my-2 rounded-md text-center text-lg w-full'>
      {message}
    </div>
  );
};

export default ErrorMessage;

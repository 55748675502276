import { FAQS_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const faqsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addFaqs: builder.mutation({
      query: (data) => ({
        url: FAQS_URL,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getFaqs: builder.query({
      query: ({ token, id }) => ({
        url: FAQS_URL + '/' + id + '/list',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getOneFaqs: builder.query({
      query: ({ token, id }) => ({
        url: FAQS_URL + '/' + id,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    deleteFaqs: builder.mutation({
      query: ({ token, fasqId }) => ({
        url: FAQS_URL + '/' + fasqId,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    faqsUpdated: builder.mutation({
      query: (data) => ({
        url: FAQS_URL + '/' + data.id + '/lesson',
        body: data,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    faqsAddComment: builder.mutation({
      query: (data) => ({
        url: FAQS_URL + '/' + data.id + '/comment',
        body: data,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    toggleLike: builder.mutation({
      query: ({ token, id }) => ({
        url: FAQS_URL + '/' + id + '/like',
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useAddFaqsMutation,
  useGetFaqsQuery,
  useGetOneFaqsQuery,
  useFaqsUpdatedMutation,
  useDeleteFaqsMutation,
  useFaqsAddCommentMutation,
  useToggleLikeMutation,
} = faqsApiSlice;

import React from 'react';
import { motion } from 'framer-motion';
import FullButton from '../utilities/FullButton';
import { useNavigate } from 'react-router-dom';
import banner from '../../assets/ban.png';

const Banner = () => {
  const navigate = useNavigate();

  // Animation configuration
  const titleVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const descriptionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay: 0.2 } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: 0.4 },
    },
  };

  return (
    <section
      className='w-full h-[400px] relative bg-cover bg-center'
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className='text-start relative w-[90%] md:w-[80%] mx-auto z-10 py-12'>
        <motion.h1
          className='text-2xl md:text-4xl lg:w-[80%] font-bold mb-4'
          variants={titleVariants}
          initial='hidden'
          animate='visible'
        >
          Améliorez vos compétences en{' '}
          <span className='text-blue-600'>santé numérique</span> avec{' '}
          <span className='text-blue-600'>AcademieCeisna</span>.
        </motion.h1>
        <motion.p
          className='text-md md:text-lg mb-6'
          variants={descriptionVariants}
          initial='hidden'
          animate='visible'
        >
          Des formations pratiques en santé numérique pour vous aider à
          progresser rapidement dans votre carrière.
        </motion.p>
        <motion.div
          variants={buttonVariants}
          initial='hidden'
          animate='visible'
        >
          <FullButton
            onClick={() => navigate('admin/my-courses')}
            text='Commencez maintenant'
            className='bg-blue-600 text-white hover:bg-blue-700 transition duration-300'
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Banner;

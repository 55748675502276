import { useEffect, useState } from 'react';
import CourseContent from '../../components/learning/CourseContent';
import TabBar from '../../components/learning/TabBar';
import VideoLearning from '../../components/learning/VideoLearning';
import CourseQuestions from '../../components/learning/CourseQuestions';
import ReviewsSection from '../../components/learning/ReviewsSection';
import EvaluationProgram from '../../components/learning/EvaluationProgram';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { setSideBar } from '../../redux/slices/stateSlice';
import TextContent from '../../components/learning/TextContent';
import YouTubeVideo from '../../components/learning/YouTubeVideo';
import QuizContent from '../../components/learning/QuizContent';
import PDFViewer from '../../components/learning/PDFViewer';
import {
  useLearningQuery,
  useUpdatedLessonMutation,
} from '../../redux/slices/resgisterCouresSlice';
import Spinner from '../../components/spinner/Spinner';
import { useGetFaqsQuery } from '../../redux/slices/faqsApiSlice';
import { FaArrowLeft } from 'react-icons/fa';
import GenialyView from '../../components/learning/GenialyView';
import CanvaView from '../../components/learning/CanvaView';

function CourseLearning() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const token = user?.token;
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tabId = parseInt(searchParams.get('tabIndex'), 10) || 0;
  const [tabIndex, setTabIndex] = useState(tabId);

  useEffect(() => {
    setTabIndex(tabId);
  }, [tabId]);

  const { data, isLoading, refetch } = useLearningQuery({ token, id });

  const {
    data: faqs,
    isLoading: loading,
    isSuccess: success,
  } = useGetFaqsQuery({ token, id: data?.courseId });

  const [lessonUpdated, { isLoading: updateLading, isSuccess }] =
    useUpdatedLessonMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const [lesson, setLesson] = useState();
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (success && data && isFirstOpen) {
      const getLastCompletedLesson = (data) => {
        if (!data?.sections) return null;

        let lastCompletedLesson = null;

        data.sections.forEach((section) => {
          section.lessons.forEach((lesson) => {
            lesson.parts.forEach((part) => {
              if (part?.isCompled) {
                if (
                  !lastCompletedLesson ||
                  new Date(part.completedAt) >
                    new Date(lastCompletedLesson.completedAt)
                ) {
                  lastCompletedLesson = {
                    ...lesson,
                    completedAt: part.completedAt,
                  };
                }
              }
            });
          });
        });

        return lastCompletedLesson;
      };

      const lastCompletedLesson = getLastCompletedLesson(data);
      setLesson(lastCompletedLesson || data?.sections[0]?.lessons[0]);
    }
  }, [success, isFirstOpen, data]);

  const updateLessonCompletion = () => {
    if (lesson) {
      data.sections.forEach((section, sectionIndex) => {
        section.lessons.forEach(async (l, lessonIndex) => {
          if (l._id === lesson._id) {
            const updatedLesson = {
              ...l,
              parts: l.parts.map((part, index) =>
                index === 0 ? { ...part, isCompled: true } : part
              ),
            };
            if (updatedLesson._id === lesson._id) {
              try {
                await lessonUpdated({
                  ...updatedLesson,
                  token,
                  id: data._id,
                }).unwrap();
                let nextLesson = null;
                const nextLessonIndex = lessonIndex + 1;
                if (nextLessonIndex < section.lessons.length) {
                  nextLesson = section.lessons[nextLessonIndex];
                } else if (sectionIndex + 1 < data.sections.length) {
                  nextLesson = data.sections[sectionIndex + 1].lessons[0];
                }
                setLesson(nextLesson || updatedLesson);
              } catch (error) {
                console.log('error', error);
              }
            }
          }
        });
      });
    }
  };

  const updateLessonTextCompletion = () => {
    if (lesson) {
      data?.sections.map((section) => {
        section.lessons.map(async (l) => {
          if (l._id === lesson._id) {
            const updatedLesson = {
              ...l,
              parts: l.parts.map((part, index) =>
                index === 0 ? { ...part, isCompled: true } : part
              ),
            };
            if (updatedLesson._id === lesson._id) {
              try {
                await lessonUpdated({
                  ...updatedLesson,
                  token,
                  id: data._id,
                }).unwrap();
              } catch (error) {
                console.log('error', error);
              }
              setLesson(updatedLesson);
            }
          }
        });
      });
    }
  };

  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  const renderContent = () => {
    if (!lesson)
      return (
        <div className=' h-[90vh] flex items-center justify-center w-full'>
          <Spinner />
        </div>
      );

    switch (lesson?.parts[0]?.type) {
      case 'lecture':
        return lesson && lesson.parts && lesson.parts[0].video ? (
          <VideoLearning
            isFirstOpen={isFirstOpen}
            lesson={lesson}
            onComplete={updateLessonCompletion}
          />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold '>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );
      case 'lien youtube':
        return lesson && lesson.parts && lesson.parts[0].youtubeLink ? (
          <YouTubeVideo
            isFirstOpen={isFirstOpen}
            lesson={lesson}
            onComplete={updateLessonCompletion}
          />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold '>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );
      case 'texte':
        return lesson && lesson.parts && lesson.parts[0].text ? (
          <TextContent
            lesson={lesson}
            onComplete={updateLessonTextCompletion}
          />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold'>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );
      case 'document':
        return lesson && lesson.parts && lesson.parts[0].document ? (
          <PDFViewer lesson={lesson} onComplete={updateLessonTextCompletion} />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold'>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );

      case 'genialy':
        return lesson && lesson.parts && lesson.parts[0].genialy ? (
          <GenialyView
            lesson={lesson}
            onComplete={updateLessonTextCompletion}
          />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold'>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );

      case 'canva':
        return lesson && lesson.parts && lesson.parts[0].canva ? (
          <CanvaView lesson={lesson} onComplete={updateLessonTextCompletion} />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold'>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );
      case 'quiz':
        return lesson && lesson.parts && lesson.parts[0].answer ? (
          <QuizContent
            lesson={lesson}
            onComplete={updateLessonTextCompletion}
          />
        ) : (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold'>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );
      default:
        return (
          <div className='flex justify-center items-center h-[65vh] bg-gray-50'>
            <h3 className=' text-3xl text-[#19142d] font-semibold'>
              Aucun contenu n'a encore été associé à cette leçon.
            </h3>
          </div>
        );
    }
  };

  if (!user) {
    return <Navigate to='/auth' />;
  }

  if (isLoading) {
    return (
      <div className=' h-[90vh] flex items-center justify-center w-full'>
        <Spinner />
      </div>
    );
  }

  const goBack = () => {
    navigate(-1);
  };

  if (!data) {
    return (
      <div className='min-h-[85vh] flex flex-col items-center justify-center bg-gray-50 p-6'>
        <div className='text-center flex flex-col justify-center items-center max-w-lg'>
          <h1 className='text-6xl font-extrabold text-[#1e1a65] mb-4'>404</h1>
          <h2 className='text-3xl font-semibold text-gray-800 mb-2'>
            Cours introuvable
          </h2>
          <p className='text-lg text-gray-600 mb-8'>
            Désolé, le cours que vous essayez de consulter n'existe pas ou a été
            supprimé. Veuillez vérifier l'URL ou revenir à l'accueil pour
            explorer d'autres cours.
          </p>
          <button
            onClick={goBack}
            className='flex items-center gap-2 bg-[#1e1a65] hover:bg-[#181550] text-white font-semibold px-6 py-2 rounded-full shadow-md transition-colors duration-300 ease-in-out'
          >
            <FaArrowLeft className='h-5 w-5' />
            Retour
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='min-h-[80vh]'>
      <div className='  w-full'>{renderContent()}</div>
      <TabBar id={id} tabIndex={tabIndex} />
      {tabIndex === 0 && (
        <CourseContent
          lesson={lesson}
          onFirstOpen={setIsFirstOpen}
          sections={data?.sections}
          onLessonClick={setLesson}
        />
      )}
      {tabIndex === 1 && <CourseQuestions faqs={faqs} />}
      {tabIndex === 2 && (
        <ReviewsSection token={token} courseId={data.courseId} id={data._id} />
      )}
      {tabIndex === 3 && (
        <EvaluationProgram user={user} token={token} id={data._id} />
      )}
    </div>
  );
}

export default CourseLearning;

import UserLayout from '../../components/admin/UserLayout';
import { useEffect, useState } from 'react';
import AdminCoursItem from '../../components/admin/AdminCoursItem';
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
import ErrorMessage from '../../components/message/ErrorMessage';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTutorCoursesQuery } from '../../redux/slices/courseApiSlice';

const InstructorCourse = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const [title, setTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();

  const { data, isLoading, isSuccess, refetch } = useTutorCoursesQuery({
    currentPage,
    title,
    id,
    token,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const backUrl = '/admin/formateurs';
  return (
    <UserLayout backUrl={backUrl}>
      <div className=' mb-3 w-full'>
        {data && data.courses && data.courses.length > 0 && (
          <div className=' mb-3 flex items-center w-full'>
            <input
              type='text'
              placeholder='Rechercher un cours'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className='p-2 border rounded flex-1'
            />
          </div>
        )}

        {errorMessage && (
          <div className='w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
            <span className='flex text-red-600'>{errorMessage}</span>
          </div>
        )}
        {isLoading ? (
          <Spinner />
        ) : data && data.courses && data.courses.length > 0 ? (
          <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
            {data.courses.map((item) => (
              <AdminCoursItem
                refetch={refetch}
                item={item}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                key={item.id}
              />
            ))}
          </div>
        ) : (
          <div className='w-full'>
            <ErrorMessage message='Aucun Cours trouvé' />
          </div>
        )}

        {data && data.courses && data.courses.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={data?.pages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </UserLayout>
  );
};

export default InstructorCourse;

import React, { useEffect, useState } from 'react';
import AuthInput from '../../components/auth/AuthInput';
import FullButton from '../../components/utilities/FullButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { setSideBar } from '../../redux/slices/stateSlice';
import {
  useSignInMutation,
  useSignUpMutation,
} from '../../redux/slices/userApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { setCredentials } from '../../redux/slices/authSlice';

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const Auth = () => {
  const { user } = useSelector((state) => state.auth);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    signUp,
    {
      isLoading: signUpLoading,
      isSuccess: signUpSuccess,
      isError: isSignUpError,
      error: signUpError,
    },
  ] = useSignUpMutation();

  const [
    signIn,
    {
      isLoading: signInLoading,
      isSuccess: signInSucces,
      isError: isSignInError,
      error: signInError,
    },
  ] = useSignInMutation();

  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  useEffect(() => {
    if ((!signInLoading && signInSucces) || (!signUpLoading && signUpSuccess)) {
      navigate('/admin/profile');
      window.location.reload();
    }
  }, [signInSucces, signUpSuccess]);

  const handleLogin = async (e) => {
    e.preventDefault();

    setTimeout(() => {
      setErrorMessage('');
    }, 5000);

    if (!email) {
      setErrorMessage('Email est obligatoire');
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage("Email n'est pas valide");
      return;
    }

    if (password.trim() === '') {
      setErrorMessage('Mot de passe est obligatoire');
      return;
    }

    try {
      if (isLogin) {
        const response = await signIn({ email, name, password }).unwrap();
        dispatch(setCredentials({ ...response }));
        return;
      } else {
        if (password.trim() !== confirmPassword.trim()) {
          setErrorMessage('Mot de passe est obligatoire');
          return;
        }
        if (!name) {
          setErrorMessage("Nom d'utilisateur est obligatoire");
          return;
        }
        const response = await signUp({ email, name, password }).unwrap();
        dispatch(setCredentials({ ...response }));
      }
    } catch (error) {
      setErrorMessage(error.data.message);
    }
  };

  if (user) {
    return <Navigate to='/admin/profile' />;
  }

  return (
    <div className='min-h-[85vh] flex items-center justify-center bg-gray-100'>
      <div className='w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md'>
        <div className=' '>
          {errorMessage && (
            <span className='  flex p-2 bg-red-100 text-red-600'>
              {errorMessage}
            </span>
          )}
          <h2 className='text-center text-3xl font-extrabold text-gray-900'>
            {isLogin ? 'Connexion' : 'Inscription'}
          </h2>
        </div>

        <form className='mt-8 flex flex-col  space-y-2' onSubmit={handleLogin}>
          {!isLogin && (
            <AuthInput
              setValue={setName}
              value={name}
              type='text'
              label="Nom d'utilisateur"
              placeholder="Nom d'utilisateur"
            />
          )}

          <AuthInput
            setValue={setEmail}
            value={email}
            type='email'
            label='Adresse email'
            placeholder='Adresse email'
          />
          <AuthInput
            setValue={setPassword}
            value={password}
            type='password'
            label=' Mot de passe'
            placeholder='Mot de passe'
          />
          {!isLogin && (
            <AuthInput
              setValue={setConfirmPassword}
              value={confirmPassword}
              type='password'
              label=' confirmation mot de passe'
              placeholder='confirmer le mot de passe'
            />
          )}

          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <input
                id='remember_me'
                name='remember_me'
                type='checkbox'
                className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
              />
              <label
                htmlFor='remember_me'
                className='ml-2 block text-sm text-gray-900'
              >
                Se souvenir de moi
              </label>
            </div>

            <div className='text-sm'>
              <Link
                to='/forgot-password'
                className='font-medium text-blue-600 hover:text-blue-500'
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </div>
          {signInLoading || signUpLoading ? (
            <Spinner />
          ) : (
            <FullButton
              text={`${isLogin ? 'Se connecter' : "S'inscrire"} `}
              type='submit'
            />
          )}

          <div className='text-sm flex items-center space-x-1'>
            <span>
              {isLogin
                ? "Vous n'avez pas encore de compte?"
                : 'Vous avez dejà un compte?'}{' '}
            </span>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsLogin(!isLogin);
              }}
              className='font-medium text-blue-600 hover:text-blue-500'
            >
              {isLogin ? 'Créer un compte' : 'Connexion'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Auth;

import { useDrag, useDrop } from 'react-dnd';
import { useState } from 'react';
import CoursLessons from './CoursLessons';

const ItemTypes = {
  SECTION: 'section',
  LESSON: 'lesson',
};

const CoursSection = ({
  section,
  sectionIndex,
  moveSection,
  moveLesson,
  addLesson,
  updateLesson,
  deleteSection,
  handleDeleteLesson,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.SECTION,
    item: { sectionIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.SECTION,
    hover: (draggedItem) => {
      if (draggedItem.sectionIndex !== sectionIndex) {
        moveSection(draggedItem.sectionIndex, sectionIndex);
        draggedItem.sectionIndex = sectionIndex;
      }
    },
  }));

  const [showLessonInput, setShowLessonInput] = useState(false);
  const [newLessonTitle, setNewLessonTitle] = useState('');

  const handleAddLesson = () => {
    addLesson(sectionIndex, newLessonTitle);
    setNewLessonTitle('');
    setShowLessonInput(false);
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`p-4 border rounded-lg mb-4 shadow-sm bg-white ${
        isDragging ? 'bg-gray-200' : 'bg-white'
      }`}
    >
      <div className='flex justify-between items-center'>
        <input
          type='text'
          value={section.title}
          onChange={(e) =>
            updateLesson(
              sectionIndex,
              null,
              'sectionTitle',
              null,
              null,
              e.target.value
            )
          }
          className='font-semibold text-lg p-1 border rounded w-4/5'
        />
        <button
          onClick={() => deleteSection(sectionIndex)}
          className='px-3 py-1 rounded-lg'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='size-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18 18 6M6 6l12 12'
            />
          </svg>
        </button>
      </div>

      <div className='mt-4'>
        {section.lessons.map((lesson, lessonIndex) => (
          <CoursLessons
            key={lessonIndex}
            lesson={lesson}
            sectionIndex={sectionIndex}
            lessonIndex={lessonIndex}
            moveLesson={moveLesson}
            updateLesson={updateLesson}
            handleDeleteLesson={handleDeleteLesson}
          />
        ))}
      </div>

      {showLessonInput ? (
        <div className='flex mt-2'>
          <input
            type='text'
            placeholder='Entrer le titre de la leçon'
            value={newLessonTitle}
            onChange={(e) => setNewLessonTitle(e.target.value)}
            className='p-2 border rounded flex-1'
          />
          <button
            onClick={handleAddLesson}
            className='ml-2 px-4 py-2 bg-gray-100 rounded-lg'
          >
            + Leçon
          </button>
        </div>
      ) : (
        <button
          className='mt-2 px-4 py-2 bg-gray-100 rounded-lg'
          onClick={() => setShowLessonInput(true)}
        >
          + Ajouter une Leçon
        </button>
      )}
    </div>
  );
};

export default CoursSection;

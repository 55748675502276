import React from 'react';

const SuccessMessage = ({ message }) => {
  return (
    <div className='bg-green-500 text-white py-2 px-4 my-2 rounded-md text-center text-lg w-full box-border'>
      {message}
    </div>
  );
};

export default SuccessMessage;

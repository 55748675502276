import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import AdminItem from './AdminItem';
import { FaBook, FaFileAlt, FaCertificate } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSideBar } from '../../redux/slices/stateSlice';
import { useEffect } from 'react';
import { useGetUserQuery } from '../../redux/slices/userApiSlice';
import { FaArrowLeft } from 'react-icons/fa';

const UserLayout = ({ children, backUrl }) => {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const token = user?.token;
  const navigate = useNavigate();

  const {
    data: instructor,
    isLoading: instructorLoading,
    isSuccess: instructorSuccess,
  } = useGetUserQuery({ token, id });

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  const studentItems = [
    {
      url: `/admin/student/courses/${id}`,
      texte: 'Cours',
      icon: <FaBook />,
    },
    {
      url: `/admin/student/exams/${id}`,
      texte: 'Examens',
      icon: <FaFileAlt />,
    },
    {
      url: `/admin/user/certificat/${id}`,
      texte: 'Certificats',
      icon: <FaCertificate />,
    },
  ];

  const tutorItems = [
    {
      url: `/admin/instructor/courses/${id}`,
      texte: 'Cours',
      icon: <FaBook />,
    },
  ];

  if (!user) {
    return <Navigate to='/auth' />;
  }

  if (user && !user.isAdmin) {
    return <Navigate to='/auth' />;
  }

  return (
    <div className='flex flex-col  md:flex-row mt-5 md:mt-[4.5rem] md:space-x-10 pb-6 w-[90%] mx-auto  md:container m-auto min-h-[78vh] '>
      <div className=' relative'>
        <div className='fixed top-16 '>
          <button
            className='flex items-center gap-2 px-4 py-2 text-[#000000] bg-gray-100  hover:bg-gray-100 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-50'
            onClick={() => navigate(backUrl)}
          >
            <FaArrowLeft className='h-5 w-5' />
            Retour
          </button>
        </div>

        <div className=' w-full md:w-64 bg-[#ffffff]   p-4'>
          <div className='bg-white   border-b-[2px] border-gray-100 flex flex-col items-center'>
            {instructor?.imageUrl ? (
              <img
                className='w-16 h-16 rounded-full object-cover mb-4'
                src={instructor?.imageUrl}
                alt={instructor?.name}
              />
            ) : (
              <div className='w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-lg font-bold text-gray-600 mb-4'>
                {instructor?.name
                  .split(' ')
                  .map((n) => n[0])
                  .join('')}
              </div>
            )}
            <h3 className='text-xl font-semibold text-gray-700 mb-2'>
              {user.name}
            </h3>
            <p className='text-gray-500'>
              {user?.isTeacher ? 'Instructeur' : 'Etudiant'}
            </p>
          </div>
          <ul>
            {instructor?.isStudent
              ? studentItems.map((item, index) => (
                  <AdminItem key={index} {...item} />
                ))
              : tutorItems.map((item, index) => (
                  <AdminItem key={index} {...item} />
                ))}
          </ul>
        </div>
      </div>

      {children}
    </div>
  );
};

export default UserLayout;

import React, { useEffect, useState } from 'react';
import {
  FaUser,
  FaEnvelope,
  FaBook,
  FaFileAlt,
  FaGlobe,
  FaWallet,
  FaHandshake,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSideBar } from '../../redux/slices/stateSlice';
import { useSendBecomeTutorMutation } from '../../redux/slices/messageApiSlice';
import SuccessMessage from '../../components/message/SuccessMessage';
import Spinner from '../../components/spinner/Spinner';

const BecomeTutor = () => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [sendBecomeTutor, { isLoading, isSuccess, isError }] =
    useSendBecomeTutorMutation();

  useEffect(() => {
    if (isSuccess) {
      setSuccessMessage('Votre demande a été envoyée avec succès.');
      setFormData({
        fullName: '',
        email: '',
        expertise: '',
        description: '',
      });
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000);

      // Nettoyage pour éviter les fuites de mémoire
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setSideBar(false));
  }, []);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    expertise: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form data:', formData);

    try {
      await sendBecomeTutor(formData).unwrap();
    } catch (error) {
      setErrorMessage("Erreur lors de l'envoi de votre candidature");
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };

  return (
    <main className='min-h-screen bg-gradient-to-br mb-12 '>
      <section className='bg-blue-900 text-white py-16'>
        <motion.div
          className='container mx-auto px-6 text-center'
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className='text-5xl font-bold tracking-wide'>
            Devenir Formateur sur{' '}
            <span className='text-yellow-400'>Académie Ceisna</span>
          </h1>
          <p className='mt-6 text-lg'>
            Partagez vos connaissances et inspirez des apprenants dans le monde
            entier.
          </p>
        </motion.div>
      </section>

      <section className=' container mx-auto px-6 py-5'>
        <motion.h2
          className='text-3xl font-semibold text-gray-800 mb-6'
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Pourquoi nous rejoindre ?
        </motion.h2>
        <div className='grid md:grid-cols-3 gap-8'>
          {[
            {
              title: 'Impact Mondial',
              description:
                "Enseignez à des milliers d'étudiants à travers le monde.",
              icon: <FaGlobe className='text-blue-900 text-4xl mx-auto' />,
            },
            {
              title: 'Accompagnement Personnalisé',
              description:
                'Recevez un soutien pour créer et promouvoir vos cours.',
              icon: <FaHandshake className='text-blue-900 text-4xl mx-auto' />,
            },
            {
              title: 'Revenus Compétitifs',
              description: 'Gagnez un revenu flexible selon vos efforts.',
              icon: <FaWallet className='text-blue-900 text-4xl mx-auto' />,
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              className='bg-white shadow-sm rounded-lg p-6 text-center'
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              {item.icon}
              <h3 className='text-xl font-bold mt-4 mb-2'>{item.title}</h3>
              <p className='text-gray-600'>{item.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className='bg-gray-100 py-8'>
        <motion.div
          className='container mx-auto px-6 text-center'
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className='text-3xl font-semibold text-gray-800 mb-6'>
            Témoignages de nos formateurs
          </h2>
          <p className='text-lg text-gray-600 mb-12'>
            Découvrez ce que disent nos formateurs sur leur expérience avec{' '}
            <span className='text-blue-900'>Académie Ceisna</span>.
          </p>
          <div className='grid md:grid-cols-3 gap-8'>
            {[
              {
                name: 'Amina B.',
                quote:
                  "Devenir formatrice sur Académie Ceisna a été l'une des meilleures décisions de ma carrière. J'adore partager mes connaissances avec des apprenants motivés.",
                imgSrc: 'https://via.placeholder.com/150',
              },
              {
                name: 'Jean-Pierre M.',
                quote:
                  "La plateforme m'a permis d'atteindre des étudiants du monde entier. Le support et les outils offerts sont vraiment utiles.",
                imgSrc: 'https://via.placeholder.com/150',
              },
              {
                name: 'Fatou D.',
                quote:
                  "Grâce à Académie Ceisna, j'ai pu diversifier mes revenus tout en faisant ce que j'aime : enseigner.",
                imgSrc: 'https://via.placeholder.com/150',
              },
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                className='bg-white shadow-sm rounded-lg p-6 text-center'
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <img
                  src={testimonial.imgSrc}
                  alt={`Photo de ${testimonial.name}`}
                  className='w-24 h-24 mx-auto rounded-full mb-4'
                />
                <p className='text-gray-700 italic'>"{testimonial.quote}"</p>
                <h4 className='text-blue-900 font-bold mt-4'>
                  {testimonial.name}
                </h4>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Form Section */}
      <section className='bg-white shadow-sm rounded-lg p-8 container mx-auto px-6 '>
        <h2 className='text-3xl font-semibold text-gray-800 mb-6'>
          Postulez pour devenir formateur
        </h2>

        {successMessage && <SuccessMessage message={successMessage} />}
        <form onSubmit={handleSubmit} className='space-y-6'>
          {[
            {
              id: 'fullName',
              label: 'Nom Complet',
              placeholder: 'Votre nom complet',
              type: 'text',
              icon: <FaUser />,
            },
            {
              id: 'email',
              label: 'Adresse Email',
              placeholder: 'Votre email',
              type: 'email',
              icon: <FaEnvelope />,
            },
            {
              id: 'expertise',
              label: "Domaine d'Expertise",
              placeholder: 'Votre domaine de compétence',
              type: 'text',
              icon: <FaBook />,
            },
          ].map((field, index) => (
            <div key={index} className='relative'>
              <label
                className='block text-gray-700 font-medium mb-2'
                htmlFor={field.id}
              >
                {field.label}
              </label>
              <div className='flex items-center'>
                <div className='absolute pl-3 text-blue-900'>{field.icon}</div>
                <input
                  type={field.type}
                  id={field.id}
                  name={field.id}
                  value={formData[field.id]}
                  onChange={handleChange}
                  required
                  className='w-full border border-gray-300 rounded-lg pl-10 p-3'
                  placeholder={field.placeholder}
                />
              </div>
            </div>
          ))}

          <div>
            <label
              className='block text-gray-700 font-medium mb-2'
              htmlFor='description'
            >
              Présentez-vous
            </label>
            <textarea
              id='description'
              name='description'
              value={formData.description}
              onChange={handleChange}
              required
              className='w-full border border-gray-300 rounded-lg p-3'
              placeholder='Décrivez vos compétences et motivations'
              rows='5'
            ></textarea>
          </div>

          {/* Submit Button */}

          {isLoading ? (
            <Spinner />
          ) : (
            <motion.div
              className='text-center'
              initial={{ scale: 0.8 }}
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 0.9 }}
            >
              <button
                type='submit'
                className='bg-blue-900 text-white px-6 py-3 rounded-lg font-medium shadow-md hover:bg-blue-700 transition'
              >
                Envoyer ma candidature
              </button>
            </motion.div>
          )}
        </form>
      </section>
    </main>
  );
};

export default BecomeTutor;

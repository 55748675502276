import Description from './Description';
import ProgressionCourse from './ProgressionCourse';
import CourseCompetences from './CourseCompetences';
import Certificat from './Certificat';
import AutorProfil from './AutorProfil';
import RatingSection from './RatingSection';
import ReviewSection from './ReviewSection';
import ConnexeCours from './ConnexeCours';
import Recommandations from './Recommandations';
import Language from './Language';

const DetailsLayout = ({ otherCourses, reviews, data, courseBycategory }) => {
  return (
    <div className=' p-4 lg:p-10  gap-8 grid grid-cols-2 lg:grid-cols-3 '>
      <div className=' col-span-2'>
        <div className=' lg:hidden block'>
          <ProgressionCourse data={data} />
        </div>
        <Description data={data} />
        <CourseCompetences data={data} />
        <Language data={data} />
        <Certificat data={data} />
        <AutorProfil data={data} />
        <RatingSection reviews={reviews} />
        <ReviewSection reviews={reviews} />
        <Recommandations otherCourses={otherCourses} />
      </div>
      <div className=' hidden lg:block'>
        <ProgressionCourse data={data} />
        {courseBycategory.length > 0 && (
          <ConnexeCours courseBycategory={courseBycategory} />
        )}
      </div>
    </div>
  );
};

export default DetailsLayout;

import React, { useEffect, useState } from 'react';

import AdminLayout from '../../components/admin/AdminLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { categories } from '../../data/data';
import SelectBox from '../../components/selectBox/SelectBox';
import { useUpdateCourseFieldMutation } from '../../redux/slices/courseApiSlice';
import { useSelector } from 'react-redux';

function CoursCategorie() {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const params = useParams();
  const { id } = params;
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isSuccess && !isLoading) {
      navigate(`/admin/create-cours/programme/edict/${id}`);
    }
  }, [isSuccess]);

  const addCourseHandler = async () => {
    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'category',
        value: category.name,
      }).unwrap();
    } catch (err) {
      setErrorMessage(err.data.message);
    }
  };

  return (
    <main className={` flex-1`}>
      <AdminLayout>
        <div className='w-full'>
          <div className='flex flex-col items-center justify-center min-h-[60vh] bg-gray-50'>
            <div className='w-full bg-white py-4 px-8 flex justify-between items-center'>
              <div className='text-xl font-semibold text-[#00075e]'>
                Gestion des Cours
              </div>
              <button
                onClick={() => navigate('/admin/courses')}
                className='text-[#00075e] hover:underline'
              >
                Quitter
              </button>
            </div>

            <div className='w-full bg-gray-300 h-1 mt-4'>
              <div className='bg-[#3f55e7] h-1' style={{ width: '100%' }}></div>
            </div>
            {errorMessage && (
              <span className='  flex p-2 bg-red-100 text-red-600'>
                {errorMessage}
              </span>
            )}
            <div className='flex-1 flex flex-col items-center justify-center px-4 text-center'>
              <h1 className='text-2xl font-bold mb-2'>
                Choisissez une catégorie
              </h1>
              <p className='text-gray-500 mb-8'>
                Sélectionnez la catégorie qui correspond le mieux au contenu de
                votre cours.
              </p>
              <div className='w-full max-w-md'>
                <SelectBox
                  value={category}
                  setValue={setCategory}
                  options={categories}
                  defaultText='Veuillez selectionner la catégorie'
                />
              </div>
            </div>

            <div className='w-full flex justify-between items-center p-4 bg-white shadow-inner'>
              <button
                onClick={() => navigate('/admin/create-cours/title')}
                className='text-gray-600 hover:underline'
              >
                Précédent
              </button>
              <button
                onClick={addCourseHandler}
                className={`px-6 py-2 text-white rounded-lg ${
                  category
                    ? 'bg-[#00075e] hover:bg-[#111438]'
                    : 'bg-gray-300 cursor-not-allowed'
                }`}
                disabled={!category}
              >
                Continuer
              </button>
            </div>
          </div>
        </div>
      </AdminLayout>
    </main>
  );
}

export default CoursCategorie;

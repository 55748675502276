import { useState } from 'react';
import FaqsCard from '../faqs/FaqsCard';

const StudentCourseQuestions = ({ faqs }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Recommandé');

  const handleSearch = () => {};

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  return (
    <div className='container  mx-auto p-4'>
      <h2 className='font-semibold text-2xl mb-4'>Questions du cours</h2>

      <div className='flex items-center mb-4'>
        <input
          type='text'
          placeholder='Rechercher des questions'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className='border border-gray-300 px-4 py-2 w-full max-w-lg'
        />
        <button
          onClick={handleSearch}
          className='bg-black text-white px-4 py-2 ml-2'
        >
          Rechercher
        </button>
      </div>

      <div className='flex space-x-4 mb-4'>
        <select
          value={sortOption}
          onChange={handleSortChange}
          className='border border-gray-300 px-4 py-2'
        >
          <option value='Recommandé'>Trier par recommandé</option>
          <option value='Les plus récents'>Trier par plus récents</option>
          <option value='Les plus anciens'>Trier par plus anciens</option>
        </select>
      </div>

      <div>
        {faqs && faqs.length > 0 ? (
          faqs?.map((question, index) => (
            <FaqsCard isAdmin={false} key={index} question={question} />
          ))
        ) : (
          <p className=' font-bold text-[1.2rem]'>Aucun faqs disponible</p>
        )}
      </div>
    </div>
  );
};

export default StudentCourseQuestions;

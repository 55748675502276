import React from 'react';

const RatingSection = ({ reviews }) => {
  return (
    <div className='w-full  py-2'>
      <div className='flex items-center'>
        <span className='text-4xl font-bold'>
          {reviews?.reviews[0]?.rating ?? 0}
        </span>
        <span className='text-lg ml-2'>sur 5</span>
      </div>

      <div className='flex items-center mb-4'>
        <div className='flex space-x-1 text-yellow-500'>
          {Array.from({ length: 5 }, (_, index) => (
            <span
              key={index}
              className={`text-xl ${
                index < reviews?.reviews[0]?.rating ?? 0
                  ? 'text-yellow-500'
                  : 'text-gray-500'
              }`}
            >
              ★
            </span>
          ))}
        </div>
        <span className='ml-2 text-gray-600'>
          {reviews?.reviews[0]?.reviews?.length ?? 0} notes
        </span>
      </div>

      <div className='space-y-2'>
        {reviews?.percentages?.map((pourcentage, index) => (
          <div key={index} className='flex items-center space-x-1 mb-1'>
            <span>{5 - index} </span>
            <span className='text-yellow-500'>★★★★★</span>
            <div className='w-full bg-gray-200 h-2 mx-2 relative'>
              <div
                className='bg-yellow-500 h-2'
                style={{ width: `${pourcentage}%` }}
              ></div>
            </div>
            <span className='text-gray-500'>{pourcentage}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingSection;

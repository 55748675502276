import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CoursSection from '../../components/admin/CoursSection';
import CoursLayout from '../../components/admin/CoursLayout';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetCourseQuery,
  useUpdateCourseFieldMutation,
} from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import BlackButton from '../../components/utilities/BlackButton';
import { useSelector } from 'react-redux';
import {
  getYouTubeVideoId,
  uploadVideoAwsDuration,
  YouTubeVideoDuration,
} from '../../utils/utils';
import EdictCourseLayout from '../../components/admin/EdictCourseLayout';

// Curriculum Builder Component
const CurriculumBuilder = () => {
  const params = useParams();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const [errorMessage, setErrorMessage] = useState('');
  const [isFormatted, setIsFormatted] = useState(false);
  const { id } = params;

  const [sections, setSections] = useState([
    { title: 'Section 1: Introduction', lessons: [] },
    { title: 'Section 2: Basics', lessons: [] },
  ]);

  const [updateCourseField, { isSuccess, isLoading }] =
    useUpdateCourseFieldMutation();

  const {
    data,
    isSuccess: getSuccess,
    isLoading: getLoading,
    refetch,
  } = useGetCourseQuery(id);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  useEffect(() => {
    if (getSuccess && data?.sections) {
      const updated = data.sections.map((section) => ({
        title: section.title,
        lessons: section.lessons.map((lesson) => ({
          title: lesson.title,
          time: lesson.time,
          parts: lesson.parts.map((part) => ({
            type: part.type,
            answer: part.answer,
            canva: part.canva,
            genialy: part.genialy,
            choiceType: part.choiceType,
            question: part.question,
            video: part.video,
            evaluation: part.evaluation,
            text: part.text,
            document: part.document,
            youtubeLink: part.youtubeLink,
            isCompled: part.isCompled,
            choices: part?.choices,
            time: part.time,
          })),
        })),
      }));

      setSections(updated);
      setIsFormatted(true);
    }
  }, [getSuccess, location.pathname, data?.sections]);

  const updateField = async () => {
    console.log('setion', sections);

    const formattedSections = await Promise.all(
      sections.map(async (section) => {
        try {
          // Formater les leçons de chaque section
          const formattedLessons = await Promise.all(
            section.lessons.map(async (lesson) => {
              try {
                const formattedParts = await Promise.all(
                  lesson.parts.map(async (part) => {
                    try {
                      if (part.type === 'lecture') {
                        const videoTime = await uploadVideoAwsDuration(
                          part.video
                        );

                        return {
                          type: part.type,
                          video: part.video || '',
                          time: videoTime || 0,
                        };
                      }

                      if (part.type === 'quiz') {
                        return {
                          type: part.type,
                          question: part.question || '',
                          choiceType: part.choiceType || 'multiple',
                          answer: part.answer || '',
                          choices: part.choices || [],
                          time: 1, // Définir un temps par défaut pour les quiz
                        };
                      }

                      if (part.type === 'texte') {
                        return {
                          type: part.type,
                          text: part.text || '',
                          time: 1, // Définir un temps par défaut pour le texte
                        };
                      }

                      if (part.type === 'document') {
                        return {
                          type: part.type,
                          document: part.document || '',
                          time: 1, // Définir un temps par défaut pour les documents
                        };
                      }

                      if (part.type === 'lien youtube') {
                        const vId = getYouTubeVideoId(part.youtubeLink); // Obtenir l'ID de la vidéo YouTube
                        const apiKey =
                          'AIzaSyADcxMYlDZmbO2v_ghTYdier_Xox8WzPaM';
                        const duration = await YouTubeVideoDuration(
                          vId,
                          apiKey
                        );

                        // Appel async pour la durée de la vidéo YouTube
                        return {
                          type: part.type,
                          youtubeLink: part.youtubeLink || '',
                          time: duration || 0, // Durée de la vidéo YouTube
                        };
                      }

                      if (part.type === 'genialy') {
                        return {
                          type: part.type,
                          genialy: part.genialy || '',
                          time: 1, // Temps par défaut pour Genially
                        };
                      }

                      if (part.type === 'canva') {
                        return {
                          type: part.type,
                          canva: part.canva || '',
                          time: 1, // Temps par défaut pour Canva
                        };
                      }

                      return part; // Si aucun type ne correspond, retourner la partie telle quelle
                    } catch (error) {
                      console.error(
                        `Erreur lors du traitement de la partie ${part.type}:`,
                        error
                      );
                      return part; // En cas d'erreur, retourner la partie sans modification
                    }
                  })
                );

                return {
                  ...lesson, // Garder toutes les propriétés de la leçon intactes
                  parts: formattedParts, // Mettre à jour les parties formatées
                };
              } catch (error) {
                console.error(
                  `Erreur lors du traitement de la leçon ${lesson.title}:`,
                  error
                );
                return lesson; // En cas d'erreur, retourner la leçon sans modification
              }
            })
          );

          return {
            ...section, // Garder toutes les propriétés de la section intactes
            lessons: formattedLessons, // Mettre à jour les leçons formatées
          };
        } catch (error) {
          console.error(
            `Erreur lors du traitement de la section ${section.title}:`,
            error
          );
          return section;
        }
      })
    );

    try {
      await updateCourseField({
        id: id,
        token: token,
        field: 'sections',
        value: formattedSections,
      }).unwrap();
    } catch (error) {
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
      setErrorMessage(error.data.message);
    }
  };

  const addSection = (newSectionTitle) => {
    setSections([...sections, { title: newSectionTitle, lessons: [] }]);
  };

  const deleteSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  //   const moveSection = (fromIndex, toIndex) => {
  //     const updatedSections = [...sections];
  //     const [movedSection] = updatedSections.splice(fromIndex, 1);
  //     updatedSections.splice(toIndex, 0, movedSection);
  //     setSections(updatedSections);
  //   };

  const moveSection = (fromIndex, toIndex) => {
    setSections((prevSections) => {
      const updatedSections = [...prevSections];

      if (
        fromIndex < 0 ||
        fromIndex >= updatedSections.length ||
        toIndex < 0 ||
        toIndex >= updatedSections.length
      ) {
        return updatedSections;
      }

      const [movedSection] = updatedSections.splice(fromIndex, 1);
      updatedSections.splice(toIndex, 0, movedSection);

      updatedSections.forEach((section, index) => {
        section.index = index;
      });

      return updatedSections;
    });
  };

  const addLesson = (sectionIndex, lessonTitle) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lessons.push({
      title: lessonTitle,
      parts: [],
    });
    setSections(updatedSections);
  };

  const moveLesson = (fromIndex, toIndex, sectionIndex) => {
    const updatedSections = [...sections];
    const lessons = updatedSections[sectionIndex].lessons;
    const [movedLesson] = lessons.splice(fromIndex, 1);
    lessons.splice(toIndex, 0, movedLesson);
    setSections(updatedSections);
  };

  const handleDeleteLesson = (sectionIndex, lessonIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].lessons.splice(lessonIndex, 1);
    setSections(updatedSections);
  };

  const updateLesson = (
    sectionIndex,
    lessonIndex,
    type,
    partIndex = null,
    field = null,
    value = null
  ) => {
    const updatedSections = [...sections];

    if (type === 'sectionTitle') {
      updatedSections[sectionIndex].title = value;
    } else if (type === 'title') {
      updatedSections[sectionIndex].lessons[lessonIndex].title = value;
    } else if (type === 'updatePart') {
      updatedSections[sectionIndex].lessons[lessonIndex].parts[partIndex][
        field
      ] = value;
    } else if (type === 'removePart') {
      updatedSections[sectionIndex].lessons[lessonIndex].parts.splice(
        partIndex,
        1
      );
    } else if (type) {
      updatedSections[sectionIndex].lessons[lessonIndex].parts.push({ type });
    }

    setSections(updatedSections);
  };

  return (
    <main className={`   flex-1`}>
      <EdictCourseLayout id={id}>
        <DndProvider backend={HTML5Backend}>
          <div className=' flex-1 w-full'>
            {sections.map((section, index) => (
              <CoursSection
                key={index}
                section={section}
                sectionIndex={index}
                moveSection={moveSection}
                moveLesson={moveLesson}
                addLesson={addLesson}
                updateLesson={updateLesson}
                deleteSection={deleteSection}
                handleDeleteLesson={handleDeleteLesson}
              />
            ))}
            <div className=' flex justify-between items-center'>
              <BlackButton
                text='+ Section'
                onClick={() => addSection(`Section ${sections.length + 1}`)}
              />

              {isLoading ? (
                <div>
                  <Spinner />
                </div>
              ) : (
                <>
                  {sections.length > 0 && (
                    <button
                      onClick={updateField}
                      className='ml-2 px-4 py-2 bg-gray-200 rounded-lg flex items-center space-x-1'
                    >
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke-width='1.5'
                          stroke='currentColor'
                          class='size-4'
                        >
                          <path
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            d='m4.5 12.75 6 6 9-13.5'
                          />
                        </svg>
                      </span>
                      <span>Enregistrer</span>
                    </button>
                  )}
                </>
              )}
            </div>
            {errorMessage && (
              <div className=' w-full flex p-2 mb-2 items-center bg-red-100 space-x-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='h-5 text-red-600'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z'
                  />
                </svg>

                <span className='   flex   text-red-600'>{errorMessage}</span>
              </div>
            )}
          </div>
        </DndProvider>
      </EdictCourseLayout>
    </main>
  );
};

export default CurriculumBuilder;

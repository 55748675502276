import { STUDENT_ASSEMENT_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const assementCouresSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addStudentAssessment: builder.mutation({
      query: (data) => ({
        url: STUDENT_ASSEMENT_URL + '/' + data.id,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    updateStudentTimeLimit: builder.mutation({
      query: (data) => ({
        url: STUDENT_ASSEMENT_URL + '/' + data.id,
        method: 'PUT',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getStudentAssessment: builder.query({
      query: ({ token, id }) => ({
        url: STUDENT_ASSEMENT_URL + '/' + id,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getNewStudentsAssessments: builder.query({
      query: (token) => ({
        url: STUDENT_ASSEMENT_URL,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getNewStudentAssessment: builder.query({
      query: ({ token, id }) => ({
        url: STUDENT_ASSEMENT_URL + '/avaluated/' + id,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    updateStudentAssessment: builder.mutation({
      query: (data) => ({
        url: STUDENT_ASSEMENT_URL + '/avaluated/' + data.id,
        method: 'PUT',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getAssessmentNoted: builder.query({
      query: ({ token, id }) => ({
        url: STUDENT_ASSEMENT_URL + '/noted/' + id,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    updateAssessment: builder.mutation({
      query: (data) => ({
        url: STUDENT_ASSEMENT_URL + '/update/' + data.id,
        method: 'PUT',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useUpdateStudentTimeLimitMutation,
  useAddStudentAssessmentMutation,
  useGetStudentAssessmentQuery,
  useUpdateAssessmentMutation,
  useGetNewStudentsAssessmentsQuery,
  useGetNewStudentAssessmentQuery,
  useUpdateStudentAssessmentMutation,
  useGetAssessmentNotedQuery,
} = assementCouresSlice;

import React, { useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import StudentsItems from '../../components/admin/StudentsItems';
import Pagination from '../../components/pagination/Pagination';
import BlackButton from '../../components/utilities/BlackButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from '../../components/spinner/Spinner';
import { useGetUsersQuery } from '../../redux/slices/userApiSlice';
import ErrorMessage from '../../components/message/ErrorMessage';

const Students = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching, isLoading, isSuccess } = useGetUsersQuery({
    token,
    type: 'students',
    currentPage,
    name,
  });

  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const addNewStudents = () => {
    navigate('/admin/new/student');
  };

  return (
    <main className={` flex-1`}>
      <AdminLayout>
        <div className=' w-full'>
          <div className=' space-x-3 flex-row items-end flex w-full pb-4  justify-between'>
            <input
              type='text'
              placeholder='Rechercher un cours'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='p-2 border rounded flex-1'
            />
            <BlackButton text='+' onClick={addNewStudents} />
          </div>
          {isLoading ? (
            <Spinner />
          ) : data ? (
            <div className='grid grid-cols-1  md:grid-cols-2 xl:grid-cols-3 gap-6 '>
              {data.students.map((instructor, index) => (
                <StudentsItems key={index} data={instructor} />
              ))}
            </div>
          ) : (
            <ErrorMessage message='Aucun formateur trouvé' />
          )}
          {data && data.students && data.students.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={data?.pages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </AdminLayout>
    </main>
  );
};

export default Students;

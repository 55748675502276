import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAssementsQuery } from '../../redux/slices/assementApiSlice';
import { formatRelativeTimeManual } from '../../utils/utils';
import { useAddStudentAssessmentMutation } from '../../redux/slices/studentAssementApiSlice';

const EvaluationProgram = ({ user, token, id }) => {
  const navigate = useNavigate();

  const {
    data: assessment,
    isLoading: loading,
    isSuccess: success,
    isError: error,
    refetch,
  } = useGetAssementsQuery({ token, id });

  const [
    addStudentAssessment,
    { isLoading: addSdtAsstLoading, isSuccess: sdtAsstSuccess, data: sdtData },
  ] = useAddStudentAssessmentMutation();

  const [showModal, setShowModal] = useState(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState({});

  const handleStartClick = (evaluationId) => {
    setSelectedEvaluation(evaluationId);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvaluation(null);
  };

  useEffect(() => {
    if (sdtAsstSuccess) {
      setShowModal(false);
      navigate(`/evaluation/${sdtData._id}`);
    }
  }, [sdtAsstSuccess]);

  const handleConfirmStart = async (assId) => {
    try {
      const data = {
        id: selectedEvaluation._id,
        token,
        timeLimit: selectedEvaluation.timeLimit,
      };

      await addStudentAssessment(data).unwrap();
    } catch (error) {}
  };

  return (
    <div className='container py-10 mx-auto px-4'>
      <h2 className='font-semibold text-2xl mb-4'>Programme d'Évaluation</h2>
      <p className='mb-4'>
        Voici la liste des évaluations prévues pour ce cours. Veuillez consulter
        les ressources associées pour vous préparer.
      </p>

      <div className='space-y-6'>
        {assessment?.map((evaluation) => (
          <div
            key={evaluation._id}
            className='border border-gray-300 p-4 rounded-lg shadow-sm'
          >
            <p className='text-sm text-gray-500 mb-2'>
              Date : {formatRelativeTimeManual(evaluation.createdAt)}
            </p>
            <p className='mb-2'>{evaluation.description}</p>

            <div className='mt-2'>
              <p className='font-medium'>Commencez votre évaluation :</p>
              <button
                disabled={evaluation.studentId.includes(user._id)}
                onClick={() => handleStartClick(evaluation)}
                className={`${
                  evaluation.studentId.includes(user._id)
                    ? ' bg-gray-300'
                    : 'bg-black'
                }    text-white px-4 py-2 rounded-md `}
              >
                {evaluation.studentId.includes(user._id)
                  ? 'Dejà evalué'
                  : 'Démarrer'}
              </button>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div
          onClick={closeModal}
          className='fixed z-[9999] inset-0 flex justify-center items-center bg-[#0000002e] bg-opacity-50'
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className='bg-white p-6 z-[999999] rounded-lg shadow-lg max-w-sm w-full'
          >
            <h3 className='text-lg font-semibold mb-4'>
              Êtes-vous sûr de vouloir démarrer cette évaluation ?
            </h3>
            <p className='text-sm text-gray-600 mb-4'>
              Vous serez lancé dans l'évaluation et un compte à rebours
              commencera. Une fois que vous avez démarré, vous ne pourrez plus
              revenir en arrière.
            </p>
            <div className='flex justify-between'>
              <button
                onClick={closeModal}
                className='bg-gray-500 text-white px-4 py-2 rounded-md'
              >
                Annuler
              </button>
              <button
                onClick={handleConfirmStart}
                className='bg-red-500 text-white px-4 py-2 rounded-md'
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluationProgram;

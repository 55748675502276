import React from 'react';

const RoundeButton = ({ onClick = () => {}, text }) => {
  return (
    <button
      onClick={onClick}
      className='bg-[#00075e] transition-all hover:bg-[#161a4e] px-5 py-2  rounded-[50px] text-white'
    >
      {text}
    </button>
  );
};

export default RoundeButton;

import React, { useState, useEffect } from 'react';
import SubjectComponent from '../../components/auth/SubjectComponent';
import TextComponent from '../../components/auth/TextComponent';
import DragAndDropComponent from '../../components/auth/DragAndDropComponent';
import { useGetAssementQuery } from '../../redux/slices/assementApiSlice';
import Loading from '../../components/loading/Loading';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useGetStudentAssessmentQuery,
  useUpdateAssessmentMutation,
  useUpdateStudentTimeLimitMutation,
} from '../../redux/slices/studentAssementApiSlice';
import MultipleChoiceComponent from '../../components/auth/MultipleChoiceComponent ';

const Timer = ({ timeLeft, setTimeLeft, timeLimit, fetchTimeLimit }) => {
  useEffect(() => {
    setTimeLeft(timeLimit * 60);
  }, [timeLimit]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft >= 1) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else {
        setTimeLeft(0);
      }
    }, 1000);

    const syncTime = setInterval(() => {
      fetchTimeLimit(Math.ceil(timeLeft / 60));
    }, 1000);

    return () => {
      clearInterval(timer);
      clearInterval(syncTime);
    };
  }, [timeLeft, fetchTimeLimit]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? `${hours}h ` : ''}${minutes}min ${
      secs < 10 ? '0' : ''
    }${secs}s`;
  };

  return (
    <div className='text-red-500 font-semibold'>
      Temps restant : {formatTime(timeLeft)}
    </div>
  );
};

const Evaluation = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [timeLimit, setTimeLimit] = useState(null);
  const [timeLeft, setTimeLeft] = useState(
    timeLimit === null ? 10 : timeLimit * 60
  );
  const [isTimeOut, setIsTimeOut] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const { id } = useParams();
  const [
    updateStudentTimeLimit,
    { isSuccess: timeSuccess, isError: timeError },
  ] = useUpdateStudentTimeLimitMutation();

  const [
    updateAssessment,
    {
      data: updatedDta,
      isSuccess: updateSuccess,
      isError: updateError,
      isLoading: updateLoading,
    },
  ] = useUpdateAssessmentMutation();

  const { data, isLoading, refetch, isSuccess } = useGetStudentAssessmentQuery({
    token,
    id,
  });

  useEffect(() => {
    if (updateSuccess) {
      setTimeLimit(0);
      return navigate('/evaluation/ended', { replace: true });
    }
  }, [updateSuccess, updatedDta]);

  const evaluation = data ? data.assessment : null;

  useEffect(() => {
    if (data) {
      setTimeLimit(data.timeLimit);
    }
  }, [data]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsTimeOut(true);
    }
  }, [timeLeft]);

  const updateAssessmentTime = async (currentLimit) => {
    try {
      const data = { token, id, timeLimit: currentLimit };
      await updateStudentTimeLimit(data).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (timeLimit !== null) {
      updateAssessmentTime(timeLimit);
    }
  }, [timeLimit]);

  const handleSubmit = async (answer) => {
    if (timeLeft === 0) {
      try {
        const data = {
          ...answer,
          token,
          id,
          timeLimit: timeLeft,
        };
        await updateAssessment(data).unwrap();
        setIsTimeOut(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const submitHandle = async (answer) => {
    try {
      const data = {
        ...answer,
        token,
        id,
        timeLimit: 0,
      };
      await updateAssessment(data).unwrap();
      setIsTimeOut(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!evaluation && !isLoading) {
    return <div>404</div>;
  }

  if (data && data.timeLimit === 0) {
    return <Navigate to='/evaluation/ended/' />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='container mx-auto p-8 my-7 rounded-lg'>
        <h1 className='text-2xl font-bold mb-4'>Évaluation</h1>
        <p className='text-gray-500 mb-4'>Cours : {evaluation.course.title}</p>

        {/* Affichage de la minuterie */}
        {timeLimit && (
          <Timer
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
            timeLimit={timeLimit}
            fetchTimeLimit={(currentLimit) => {
              if (currentLimit !== timeLimit) {
                updateAssessmentTime(currentLimit);
              }
            }}
          />
        )}

        <div>
          {evaluation.type === 'Sujet' && (
            <SubjectComponent
              isTimeOut={isTimeOut}
              type={evaluation.type}
              onSubmit={handleSubmit}
              content={evaluation.content}
              onSubmitHandle={submitHandle}
              loading={updateLoading}
            />
          )}
          {evaluation.type === 'texte' && !submitted && (
            <TextComponent
              isTimeOut={isTimeOut}
              type={evaluation.type}
              content={evaluation.content}
              onSubmit={handleSubmit}
              onSubmitHandle={submitHandle}
              loading={updateLoading}
            />
          )}
          {evaluation.type === 'choix-multiple' && !submitted && (
            <MultipleChoiceComponent
              isTimeOut={isTimeOut}
              type={evaluation.type}
              content={evaluation.content}
              choices={evaluation.choices}
              onSubmit={handleSubmit}
              onSubmitHandle={submitHandle}
              loading={updateLoading}
            />
          )}
          {evaluation.type === 'glisser-deposer' && !submitted && (
            <DragAndDropComponent
              isTimeOut={isTimeOut}
              type={evaluation.type}
              content={evaluation.content}
              choices={evaluation.choices}
              questions={evaluation.question}
              timeLimit={evaluation.timeLimit}
              onSubmit={handleSubmit}
              onSubmitHandle={submitHandle}
              loading={updateLoading}
            />
          )}
        </div>

        {/* Message de fin si le temps est écoulé */}
        {submitted && (
          <p className='text-green-500 font-semibold mt-4'>
            Votre réponse a été soumise automatiquement.
          </p>
        )}
      </div>
    </DndProvider>
  );
};

export default Evaluation;

import { useEffect, useState } from 'react';
import AuthInput from '../../components/auth/AuthInput';
import ErrorMessage from '../../components/message/ErrorMessage';
import Spinner from '../../components/spinner/Spinner';
import { useForgotPasswordMutation } from '../../redux/slices/userApiSlice';
import FullButton from '../../components/utilities/FullButton';
import SuccessMessage from '../../components/message/SuccessMessage';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [forgotPassword, { isLoading, isError, isSuccess }] =
    useForgotPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      setSuccessMessage(
        'Un email de réinitialisation a été envoyé. Veuillez vérifier votre boîte de réception.'
      );
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    }
  }, [isSuccess]);

  const sendEmailHandelr = async (e) => {
    e.preventDefault();

    try {
      await forgotPassword({ email }).unwrap();
    } catch (error) {
      setErrorMessage(error?.data?.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };
  return (
    <div className='min-h-[85vh] flex items-center justify-center bg-gray-100'>
      <div className='w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow-md'>
        <div className=' '>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {successMessage && <SuccessMessage message={successMessage} />}
          <h2 className='text-center text-3xl font-extrabold text-gray-900'>
            Envoyer un email de réinitialisation
          </h2>
        </div>

        <form
          className='mt-8 flex flex-col  space-y-2'
          onSubmit={sendEmailHandelr}
        >
          <AuthInput
            setValue={setEmail}
            value={email}
            type='email'
            label='Adresse email'
            placeholder='Adresse email'
          />

          {isLoading ? (
            <Spinner />
          ) : (
            <FullButton text={`Envoyer `} type='submit' />
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React from 'react';
import Title from '../utilities/Title';

const Language = ({ data }) => {
  return (
    <div className=' py-4'>
      <Title text='Langue du cours' />
      <h3 className=' font-normal'>{data?.language}</h3>
    </div>
  );
};

export default Language;

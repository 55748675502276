import { Link, useLocation } from 'react-router-dom';

const AdminItem = ({ url, texte, icon }) => {
  const location = useLocation();

  return (
    <li className='w-full'>
      <Link
        to={`${url}`}
        className={`${
          location.pathname === url ? 'bg-gray-100' : ''
        } transition-all px-3 py-[0.3rem] w-full hover:bg-gray-100 cursor-pointer flex items-center`}
      >
        <span className='mr-2'>{icon}</span>
        {texte}
      </Link>
    </li>
  );
};

export default AdminItem;

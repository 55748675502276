import React, { useState } from 'react';

const ChapterList = ({ setLessonsIndex, lessonsIndex, sect, index }) => {
  return (
    <li className='flex mt-2 mb-1 space-y-1 flex-col  '>
      <div
        onClick={() => {
          if (lessonsIndex.includes(index)) {
            setLessonsIndex(lessonsIndex.filter((i) => i !== index));
          } else {
            setLessonsIndex([...lessonsIndex, index]);
          }
        }}
        className=' cursor-pointer flex space-x-4'
      >
        <h4 className=' font-medium text-[1rem]'>
          {index + 1}. {sect.title}
        </h4>

        {lessonsIndex.includes(index) ? (
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='h-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m19.5 8.25-7.5 7.5-7.5-7.5'
              />
            </svg>
          </span>
        ) : (
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='h-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m8.25 4.5 7.5 7.5-7.5 7.5'
              />
            </svg>
          </span>
        )}
      </div>

      {lessonsIndex.includes(index) &&
        sect &&
        sect.lessons &&
        sect.lessons.length > 0 &&
        sect.lessons.map((l) => (
          <div
            className='flex space-x-1 items-start py-1 rounded-lg'
            key={l.id}
          >
            <span className='text-gray-500 mt-[0.1rem]'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className='h-4 text-[#08236d]'
              >
                <path
                  fillRule='evenodd'
                  d='M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z'
                  clipRule='evenodd'
                />
              </svg>
            </span>
            <div className='flex space-x-2'>
              <p className='text-[0.9rem]'>
                {l.title}
                <small className='text-gray-400'>2 min 10 sec</small>
              </p>
            </div>
          </div>
        ))}
    </li>
  );
};

export default ChapterList;

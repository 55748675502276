import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setSideBar } from '../../redux/slices/stateSlice';
import {
  FaBook,
  FaCertificate,
  FaChalkboardTeacher,
  FaClipboardCheck,
  FaFileAlt,
  FaHome,
  FaTachometerAlt,
  FaUserGraduate,
} from 'react-icons/fa';

const Layout = ({ children }) => {
  const { sideBarOpen } = useSelector((state) => state.state);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const isAdmin = user?.isAdmin;
  const location = useLocation();

  const menuData = [
    {
      url: '/',
      text: 'Accueil',
      icon: <FaHome className='text-2xl' />,
    },
    {
      url: '/admin/my-courses',
      text: 'Cours',
      icon: <FaBook className='text-2xl' />,
    },
    {
      url: '/student/exams/',
      text: 'Examens',
      icon: <FaFileAlt className='text-2xl' />,
    },
    {
      url: '/student/certificat/',
      text: 'Certificats',
      icon: <FaCertificate className='text-2xl' />,
    },
  ];

  const conentItems = [
    {
      url: '/admin/dasboard',
      texte: 'Tableau de bord',
      icon: <FaTachometerAlt />,
    },
    {
      url: '/admin/formateurs',
      texte: 'Formateurs',
      icon: <FaChalkboardTeacher />,
    },
    {
      url: '/admin/etudiants',
      texte: 'Étudiant(e)s',
      icon: <FaUserGraduate />,
    },

    {
      url: '/admin/evalution',
      texte: 'Évaluations',
      icon: <FaClipboardCheck />,
    },
    { url: '/admin/courses', texte: 'Cours', icon: <FaBook /> },
  ];
  return (
    <aside className='  flex '>
      <nav
        style={{
          height: 'calc(100vh - 70px)',
          width: '16%',
          position: 'fixed',
          background: '#fff',
        }}
        className='shadow-md'
      >
        <ul className='   h-full w-[100%] flex flex-col space-y-2'>
          {!isAdmin || !user || (user && !isAdmin)
            ? menuData.map((menu, index) => (
                <li key={menu.text + index} className=''>
                  <Link
                    to={menu.url}
                    className={`${
                      menu.url === location.pathname ? 'bg-[#ebebeb]' : ''
                    }  transition-all duration-500 hover:bg-[#f5f5f5] space-x-2 py-2 items-center flex relative px-6`}
                  >
                    <span>{menu.icon}</span>
                    <span className=' text-[1.2rem]'>{menu.text}</span>
                  </Link>
                </li>
              ))
            : conentItems.map((menu, index) => (
                <li key={menu.texte + index} className=''>
                  <Link
                    to={menu.url}
                    className={`${
                      menu.url === location.pathname ? 'bg-[#ebebeb]' : ''
                    }  transition-all duration-500 hover:bg-[#f5f5f5] space-x-2 py-2 items-center flex relative px-6`}
                  >
                    <span>{menu.icon}</span>
                    <span className=' text-[1.2rem]'>{menu.texte}</span>
                  </Link>
                </li>
              ))}
        </ul>
      </nav>
      {sideBarOpen ? (
        <div className='ml-[16%] flex-1'>{children}</div>
      ) : (
        <>{children}</>
      )}
    </aside>
  );
};

export default Layout;

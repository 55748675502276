// import Routers from './router/Routers';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Routers from './router/Routers';

function App() {
  return (
    <PayPalScriptProvider deferLoading={true}>
      <Routers />
    </PayPalScriptProvider>
  );
}

export default App;

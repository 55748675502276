import { USER_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (data) => ({
        url: USER_URL + '/signup',
        method: 'POST',
        body: data,
      }),
    }),

    signIn: builder.mutation({
      query: (data) => ({
        url: USER_URL + '/login',
        method: 'POST',
        body: data,
      }),
    }),

    logOut: builder.mutation({
      query: (data) => ({
        url: USER_URL + '/logout',
        method: 'POST',
        body: data,
      }),
    }),

    getProfil: builder.query({
      query: (token) => ({
        url: `${USER_URL}/profile`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getUsers: builder.query({
      query: ({ token, type, currentPage, name }) => ({
        url: `${USER_URL}/${type}`,
        method: 'GET',
        params: { currentPage, name },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getUser: builder.query({
      query: ({ token, id }) => ({
        url: `${USER_URL}/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    addUsers: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/${data.type}`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    updateProfil: builder.mutation({
      query: (data) => ({
        url: USER_URL + '/profile',
        method: 'PUT',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: USER_URL + '/password/send-token',
        method: 'POST',
        body: data,
      }),
    }),

    validateResetToken: builder.query({
      query: (token) => ({
        url: USER_URL + '/password/reset/' + token,
        method: 'GET',
      }),
    }),

    resetPassword: builder.mutation({
      query: (data) => ({
        url: USER_URL + '/password/reset/' + data.token,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useLogOutMutation,
  useGetProfilQuery,
  useUpdateProfilMutation,
  useGetUsersQuery,
  useAddUsersMutation,
  useGetUserQuery,
  useForgotPasswordMutation,
  useValidateResetTokenQuery,
  useResetPasswordMutation,
} = userApiSlice;

export const cours = [
  {
    titre: 'Windows Server 2022',
    description: 'Active Directory et les stratégies de groupe',
    temps: '2 h 33 min',
    auteur: 'Nicolas Bonnet',
    populaire: true,
    image:
      'https://academieceisna.org/wp-content/uploads/2023/02/Affiche-certificat-6mois-675x450.png',
  },
  {
    titre: "L'essentiel de Windows 11",
    description: '',
    temps: '1 h 28 min',
    auteur: 'Nicolas Bonnet',
    populaire: false,
    image:
      'https://academieceisna.org/wp-content/uploads/2022/09/Digitalisation-des-pharmacies-officine-675x450.png',
  },
  {
    titre: "L'essentiel de VMware vSphere",
    description: '',
    temps: '4 h 41 min',
    auteur: 'Pierre Cabantous',
    populaire: false,
    image:
      'https://academieceisna.org/wp-content/uploads/2024/07/Modele-Economique-675x450.png',
  },
  {
    titre: 'La sécurité des applications web',
    description: '',
    temps: '2 h 29 min',
    auteur: 'Pierre Cabantous',
    populaire: false,
    image:
      'https://academieceisna.org/wp-content/uploads/2022/11/course-13-675x450.jpg',
  },
  {
    titre: 'Microsoft Sentinel',
    description: 'et gestion du SIEM',
    temps: '1 h 26 min',
    auteur: 'A. B.',
    populaire: false,
    image:
      'https://academieceisna.org/wp-content/uploads/2022/11/course-17-675x450.jpg',
  },

  {
    titre: 'Les fondements des réseaux',
    description: 'Les protocoles, les outils et la CLI',
    temps: '2 h 18 min',
    auteur: 'J. Doe',
    misAJour: true,
    image:
      'https://academieceisna.org/wp-content/uploads/2022/10/Business-model.png',
  },
  {
    titre: 'Examen blanc 2 pour Microsoft Azure Fundamentals (AZ-900)',
    temps: '4 min 19 sec',
    auteur: '',
    misAJour: false,
    nouveau: true,
    image:
      'https://academieceisna.org/wp-content/uploads/2023/02/Master-sante-numerique-et-IA-675x450.png',
  },
  {
    titre: 'Python : Le nettoyage des données',
    temps: '1 h 26 min',
    auteur: '',
    misAJour: false,
    nouveau: true,
    image:
      'https://academieceisna.org/wp-content/uploads/2023/12/Introduction-learnpress-lms-plugin-4-675x450.png',
  },
];

export const categories = [
  {
    id: 101,
    name: 'Télémédecine',
  },
  {
    id: 102,
    name: 'Dossiers Médicaux Électroniques',
  },
  {
    id: 103,
    name: 'Intelligence Artificielle en Santé',
  },
  {
    id: 104,
    name: 'Cybersécurité en Santé',
  },
  {
    id: 105,
    name: 'Santé Connectée',
  },

  {
    id: 201,
    name: 'Pathologies Communes',
  },
  {
    id: 202,
    name: 'Médecine Préventive',
  },
  {
    id: 203,
    name: 'Premiers Secours',
  },

  {
    id: 301,
    name: 'Cardiologie',
  },
  {
    id: 302,
    name: 'Neurologie',
  },
  {
    id: 303,
    name: 'Pédiatrie',
  },
  {
    id: 304,
    name: 'Oncologie',
  },
  {
    id: 305,
    name: 'Dermatologie',
  },

  {
    id: 401,
    name: 'Soins Généraux',
  },
  {
    id: 402,
    name: "Soins d'Urgence",
  },
  {
    id: 403,
    name: 'Prise en Charge des Maladies Chroniques',
  },

  {
    id: 501,
    name: 'Psychologie',
  },
  {
    id: 502,
    name: 'Psychiatrie',
  },
  {
    id: 503,
    name: 'Addictologie',
  },
  {
    id: 504,
    name: 'Gestion du Stress',
  },

  {
    id: 601,
    name: 'Épidémiologie',
  },
  {
    id: 602,
    name: 'Politiques de Santé',
  },
  {
    id: 603,
    name: 'Gestion des Pandémies',
  },
  {
    id: 604,
    name: 'Vaccination',
  },

  {
    id: 701,
    name: 'Introduction à la Santé Numérique',
  },
  {
    id: 702,
    name: 'Éthique et Régulation',
  },
  {
    id: 703,
    name: 'Nouvelles Technologies en Santé',
  },
];

export const langue = [
  {
    id: 1,
    code: 'en',
    name: 'Anglais',
  },
  {
    id: 2,
    code: 'fr',
    name: 'Français',
  },
  { id: 19, code: 'sw', name: 'Swahili' },
  { id: 3, code: 'yo', name: 'Yoruba' },
  { id: 4, code: 'ig', name: 'Igbo' },
  { id: 5, code: 'ha', name: 'Hausa' },
  { id: 6, code: 'ar', name: 'Arabe' },
  { id: 7, code: 'am', name: 'Amharique' },
  { id: 8, code: 'wo', name: 'Wolof' },
  { id: 8, code: 'zu', name: 'Zulu' },
  { id: 9, code: 'xh', name: 'Xhosa' },
  { id: 10, code: 'bm', name: 'Bambara' },
  { id: 11, code: 'ts', name: 'Tsonga' },
  { id: 12, code: 'so', name: 'Somali' },
  { id: 13, code: 'rw', name: 'Kinyarwanda' },
  { id: 14, code: 'ny', name: 'Chichewa' },
  { id: 15, code: 'sn', name: 'Shona' },
  { id: 16, code: 'tn', name: 'Tswana' },
  { id: 17, code: 'lg', name: 'Ganda' },
  { id: 18, code: 'ak', name: 'Akan' },
];

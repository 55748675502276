import { CERTIFICAT_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const certicatApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addOrRemoveCertificat: builder.mutation({
      query: (data) => ({
        url: CERTIFICAT_URL,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    getCertificats: builder.query({
      query: (token) => ({
        url: CERTIFICAT_URL,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getStudentCertificats: builder.query({
      query: ({ token, studentId }) => ({
        url: CERTIFICAT_URL + '/student/' + studentId,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useAddOrRemoveCertificatMutation,
  useGetStudentCertificatsQuery,
  useGetCertificatsQuery,
} = certicatApiSlice;

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className=' pt-5 border-t-2  border-gray-300'>
      <ul className='  px-2 mb-6 space-y-2 md:space-y-0 flex-col flex md:flex-row md:items-center space-x-0 md:space-x-6'>
        <li>
          <a
            href='https://ceisna.org/apropos-de-nous'
            target='_blank'
            rel='noopener noreferrer'
            className='font-medium hover:underline hover:decoration-blue-900'
          >
            A propos de nous
          </a>
        </li>
        <li>
          <a
            href='https://ceisna.org/contactez-nous'
            target='_blank'
            rel='noopener noreferrer'
            className='font-medium hover:underline hover:decoration-blue-900'
          >
            Contactez-nous
          </a>
        </li>

        <li className=' font-medium hover:underline  hover:decoration-blue-900'>
          <Link to='/devenir-tuteur'>Devenir instructeur</Link>
        </li>
        <li>
          <a
            href='https://ceisna.org/politique-de-confidentialite'
            target='_blank'
            rel='noopener noreferrer'
            className='font-medium hover:underline hover:decoration-blue-900'
          >
            Conditions générales et confidentialité
          </a>
        </li>
      </ul>
      <div className='bg-[#00075e] text-white py-6 mt-auto'>
        <div className='container mx-auto px-6 text-center'>
          <p>&copy; 2024 Académie Ceisna. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

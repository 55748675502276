import AdminLayout from '../../components/admin/AdminLayout';
import {
  FaUserGraduate,
  FaChalkboardTeacher,
  FaBookOpen,
  FaBook,
} from 'react-icons/fa';

import { useGetCoursesStatQuery } from '../../redux/slices/courseApiSlice';

function Dashboard() {
  const { data, isLoading, isSuccess } = useGetCoursesStatQuery();

  return (
    <main className={` flex-1`}>
      <AdminLayout>
        <div className=' mt-4 md:mt-0 w-full'>
          <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6'>
            <div className='bg-blue-500 text-white p-6 rounded-lg shadow-md flex flex-col items-center transform transition-transform duration-300 hover:scale-105'>
              <FaUserGraduate className='text-4xl animate-bounce' />
              <h3 className='text-xl font-semibold mt-4'>Étudiants</h3>
              <p className='text-2xl mt-2'>{data?.studentCount}</p>
            </div>
            <div className='bg-green-500 text-white p-6 rounded-lg shadow-md flex flex-col items-center transform transition-transform duration-300 hover:scale-105'>
              <FaBookOpen className='text-4xl animate-spin' />
              <h3 className='text-xl font-semibold mt-4'>Cours publiés</h3>
              <p className='text-2xl mt-2'>{data?.publishedCourses}</p>
            </div>
            <div className='bg-yellow-500 text-white p-6 rounded-lg shadow-md flex flex-col items-center transform transition-transform duration-300 hover:scale-105'>
              <FaBook className='text-4xl animate-pulse' />
              <h3 className='text-xl font-semibold mt-4'>Cours en cours</h3>
              <p className='text-2xl mt-2'>{data?.unpublishedCourses}</p>
            </div>
            <div className='bg-red-500 text-white p-6 rounded-lg shadow-md flex flex-col items-center transform transition-transform duration-300 hover:scale-105'>
              <FaChalkboardTeacher className='text-4xl animate-bounce' />
              <h3 className='text-xl font-semibold mt-4'>Formateurs</h3>
              <p className='text-2xl mt-2'>{data?.teacherCount}</p>
            </div>
          </div>
        </div>
      </AdminLayout>
    </main>
  );
}

export default Dashboard;

import UserLayout from '../../components/admin/UserLayout';
import { useState } from 'react';
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
import { useStudentsCoursesQuery } from '../../redux/slices/resgisterCouresSlice';
import ErrorMessage from '../../components/message/ErrorMessage';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StudentCoursItem from '../../components/admin/StudentCoursItem';

const StudentCours = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isSuccess } = useStudentsCoursesQuery({
    currentPage,
    title,
    id,
    token,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const backUrl = '/admin/etudiants';
  return (
    <UserLayout backUrl={backUrl}>
      <div className=' mt-3 md:mt-0 mb-3 w-full'>
        {data && data.courses && data.courses.length > 0 && (
          <div className=' mb-3 flex items-center w-full'>
            <input
              type='text'
              placeholder='Rechercher un cours'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className='p-2 border rounded flex-1'
            />
          </div>
        )}

        {isLoading ? (
          <Spinner />
        ) : data && data.courses && data.courses.length > 0 ? (
          <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
            {data.courses.map((item) => (
              <StudentCoursItem item={item} key={item.id} />
            ))}
          </div>
        ) : (
          <div className='w-full'>
            <ErrorMessage message='Aucun Cours trouvé' />
          </div>
        )}

        {data && data.courses && data.courses.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={data?.pages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </UserLayout>
  );
};

export default StudentCours;

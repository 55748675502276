import { useEffect, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGetPaypalClientIdQuery } from '../../redux/slices/orderSlice';
import Spinner from '../../components/spinner/Spinner';
import { useGetReviewsQuery } from '../../redux/slices/reviewApiSlice';
import { formatMinutesToHourMinute } from '../../utils/utils';
import { setSideBar } from '../../redux/slices/stateSlice';
import { useAddResgisterMutation } from '../../redux/slices/resgisterCouresSlice';

const Payment = () => {
  const { user } = useSelector((state) => state.auth);
  const { course } = useSelector((state) => state.state);
  const token = user?.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSideBar(false));
  }, []);

  const [clientId, setClientId] = useState('');
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();
  const match = useParams();
  const orderId = match.id;

  const [
    addResgister,
    {
      error: addResgisterError,
      isLoading: addResgisterLoading,
      isSuccess,
      data,
    },
  ] = useAddResgisterMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/course/learning/${data.courseId}`);
    }
  }, [isSuccess]);

  const {
    data: reviews,
    isLoading: reviewsLoading,
    isSuccess: reviewSuccess,
    isError: errorLoading,
  } = useGetReviewsQuery(orderId);

  const {
    data: paypal,
    isLoading: payPalLoading,
    error: ErrorPayPal,
  } = useGetPaypalClientIdQuery();

  useEffect(() => {
    if (!ErrorPayPal && !payPalLoading && paypal.clientId) {
      const loadPayPalScript = () => {
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': paypal.clientId,
            currency: 'EUR',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      if (course) {
        if (!window.paypal) {
          loadPayPalScript();
        }
      }
    }
  }, [course, paypal, paypalDispatch, payPalLoading, ErrorPayPal]);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: Number(course.price),
            },
          },
        ],
      })
      .then((orderId) => {
        return orderId;
      });
  };

  const onError = (error) => {
    console.log(error);
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (details) {
      try {
        await addResgister({ ...course, token: token });
      } catch (error) {
        console.log(error);
      }
    });
  };

  if (!user) {
    return <Navigate to='/auth' />;
  }

  return (
    <div className='container min-h-[80vh] mx-auto p-6'>
      <h1 className='text-3xl font-bold mb-6'>Paiement</h1>
      <div className='flex flex-col md:flex-row gap-6'>
        <div className='flex-1'>
          <div className='flex items-center border-b border-gray-200 py-4'>
            <img
              src={course?.imageUrl}
              alt={course?.title}
              className='w-40 h-20 rounded-lg object-cover mr-4'
            />
            <div className='flex-1'>
              <h2 className='text-lg font-semibold'>{course?.title}</h2>
              <p className='text-sm text-gray-600'>
                Par {course?.user?.name} - {reviews?.reviews[0]?.rating || 0} ⭐
                ({reviews?.reviews[0]?.reviews?.length || 0} avis)
              </p>
              <p className='text-sm text-gray-500'>
                {formatMinutesToHourMinute(course.time)} | {course?.level}
              </p>
            </div>
            <div className='flex flex-col items-end'>
              <p className='text-lg font-bold text-purple-600'>
                ${course?.price}
              </p>
            </div>
          </div>
        </div>

        <div className='w-full md:w-1/3 bg-gray-100 rounded-lg p-6'>
          <h2 className='text-xl font-bold mb-4'>Total : {course.price}€</h2>
          {addResgisterLoading || payPalLoading ? (
            <Spinner />
          ) : (
            <>
              {isPending ? (
                <Spinner />
              ) : (
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;

import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/banner/Banner';
import Courses from '../../components/courses/Courses';
import Layout from '../../components/layout/Layout';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { setSideBar } from '../../redux/slices/stateSlice';
import { useGetCoursesQuery } from '../../redux/slices/courseApiSlice';

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { sideBarOpen } = useSelector((state) => state.state);

  const { data, isLoading, isSuccess } = useGetCoursesQuery({
    currentPage: '',
    title: '',
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 1024) {
        dispatch(setSideBar(false));
      } else {
        dispatch(setSideBar(true));
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const recentCourses = useMemo(() => {
    return isSuccess && data?.courses
      ? [...data.courses].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      : [];
  }, [isSuccess, data]);

  const courseLevels = [
    { title: 'Débutant', level: 'Débutant' },
    { title: 'Cours Niveau 1', level: 'Niveau 1' },
    { title: 'Cours Niveau 2', level: 'Niveau 2' },
  ];

  const filteredCourses = useMemo(() => {
    return isSuccess && data?.courses
      ? courseLevels.map(({ title, level }) => ({
          title,
          courses: data.courses.filter((course) => course.level === level),
        }))
      : [];
  }, [isSuccess, data, courseLevels]);

  return (
    <main className='flex-1'>
      <Banner />
      <Courses title='Nouveaux cours' courses={recentCourses} />
      {filteredCourses.map(({ title, courses }) => (
        <Courses key={title} title={title} courses={courses} />
      ))}
    </main>
  );
};

export default Home;

import { RESGISTERS_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const resgisterCouresSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addResgister: builder.mutation({
      query: (resgister) => ({
        url: RESGISTERS_URL,
        method: 'POST',
        body: resgister,
        headers: {
          Authorization: `Bearer ${resgister.token}`,
        },
      }),
    }),

    getResgister: builder.query({
      query: (id) => ({
        url: RESGISTERS_URL + '/' + id,
        method: 'GET',
      }),
    }),

    getResgisters: builder.query({
      query: ({ currentPage = '', title = '', token }) => ({
        url: RESGISTERS_URL,
        method: 'GET',
        params: { currentPage, title },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    studentsCourses: builder.query({
      query: ({ currentPage = '', title = '', id, token }) => ({
        url: RESGISTERS_URL + '/' + id + '/student',
        method: 'GET',
        params: { currentPage, title },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    deleteResgisters: builder.mutation({
      query: ({ token, id }) => ({
        url: RESGISTERS_URL + '/' + id,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    updatedLesson: builder.mutation({
      query: (data) => ({
        url: RESGISTERS_URL + '/' + data.id + '/lesson',
        body: data,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),

    learning: builder.query({
      query: ({ token, id }) => ({
        url: RESGISTERS_URL + '/' + id + '/learning',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    getStudentCourse: builder.query({
      query: ({ token, id }) => ({
        url: RESGISTERS_URL + '/details/' + id + '/student',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useAddResgisterMutation,
  useGetResgisterQuery,
  useStudentsCoursesQuery,
  useGetResgistersQuery,
  useUpdatedLessonMutation,
  useDeleteResgistersMutation,
  useLearningQuery,
  useGetStudentCourseQuery,
} = resgisterCouresSlice;

import { Navigate, useLocation } from 'react-router-dom';
import AdminItem from './AdminItem';
import {
  FaTachometerAlt,
  FaChalkboardTeacher,
  FaUserGraduate,
  FaBook,
  FaUser,
  FaSignOutAlt,
  FaClipboardCheck,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSideBar } from '../../redux/slices/stateSlice';
import { useEffect } from 'react';

const Title = ({ texte }) => {
  return <h2 className='text-lg font-bold  mb-4'>{texte}</h2>;
};

const AdminLayout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(setSideBar(false));
  }, [location.pathname]);

  const conentItems = [
    {
      url: '/admin/dasboard',
      texte: 'Tableau de bord',
      icon: <FaTachometerAlt />,
    },
    {
      url: '/admin/formateurs',
      texte: 'Formateurs',
      icon: <FaChalkboardTeacher />,
    },
    {
      url: '/admin/etudiants',
      texte: 'Étudiant(e)s',
      icon: <FaUserGraduate />,
    },

    {
      url: '/admin/evalution',
      texte: 'Évaluations',
      icon: <FaClipboardCheck />,
    },
    { url: '/admin/courses', texte: 'Cours', icon: <FaBook /> },
  ];

  const itemsPrice = [
    { url: '/admin/profile', texte: 'Profil', icon: <FaUser /> },
  ];

  if (!user || !user.isAdmin) {
    return <Navigate to='/auth' />;
  }

  return (
    <div className='flex flex-col  md:flex-row mt-5 md:mt-[4.5rem] md:space-x-10 pb-6 w-[90%] mx-auto  md:container m-auto min-h-[78vh] '>
      <div>
        <div className=' w-full md:w-64 bg-[#ffffff]   p-4'>
          <Title texte={'Administration Centrale'} />
          {user && user.isAdmin && (
            <ul className=' w-full'>
              {conentItems.map((item, index) => (
                <AdminItem key={index} {...item} />
              ))}
            </ul>
          )}

          <Title texte='Compte' />
          <ul>
            {itemsPrice.map((item, index) => (
              <AdminItem key={index} {...item} />
            ))}
            <li className='w-full'>
              <button
                className={`transition-all px-3 py-[0.3rem] w-full hover:bg-gray-100 cursor-pointer flex items-center`}
              >
                <span className='mr-2'>
                  <FaSignOutAlt />
                </span>
                Deconnexion
              </button>
            </li>
          </ul>
        </div>
      </div>

      {children}
    </div>
  );
};

export default AdminLayout;

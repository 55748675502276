import React, { useEffect, useState } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { useAddCourseMutation } from '../../redux/slices/courseApiSlice';
import Spinner from '../../components/spinner/Spinner';
import { useSelector } from 'react-redux';

function CourseTitleStep() {
  const { user } = useSelector((state) => state.auth);
  const token = user.token;

  const [title, setTitle] = useState('');
  const navigate = useNavigate();
  const maxCharacters = 60;
  const [addCourse, { isSuccess, isLoading, data }] = useAddCourseMutation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isSuccess && !isLoading && data?._id) {
      navigate(`/admin/create-cours/categorie/${data._id}`);
    }
  }, [isSuccess, data]);

  const addCourseHandler = async () => {
    try {
      await addCourse({ token, title }).unwrap();
    } catch (err) {
      console.error("Erreur lors de l'ajout du cours", err);
      setErrorMessage(err.data.message);
    }
  };

  return (
    <main className={` flex-1`}>
      <AdminLayout>
        <div className='w-full'>
          <div className='flex flex-col items-center justify-center min-h-[60vh] bg-gray-50'>
            <div className='w-full bg-white py-4 px-8 flex justify-between items-center'>
              <div className='text-xl font-semibold text-[#00075e]'>
                Gestion des Cours
              </div>
              <button
                onClick={() => navigate('/admin/courses')}
                className='text-[#00075e] hover:underline'
              >
                Quitter
              </button>
            </div>

            <div className='w-full bg-gray-300 h-1 mt-4'>
              <div className='bg-[#3f55e7] h-1' style={{ width: '50%' }}></div>
            </div>

            {errorMessage && (
              <span className='  flex p-2 bg-red-100 text-red-600'>
                {errorMessage}
              </span>
            )}

            <div className='flex-1 flex flex-col items-center justify-center px-4 text-center'>
              <h1 className='text-2xl font-bold mb-2'>
                Quel est le titre de votre cours?
              </h1>
              <p className='text-gray-500 mb-8'>
                Ce n'est pas grave si vous ne trouvez pas un bon titre
                maintenant. Vous pouvez le changer plus tard.
              </p>

              <div className='w-full max-w-md'>
                <input
                  type='text'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder='par ex. Apprendre Photoshop CS6 de A à Z'
                  maxLength={maxCharacters}
                  className='w-full p-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#394dd0]'
                />
                <div className='text-right text-gray-400 mt-1'>
                  {maxCharacters - title.length} caractères restants
                </div>
              </div>
            </div>

            <div className='w-full flex justify-between items-center p-4 bg-white shadow-inner'>
              <button
                onClick={() => navigate('/admin/courses')}
                className='text-gray-600 hover:underline'
              >
                Précédent
              </button>

              {isLoading ? (
                <button>
                  <Spinner />
                </button>
              ) : (
                <button
                  onClick={addCourseHandler}
                  className={`px-6 py-2 text-white rounded-lg ${
                    title
                      ? 'bg-[#00075e] hover:bg-[#111438]'
                      : 'bg-gray-300 cursor-not-allowed'
                  }`}
                  disabled={!title}
                >
                  Continuer
                </button>
              )}
            </div>
          </div>
        </div>
      </AdminLayout>
    </main>
  );
}

export default CourseTitleStep;

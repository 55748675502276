import React, { useState } from 'react';
import ChapterList from './ChapterList';

const ProgressionCourse = ({ data }) => {
  const [lessonsIndex, setLessonsIndex] = useState([]);

  return (
    <div className='h-[40vh] rounded-md p-6  border-[1px] border-gray-300 overflow-hidden'>
      <div className='overflow-y-scroll h-full'>
        <ul className='space-y-4'>
          {data &&
            data.sections.length > 0 &&
            data.sections.map((sect, index) => (
              <ChapterList
                lessonsIndex={lessonsIndex}
                setLessonsIndex={setLessonsIndex}
                key={index}
                index={index}
                sect={sect}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ProgressionCourse;

import { useState } from 'react';
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
import { useGetResgistersQuery } from '../../redux/slices/resgisterCouresSlice';
import ErrorMessage from '../../components/message/ErrorMessage';
import { useSelector } from 'react-redux';
import AuthLayout from '../../components/auth/AuthLayout';
import CourseLearningItem from '../../components/auth/CourseLearningItem';

const StudentCours = () => {
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const [title, setTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isSuccess } = useGetResgistersQuery({
    currentPage,
    title,
    token,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <AuthLayout>
      <div className=' mt-4 md:mt-0 mb-3 w-full'>
        {data && data.courses && data.courses.length > 0 && (
          <div className=' mb-3 flex items-center w-full'>
            <input
              type='text'
              placeholder='Rechercher un cours'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className='p-2 border rounded flex-1'
            />
          </div>
        )}

        {isLoading ? (
          <Spinner />
        ) : data && data.courses && data.courses.length > 0 ? (
          <div className='w-full grid-flow-row grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4'>
            {data.courses.map((item) => (
              <CourseLearningItem item={item} key={item._id} />
            ))}
          </div>
        ) : (
          <div className='w-full'>
            <ErrorMessage message='Aucun Cours trouvé' />
          </div>
        )}

        {data && data.courses && data.courses.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={data?.pages}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default StudentCours;

import { useNavigate } from 'react-router-dom';

const TabItem = ({ tabIndex, id, text, index }) => {
  const navigate = useNavigate();

  const TabHandler = () => {
    navigate(`/course/learning/${id}?tabIndex=${index}`);
  };

  return (
    <li>
      <button
        onClick={TabHandler}
        className={`${
          tabIndex === index ? 'border-b-[2px] border-[#000000]' : ''
        } py-4 text-[1.1rem] font-medium`}
      >
        {text}
      </button>
    </li>
  );
};

export default TabItem;

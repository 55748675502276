import { useParams } from 'react-router-dom';
import UserLayout from '../../components/admin/UserLayout';
import CertificatCard from '../../components/certificat/CertificatCard';
import { useGetStudentCertificatsQuery } from '../../redux/slices/certicatApiSlice';
import { useSelector } from 'react-redux';

const StudentCertificates = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;

  const {
    data: certificats = [],
    isSuccess: certificatsSuccess,
    isLoading: certificatsLoading,
    refetch: certificatsRefetch,
  } = useGetStudentCertificatsQuery({ token, studentId: id });

  const backUrl = '/admin/etudiants';
  return (
    <UserLayout backUrl={backUrl}>
      <div className=' mt-3 md:mt-0 mb-8 w-full'>
        <h2 className='text-3xl font-bold mb-6 text-gray-800'>
          Certificats Obtenus
        </h2>
        {certificats.length === 0 ? (
          <p className='text-start text-gray-500'>
            Aucun certificat obtenu pour le moment.
          </p>
        ) : (
          <div className='grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {certificats.map((certificat) => (
              <CertificatCard
                isAdim={true}
                isAdminEdition={false}
                key={certificat._id}
                certificat={certificat}
              />
            ))}
          </div>
        )}
      </div>
    </UserLayout>
  );
};

export default StudentCertificates;

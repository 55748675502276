import { useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Spinner from '../spinner/Spinner';

const DraggableChoice = ({ choice, index }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'choice',
    item: { choice, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`p-2 bg-blue-200 rounded-md cursor-pointer mb-2 ${
        isDragging ? 'opacity-50' : ''
      }`}
    >
      {choice}
    </div>
  );
};

const DropZone = ({ answer, onDrop, matchedChoice }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'choice',
    drop: (item) => onDrop(item, answer),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={`p-4 border-2 border-dashed rounded-md mb-4 ${
        isOver ? 'border-blue-500' : 'border-gray-300'
      }`}
    >
      <p className='text-gray-700'>{answer}</p>
      {matchedChoice && (
        <div className='mt-2 p-2 bg-green-200 rounded-md'>{matchedChoice}</div>
      )}
    </div>
  );
};

const DragAndDropComponent = ({
  content,
  choices,
  questions,
  timeLimit,
  onSubmit,
  type,
  isTimeOut,
  onSubmitHandle,
  loading,
}) => {
  const [answers, setAnswers] = useState(
    questions.map((question) => ({ answer: question, matchedChoice: null }))
  );

  const [remainingTime, setRemainingTime] = useState(timeLimit);

  const handleDrop = (item, answer) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((ans) =>
        ans.answer === answer ? { ...ans, matchedChoice: item.choice } : ans
      )
    );
  };

  const handleAutoSubmit = () => {
    const result = answers.map((ans) => ({
      question: ans.answer,
      userAnswer: ans.matchedChoice,
    }));

    onSubmit({
      type,
      dropDownQuestions: [...result],
    });
  };

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(
        () => setRemainingTime((prev) => prev - 1),
        1000
      );
      return () => clearInterval(timer);
    } else {
      handleAutoSubmit();
    }
  }, [remainingTime]);

  useEffect(() => {
    if (isTimeOut) {
      handleAutoSubmit();
    }
  }, [isTimeOut]);

  return (
    <div>
      <h2 className='text-xl font-semibold mb-2'>Glisser-Déposer</h2>
      <p className='text-gray-700 mb-4'>{content}</p>

      <div className='grid grid-cols-2 gap-4 mt-4'>
        <div>
          <h3 className='font-bold mb-2'>Réponses</h3>
          {choices.map((choice, index) => (
            <DraggableChoice key={index} choice={choice} index={index} />
          ))}
        </div>

        <div>
          <h3 className='font-bold mb-2'>Questions</h3>
          {answers.map((ans, index) => (
            <DropZone
              key={index}
              answer={ans.answer}
              onDrop={handleDrop}
              matchedChoice={ans.matchedChoice}
            />
          ))}
        </div>
      </div>

      {loading ? (
        <div className=' w-[12%]'>
          <Spinner />
        </div>
      ) : (
        <button
          onClick={() => {
            const result = answers.map((ans) => ({
              question: ans.answer,
              userAnswer: ans.matchedChoice,
            }));
            onSubmitHandle({ type, dropDownQuestions: [...result] });
          }}
          className='bg-black text-white px-4 py-2 ml-2'
        >
          Soumettre
        </button>
      )}
    </div>
  );
};

export default DragAndDropComponent;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import useRouter from './useRouter';
import Layout from '../components/layout/Layout';
import { useSelector } from 'react-redux';

const Routers = () => {
  const { publicPath, adminPath } = useRouter();
  const { sideBarOpen } = useSelector((state) => state.state);

  return (
    <BrowserRouter>
      <Header />
      {sideBarOpen ? (
        <Layout>
          <Routes>
            {publicPath.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}

            {adminPath.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Routes>
          <Footer />
        </Layout>
      ) : (
        <>
          <Routes>
            {publicPath.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}

            {adminPath.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Routes>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

export default Routers;

import { useEffect, useState } from 'react';
import { FaThumbsUp, FaComment, FaTrash } from 'react-icons/fa';
import { formatRelativeTimeManual } from '../../utils/utils';
import {
  useFaqsAddCommentMutation,
  useGetOneFaqsQuery,
  useToggleLikeMutation,
} from '../../redux/slices/faqsApiSlice';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommentForm from '../../components/learning/CommentForm';
import Loading from '../../components/loading/Loading';

const FaqDetail = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const { data: faqs, isLoading, refetch } = useGetOneFaqsQuery({ token, id });

  const [faqsAddComment, { isLoading: faqLoading, isSuccess }] =
    useFaqsAddCommentMutation();

  const [toggleLike, { isLoading: likeLoading, isSuccess: LikeIsuccess }] =
    useToggleLikeMutation();

  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    if (isSuccess || LikeIsuccess) {
      refetch();
    }
  }, [isSuccess, LikeIsuccess]);

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      if (newComment.trim()) {
        const comment = {
          id,
          text: newComment,
          token,
        };

        await faqsAddComment(comment).unwrap();
        setNewComment('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!user && !user.token) {
    Navigate('/');
    return;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!faqs && !isLoading) {
    return <Navigate to='*' replace />;
  }

  const isLikedComment = faqs.like.indexOf(user._id);

  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-2xl font-bold mb-4'>{faqs?.question}</h1>
      <p
        className='text-gray-700 text-lg mb-6'
        dangerouslySetInnerHTML={{ __html: faqs?.response }}
      ></p>

      <div className='flex items-center justify-between mb-6'>
        <div className='text-gray-500 text-sm'>
          <p>
            <span className='font-semibold'>{faqs?.user?.name}</span> -{' '}
            {formatRelativeTimeManual(faqs?.createdAt)}
          </p>
        </div>
        <div className='flex items-center space-x-4 text-gray-500'>
          <div className='flex items-center space-x-1'>
            <button
              onClick={() => {
                toggleLike({ token, id });
              }}
            >
              {isLikedComment === -1 ? (
                <FaThumbsUp className='text-gray-500' />
              ) : (
                <FaThumbsUp className='text-blue-500' />
              )}
            </button>

            <span>{faqs?.like.length} Like(s)</span>
          </div>
          <div className='flex items-center space-x-1'>
            <FaComment className='text-gray-500' />
            <span>{faqs?.comments?.length} Commentaire(s)</span>
          </div>
        </div>
      </div>

      <CommentForm
        newComment={newComment}
        setNewComment={setNewComment}
        handleAddComment={handleAddComment}
      />

      <h3 className='text-lg font-semibold mb-4'>Commentaires</h3>
      <div className='space-y-4'>
        {faqs &&
          faqs.comments &&
          faqs.comments.length > 0 &&
          faqs.comments.map((comment, index) => (
            <div
              key={index}
              className='flex justify-between items-start bg-gray-100 p-4 rounded-lg relative'
            >
              <div>
                <p className='font-semibold'>{comment.user.name}</p>
                <p className='text-sm text-gray-700'>{comment.text}</p>
                <p className='text-xs text-gray-500'>
                  {formatRelativeTimeManual(comment.createdAt)}
                </p>
              </div>
              <button className='absolute top-3 right-3 text-red-500 hover:text-red-700'>
                <FaTrash />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FaqDetail;

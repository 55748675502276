import { FaAward, FaEnvelope } from 'react-icons/fa';

const CertificatCard = ({
  removeStudent = () => {},
  isAdim = false,
  certificat,
  isAdminEdition = false,
}) => {
  return (
    <div className='bg-white relative shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1'>
      {isAdim && isAdminEdition && (
        <div className=' -z-40 absolute top-0 right-0 p-2'>
          <button onClick={() => removeStudent(certificat.studentId._id)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6 text-red-600'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18 18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>
      )}

      <div className='flex items-center mb-4'>
        <FaAward className='text-yellow-500 h-8 w-8 mr-3' />
        <h3 className='text-xl font-semibold text-gray-800'>
          Certificat en {certificat?.courseId?.title || 'Formation'}
        </h3>
      </div>

      <p className='text-gray-600 mb-4'>
        Date d'obtention :{' '}
        <span className='font-medium'>
          {new Date(certificat?.createdAt).toLocaleDateString()}
        </span>
      </p>
      <p className='text-gray-600 mb-2'>
        Institution : <span className='font-medium'>Académie Ceisna</span>
      </p>
      {isAdim && (
        <>
          <p className='text-gray-600 mb-2'>
            Étudiant :{' '}
            <span className='font-medium'>
              {certificat?.studentId?.name || 'N/A'}
            </span>
          </p>
          <p className='text-gray-600 mb-4 flex items-center'>
            <FaEnvelope className='mr-2 text-gray-500' />
            {certificat?.studentId?.email || 'Email indisponible'}
          </p>
        </>
      )}

      {!isAdim && (
        <div className='bg-blue-100 p-4 rounded-lg text-blue-800 text-sm mb-4'>
          <p>
            Votre certificat a été délivré avec succès. Un e-mail contenant
            votre certificat sera envoyé à votre adresse e-mail.
          </p>
        </div>
      )}
    </div>
  );
};

export default CertificatCard;

import { Link } from 'react-router-dom';

const StudentsItems = ({ data }) => {
  return (
    <Link
      to={`/${
        data.isTeacher ? 'admin/instructor/courses' : 'admin/student/courses'
      }/${data._id}`}
      className='bg-white p-4 rounded-lg shadow-sm flex flex-col items-center'
    >
      {data.imageUrl ? (
        <img
          className='w-16 h-16 rounded-full object-cover mb-4'
          src={data.imageUrl}
          alt={data.name}
        />
      ) : (
        <div className='w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-lg font-bold text-gray-600 mb-4'>
          {data.name
            .split(' ')
            .map((n) => n[0])
            .join('')}
        </div>
      )}
      <h3 className='text-xl font-semibold text-gray-700 mb-2'>{data.name}</h3>
      <p className='text-gray-500'>
        {data?.isTeacher ? 'Instructeur' : 'Etudiant'}
      </p>
    </Link>
  );
};

export default StudentsItems;

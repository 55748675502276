import React from 'react';
import Title from '../utilities/Title';

const Description = ({ data }) => {
  return (
    <div>
      <Title text='Description du cours' />
      <p
        dangerouslySetInnerHTML={{
          __html: data?.description,
        }}
        className=' text-[1rem] py-2'
      />
    </div>
  );
};

export default Description;

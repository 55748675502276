import React from 'react';
import { Link } from 'react-router-dom';

const CompetenceList = ({ competence }) => {
  return (
    <li>
      <div
        className=' hover:border-[3px]  transition-all py-1 px-4 border-[1px] border-gray-300 rounded-full '
        // to={'/'}
      >
        {competence}
      </div>
    </li>
  );
};

export default CompetenceList;

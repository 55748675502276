import { Link } from 'react-router-dom';
import { formatRelativeTimeManual } from '../../utils/utils';
import { FaTrashAlt } from 'react-icons/fa';

const FaqsCard = ({ question, onDelete, isAdmin = false }) => {
  return (
    <div className='border-b border-gray-300 py-4 relative'>
      <Link to={`/faqs/${question._id} `} key={question.id}>
        {isAdmin && (
          <button
            onClick={() => onDelete(question._id)}
            className='absolute top-2 right-2 text-red-500 hover:text-red-700 focus:outline-none'
            aria-label='Supprimer la question'
          >
            <FaTrashAlt size={16} />
          </button>
        )}

        <div className='flex justify-between items-start mb-2'>
          <div>
            <p className='font-semibold'>{question.question}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: question.response,
              }}
              className='text-sm py-1 text-gray-700'
            />
            <p className='text-sm text-gray-500'>
              {question.user.name} -{' '}
              {formatRelativeTimeManual(question.createdAt)}
            </p>
          </div>
          <div className='text-right'>
            <p className='text-gray-600'>{question.like.length} Like(s)</p>
            <p className='text-gray-500'>
              {question.comments.length} commentaire(s)
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default FaqsCard;

import { formatTime } from '../../utils/utils';
import Title from '../utilities/Title';
import RecommmandationsItem from './RecommmandationsItem';

export default function Recommandations({ otherCourses }) {
  return (
    <div className=' py-4'>
      <Title text='Autres cours qui pourraient vous intéresser' />
      <div className=' grid-cols-1  pt-5 grid md:grid-cols-3 gap-6'>
        {otherCourses.map((course) => (
          <RecommmandationsItem
            imageUrl={course.imageUrl}
            title={course.title}
            category={course.category}
            temps={formatTime(course.time)}
            key={course._id}
            courseId={course._id}
          />
        ))}
      </div>
    </div>
  );
}

import { useDrag, useDrop } from 'react-dnd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { FaDownload, FaVideo } from 'react-icons/fa';
import Spinner from '../spinner/Spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  getYouTubeVideoId,
  uploadVideoAwsDuration,
  YouTubeVideoDuration,
} from '../../utils/utils';

const ItemTypes = {
  SECTION: 'section',
  LESSON: 'lesson',
};

const CoursLessons = ({
  lesson,
  sectionIndex,
  lessonIndex,
  moveLesson,
  updateLesson,
  handleDeleteLesson,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.LESSON,
    item: { sectionIndex, lessonIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.LESSON,
    hover: (draggedItem) => {
      if (
        draggedItem.sectionIndex === sectionIndex &&
        draggedItem.lessonIndex !== lessonIndex
      ) {
        moveLesson(draggedItem.lessonIndex, lessonIndex, sectionIndex);
        draggedItem.lessonIndex = lessonIndex;
      }
    },
  }));

  const handleAddPart = (partType) => {
    updateLesson(sectionIndex, lessonIndex, partType);
  };

  const handlePartChange = (partIndex, field, value) => {
    updateLesson(
      sectionIndex,
      lessonIndex,
      'updatePart',
      partIndex,
      field,
      value
    );
  };

  const handleRemovePart = (partIndex) => {
    updateLesson(sectionIndex, lessonIndex, 'removePart', partIndex);
  };

  const hasPart = lesson.parts.length > 0;
  const [uploadLoading, setuploadLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [choices, setChoices] = useState([]);
  const [choice, setChoice] = useState();
  const [choiceIndex, setChoiceIndex] = useState();
  const [choicesId, setChoicesId] = useState({});

  const [isUploaded, setIsUploaded] = useState(false);
  const [fleError, setFileError] = useState('');

  const handleFileChange = async (partIndex, file) => {
    const formData = new FormData();
    formData.append('doc', file);
    setIsUploaded(true);

    try {
      const res = await axios.post(
        'https://api.academieceisna.org/api/upload-doc',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res) {
        handlePartChange(partIndex, 'document', res.data.fileUrl);
        handlePartChange(partIndex, 'time', 1);
      }

      setIsUploaded(false);
    } catch (error) {
      setIsUploaded(false);
      console.error('Erreur lors du téléchargement:', error);
      setFileError('Erreur lors du téléchargement.');
    }
  };

  // const addChoice = () => {
  //   setChoices((prev) => [...prev, choice]);
  //   handlePartChange(choiceIndex, 'choices', [...choices, choice]);
  //   setChoice('');
  // };

  // const handleDeleteChoice = (index, chce) => {
  //   const updatedChoices = chce.filter((_, i) => i !== index);
  //   setChoices(updatedChoices);
  //   handlePartChange(choiceIndex, 'choices', [...updatedChoices]);
  // };

  // function ChoiceHandler(index, value) {
  //   setChoiceIndex(index);
  //   setChoice(value);
  // }

  const addChoice = () => {
    if (choice.trim()) {
      const updatedChoices = [...choices, choice];
      setChoices(updatedChoices);
      handlePartChange(choiceIndex, 'choices', updatedChoices);
      setChoice('');
    }
  };

  const handleDeleteChoice = (choiceIndex, idx, chces) => {
    const updatedChoices = (choices.length === 0 ? chces : choices).filter(
      (_, i) => i !== idx
    );

    setChoices(updatedChoices);
    handlePartChange(choiceIndex, 'choices', updatedChoices);
  };

  function ChoiceHandler(index, value) {
    setChoiceIndex(index);
    setChoice(value);
  }

  const uploadVideo = async (partIndex, file) => {
    const formData = new FormData();
    formData.append('video', file);
    setuploadLoading(true);
    // http://85.31.236.195:5000
    try {
      const res = await axios.post(
        'https://api.academieceisna.org/api/upload-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res) {
        // res.data.fileUrl
        const videoUrl = await uploadVideoAwsDuration(res.data.fileUrl);
        handlePartChange(partIndex, 'video', res.data.fileUrl);
        handlePartChange(partIndex, 'time', videoUrl);
      }

      setuploadLoading(false);
      setUploadStatus('Téléchargement réussi !');
    } catch (error) {
      setuploadLoading(false);
      console.error('Erreur lors du téléchargement:', error);
      setUploadStatus('Erreur lors du téléchargement.');
    }
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`p-3 border rounded-lg mb-2 ${
        isDragging ? 'bg-gray-200' : 'bg-gray-100'
      }`}
    >
      <input
        type='text'
        value={lesson.title}
        onChange={(e) =>
          updateLesson(
            sectionIndex,
            lessonIndex,
            'title',
            null,
            null,
            e.target.value
          )
        }
        className='font-medium text-md p-1 border rounded w-full mb-2'
      />

      <div className='mt-4'>
        {lesson.parts.map((part, index) => (
          <div key={index} className='p-2 mt-2 bg-gray-50 border rounded-lg'>
            {part.type === 'lecture' && (
              <>
                {uploadLoading ? (
                  <div className=' pt-4 w-[20%]'>
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <label>Uploader une video: </label>
                    <label
                      htmlFor={`video-upload-${lessonIndex}-${index}`}
                      className='cursor-pointer flex items-center justify-center px-4 py-2 bg-gray-200  rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:bg-gray-400 transition'
                    >
                      <FaVideo className='mr-2' />
                      {part.video ? 'Modifier la vidéo' : '+ vidéo'}
                    </label>
                    <input
                      id={`video-upload-${lessonIndex}-${index}`}
                      type='file'
                      accept='video/*'
                      className='hidden'
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setUploadStatus('Téléchargement en cours...');
                          uploadVideo(index, file);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {part.type === 'quiz' && (
              <div className=' flex flex-col w-full'>
                <div className=' flex-1 flex space-x-4'>
                  <input
                    type='text'
                    placeholder='Question'
                    value={part.question || ''}
                    onChange={(e) => {
                      handlePartChange(index, 'question', e.target.value);
                      handlePartChange(index, 'time', 1);
                    }}
                    className='mb-2 flex-1 p-1 border rounded'
                  />

                  <select
                    value={part.choiceType || ''}
                    onChange={(e) => {
                      handlePartChange(index, 'choiceType', e.target.value);
                    }}
                    className='p-1 border rounded'
                  >
                    <option value=''>Selectionner </option>
                    <option value='multiple'>Choix Multiple </option>
                    <option value='true-false'>Vrai/Faux</option>
                  </select>
                </div>

                <input
                  type='text'
                  placeholder='bonne reponse'
                  value={part.answer || ''}
                  onChange={(e) => {
                    handlePartChange(index, 'answer', e.target.value);
                  }}
                  className=' flex-1 p-1 border rounded'
                />

                <div className='mt-2 flex items-center'>
                  <input
                    type='text'
                    placeholder='Reponse'
                    value={choice}
                    onChange={(e) => {
                      ChoiceHandler(index, e.target.value);
                    }}
                    className=' flex-1 p-1 border rounded'
                  />
                  <button
                    onClick={addChoice}
                    className='px-2 py-1 bg-green-200 rounded'
                  >
                    +
                  </button>
                </div>

                <div className='mt-4'>
                  {part?.choices?.map((choice, idx) => (
                    <div
                      key={idx}
                      className='flex items-center justify-between mb-2'
                    >
                      <span>{choice}</span>
                      <button
                        className='text-red-500 hover:underline ml-4'
                        onClick={() =>
                          handleDeleteChoice(index, idx, part.choices)
                        }
                      >
                        Supprimer
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {part.type === 'texte' && (
              <ReactQuill
                theme='snow'
                style={{ minHeight: '100px' }}
                value={part.text || ''}
                onChange={(value) => {
                  handlePartChange(index, 'text', value);
                  handlePartChange(index, 'time', 1);
                }}
                className='mb-4'
                placeholder='Ajouter un texte'
              />
            )}

            {part.type === 'document' && (
              <>
                {isUploaded ? (
                  <div className=' w-[16%]'>
                    <Spinner />
                  </div>
                ) : (
                  <div className='flex flex-col items-start'>
                    <label className='cursor-pointer flex items-center text-[#000]'>
                      <FaDownload className='mr-2 text-2xl' />
                      <span>
                        {part.document
                          ? 'Document ajouté ! Cliquez pour modifier'
                          : 'Télécharger'}
                      </span>
                      <input
                        accept='.pdf, .doc, .docx, .txt'
                        type='file'
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            handleFileChange(index, file);
                          }
                        }}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </div>
                )}
              </>
            )}

            {part.type === 'lien youtube' && (
              <input
                placeholder='Ajouter le lien de la vidéo'
                value={part.youtubeLink || ''}
                className='font-medium text-md p-1 border rounded w-full mb-2'
                onChange={(e) =>
                  handlePartChange(index, 'youtubeLink', e.target.value)
                }
              />
            )}

            {part.type === 'genialy' && (
              <input
                placeholder='Ajouter le lien'
                value={part.genialy || ''}
                className='font-medium text-md p-1 border rounded w-full mb-2'
                onChange={async (e) => {
                  handlePartChange(index, 'genialy', e.target.value);
                  handlePartChange(index, 'time', 1);
                }}
              />
            )}

            {part.type === 'canva' && (
              <input
                placeholder='Ajouter le lien'
                value={part.canva || ''}
                className='font-medium text-md p-1 border rounded w-full mb-2'
                onChange={async (e) => {
                  handlePartChange(index, 'canva', e.target.value);
                  handlePartChange(index, 'time', 1);
                }}
              />
            )}

            <button
              onClick={() => handleRemovePart(index)}
              className='mt-2 px-2 py-1  hover:text-red-600 text-red-700 rounded-lg'
            >
              <span>
                Supprimer{' '}
                {part.type.charAt(0).toUpperCase() + part.type.slice(1)}
              </span>
            </button>
          </div>
        ))}
      </div>

      {!hasPart && (
        <div className='flex space-x-2 mt-2'>
          <button
            onClick={() => handleAddPart('lecture')}
            className='px-2 py-1 bg-green-200 rounded'
          >
            + Lecture
          </button>
          <button
            onClick={() => handleAddPart('quiz')}
            className='px-2 py-1 bg-yellow-200 rounded'
          >
            + Quiz
          </button>

          <button
            onClick={() => handleAddPart('genialy')}
            className='px-2 py-1    bg-indigo-200 rounded'
          >
            + Genialy
          </button>

          <button
            onClick={() => handleAddPart('canva')}
            className='px-2 py-1  bg-blue-300 rounded'
          >
            + Canva
          </button>

          <button
            onClick={() => handleAddPart('texte')}
            className='px-2 py-1  bg-lime-200 rounded'
          >
            + contenu
          </button>
          <button
            onClick={() => handleAddPart('document')}
            className='px-2 py-1  bg-orange-200 rounded'
          >
            + Document
          </button>
          <button
            onClick={() => handleAddPart('lien youtube')}
            className='px-2 py-1  bg-red-300 rounded'
          >
            + Lien Youtube
          </button>
        </div>
      )}

      <button
        onClick={() => handleDeleteLesson(sectionIndex, lessonIndex)}
        className='mt-2 px-2 py-1  rounded-lg'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='size-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M6 18 18 6M6 6l12 12'
          />
        </svg>
      </button>
    </div>
  );
};

export default CoursLessons;

import React, { useEffect } from 'react';
import './textcontent.css';

const TextContent = ({ lesson, onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, [lesson, onComplete]);

  return (
    <div
      className='min-h-[50vh] flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-200 p-6'
      id='text-content'
    >
      <div className='pt-4 w-[80%] mx-auto'>
        <div
          className='text-content'
          dangerouslySetInnerHTML={{ __html: lesson.parts[0]?.text }}
        ></div>
      </div>
    </div>
  );
};

export default TextContent;

import { useEffect } from 'react';

const GenialyView = ({ lesson, onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 1000);

    return () => clearTimeout(timer);
  }, [lesson, onComplete]);

  if (!lesson?.parts?.[0]?.genialy) {
    return (
      <p className='text-red-500'>
        Le contenu de la leçon n'est pas disponible.
      </p>
    );
  }

  return (
    <div className='w-full  py-1 '>
      <div className='relative h-[600px]'>
        <iframe
          title='CEISNA'
          src={lesson.parts[0].genialy}
          className='absolute top-0 left-0 w-full h-full'
          allow='fullscreen; autoplay; encrypted-media'
          sandbox='allow-same-origin allow-scripts allow-popups'
        />
      </div>
    </div>
  );
};

export default GenialyView;

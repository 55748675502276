import { Link, useLocation } from 'react-router-dom';

const LayoutItem = ({ url, texte }) => {
  const location = useLocation();
  return (
    <li className=' w-full'>
      <Link
        to={url}
        className={`${
          location.pathname === url ? 'bg-gray-100' : ''
        }  transition-all px-3 py-[0.3rem] w-full hover:bg-gray-100 cursor-pointer flex items-center`}
      >
        {texte}
      </Link>
    </li>
  );
};

export default LayoutItem;

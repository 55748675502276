import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const EndAssements = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className='h-[80vh] flex flex-col items-center justify-center text-center bg-gray-100 p-6 rounded-lg shadow-md'>
      <h2 className='text-xl font-bold text-red-600 mb-2'>
        Accès à l'évaluation terminé
      </h2>
      <p className='text-gray-700'>
        Le temps imparti pour cette évaluation est écoulé. Merci pour votre
        participation. Veuillez contacter votre instructeur pour plus
        d’informations ou pour obtenir une nouvelle tentative, si nécessaire.
      </p>
      <button
        onClick={handleGoBack}
        className='bg-black flex items-center mt-3 text-white px-4 py-2 ml-2'
      >
        <FaArrowLeft className='mr-2' /> retour a l'accueil
      </button>
    </div>
  );
};

export default EndAssements;

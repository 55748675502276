import { useState, useEffect } from 'react';
import FullButton from '../../components/utilities/FullButton';
import AuthLayout from '../../components/auth/AuthLayout';
import {
  useGetProfilQuery,
  useUpdateProfilMutation,
} from '../../redux/slices/userApiSlice';
import axios from 'axios';
import Spinner from '../../components/spinner/Spinner';
import SuccessMessage from '../../components/message/SuccessMessage';
import ErrorMessage from '../../components/message/ErrorMessage';
import { useSelector } from 'react-redux';

const EditProfile = () => {
  const [successUpdate, setSuccessUpdate] = useState();
  const [errorUpdate, setErrorUpdate] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { user: auth } = useSelector((state) => state.auth);

  const [updateProfil, { isLoading, isSuccess, isError }] =
    useUpdateProfilMutation();

  const token = auth?.token;
  const {
    data,
    isLoading: profilLoading,
    isSuccess: profilSuccess,
    isError: profilError,
  } = useGetProfilQuery(token);

  const [user, setUser] = useState({
    name: '',
    userName: '',
    imageUrl: '',
    token: token,
  });

  const [newPassword, setNewPassword] = useState({
    password: '',
    confirmePassword: '',
  });

  useEffect(() => {
    if (profilSuccess && data) {
      setUser({
        name: data.name || '',
        userName: data.userName || '',
        imageUrl: data.imageUrl || '',
        token: token,
      });
    }
  }, [profilSuccess, data]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(
        `https://api.academieceisna.org/api/upload-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      setUploadProgress(0);
      setUser({ ...user, imageUrl: response.data.imagePath });
    } catch (error) {
      console.error('Erreur lors du téléchargement :', error);
      setErrorUpdate("Le téléchargement de l'image a échoué.");
      throw error;
    }
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setNewPassword({
      ...newPassword,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTimeout(() => {
      setSuccessUpdate('');
    }, 5000);

    try {
      await updateProfil(user).unwrap();
      setSuccessUpdate('Profil mis à jour avec succès');
    } catch (error) {
      console.log(error);
      setErrorUpdate('Erreur de mise à jour du profil');
    }
  };

  return (
    <main>
      <AuthLayout>
        <div className='w-full'>
          <div className='bg-white p-6 rounded-lg shadow-lg w-full'>
            {successUpdate && <SuccessMessage message={successUpdate} />}
            {errorUpdate && <ErrorMessage message={errorUpdate} />}
            <h4 className='text-[1.1rem] mb-2 text-center'>
              Modifier le profil
            </h4>

            <form onSubmit={handleSubmit} className='space-y-6 flex flex-col'>
              <div className='flex flex-col items-center'>
                {user?.imageUrl ? (
                  <img
                    src={user?.imageUrl}
                    alt='Profile'
                    className='w-24 h-24 rounded-full object-cover mb-4'
                  />
                ) : (
                  <div className='w-24 h-24 bg-gray-300 rounded-full flex items-center justify-center text-lg font-bold text-gray-600 mb-4'>
                    {user?.name
                      ?.split(' ')
                      .map((n) => n[0])
                      .join('')}
                  </div>
                )}

                <label className='text-blue-500 cursor-pointer'>
                  <input
                    type='file'
                    className='hidden'
                    accept='image/*'
                    onChange={handleImageChange}
                  />
                  Modifier l'image de profil
                </label>
              </div>

              <div>
                <label className='block text-gray-700'>Nom</label>
                <input
                  type='text'
                  name='name'
                  value={user.name}
                  onChange={handleChange}
                  className='w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                  placeholder='Nom'
                />
              </div>

              {/* Email */}
              <div>
                <label className='block text-gray-700'>Nom d'utilisateur</label>
                <input
                  type='text'
                  name='userName'
                  value={user.userName}
                  onChange={handleChange}
                  className='w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                  placeholder="Nom d'utilisateur"
                />
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <FullButton
                  text='Enregistrer les modifications'
                  type='submit'
                />
              )}
            </form>

            {/* Formulaire de modification du mot de passe */}
            <form onSubmit={handleSubmit} className='space-y-6 flex flex-col'>
              <div>
                <label className='block text-gray-700'>Mot de passe</label>
                <input
                  type='password'
                  name='password'
                  value={newPassword.password}
                  onChange={handlePasswordChange}
                  className='w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                  placeholder='Mot de passe'
                />
              </div>
              <div>
                <label className='block text-gray-700'>
                  Confirmer le mot de passe
                </label>
                <input
                  type='password'
                  name='confirmePassword'
                  value={newPassword.confirmePassword}
                  onChange={handlePasswordChange}
                  className='w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                  placeholder='Confirmer le mot de passe'
                />
              </div>
              <FullButton text='Modifier le mot de passe' type='submit' />
            </form>
          </div>
        </div>
      </AuthLayout>
    </main>
  );
};

export default EditProfile;

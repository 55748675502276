import { useState, useEffect } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import {
  FaFileAlt,
  FaQuestionCircle,
  FaFilePdf,
  FaClipboard,
} from 'react-icons/fa';
import { formatMinutesToHourMinute } from '../../utils/utils';

const StudentCourseContent = ({ onFirstOpen, sections, onLessonClick }) => {
  const [openChapter, setOpenChapter] = useState([]);

  return (
    <div className='w-full container p-4 my-10 mx-auto'>
      {sections?.map((chapter, index) => (
        <div key={index}>
          <div
            onClick={() =>
              setOpenChapter((prev) =>
                prev.includes(index)
                  ? prev.filter((p) => p !== index)
                  : [...prev, index]
              )
            }
            className='border-b-2 py-2 border-[#cacaca] px-5 bg-[#e6e6e6] flex items-start justify-between cursor-pointer'
          >
            <div>
              <h2 className='text-[1rem] font-semibold text-gray-800 mb-1'>
                {chapter.title}
              </h2>
              <div>
                <span>
                  Durée totale estimée :
                  {formatMinutesToHourMinute(chapter.time || 0)}
                </span>
              </div>
            </div>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className={`${
                  openChapter.includes(index) ? 'rotate-180' : 'rotate-0'
                } transition-all duration-300 ease-in-out h-4`}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='m19.5 8.25-7.5 7.5-7.5-7.5'
                />
              </svg>
            </div>
          </div>

          <ul
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              openChapter.includes(index)
                ? 'max-h-[1000px] opacity-100'
                : 'max-h-0 opacity-0'
            }`}
          >
            {chapter.lessons.map((lesson, idx) => (
              <li
                key={idx}
                onClick={() => {
                  onFirstOpen(false);
                  onLessonClick(lesson);
                }}
                className='hover:bg-[#e6e6e6] pl-8 cursor-pointer flex justify-between items-center py-2 pr-4 shadow-sm'
              >
                <div className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    checked={lesson.parts[0].isCompled}
                    onChange={() => {}}
                    className={`w-4 h-4 border-2 ${
                      lesson.parts[0].isCompled
                        ? 'bg-[#000000] border-[#000000]'
                        : ''
                    } cursor-pointer`}
                    style={{
                      accentColor: lesson.parts[0].isCompled ? '#000000' : '',
                    }}
                  />
                  <span className='text-gray-700'>{lesson.title}</span>
                </div>
                <div className='flex items-center space-x-2'>
                  <span>
                    {lesson.parts[0].type === 'texte' ? (
                      <span className='text-gray-500'>
                        <FaFileAlt size={16} />
                      </span>
                    ) : lesson.parts[0].type === 'quiz' ? (
                      <span className='text-gray-500'>
                        <FaQuestionCircle size={16} />
                      </span>
                    ) : lesson.parts[0].type === 'lecture' ? (
                      <span className='text-gray-500'>
                        <MdPlayCircleFilled size={18} />
                      </span>
                    ) : lesson.parts[0].type === 'document' ? (
                      <span className='text-gray-500'>
                        <FaFilePdf size={16} />
                      </span>
                    ) : lesson.parts[0].type === 'evaluation' ? (
                      <span className='text-gray-500'>
                        <FaClipboard size={16} />
                      </span>
                    ) : lesson.parts[0].type === 'lien youtube' ? (
                      <span className='text-gray-500'>
                        <MdPlayCircleFilled size={16} />
                      </span>
                    ) : null}
                  </span>
                  <span className='text-sm text-gray-500'>
                    {formatMinutesToHourMinute(lesson.parts[0].time || 0)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default StudentCourseContent;

import { FaUser, FaEnvelope } from 'react-icons/fa';

const StudentCard = ({ student, onClick }) => (
  <div
    key={student._id}
    onClick={() => onClick(student._id)}
    className=' w-full cursor-pointer flex items-center bg-white shadow-md rounded-lg p-4 mb-3 transition-transform duration-200 ease-in-out transform hover:scale-105 hover:shadow-lg'
  >
    <div className='flex-shrink-0 bg-blue-500 text-white rounded-full h-12 w-12 flex items-center justify-center mr-2'>
      <FaUser className='h-6 w-6' />
    </div>
    <div className='flex flex-col'>
      <p className='text-lg font-semibold text-gray-800'>{student.name}</p>
      <p className='text-gray-600 flex items-center'>
        <FaEnvelope className='mr-2 text-gray-500' /> {student.email}
      </p>
    </div>
  </div>
);

export default StudentCard;

import { MESSAGE_URL } from '../constants/constants';
import { apiSlice } from './apiSlice';

export const messageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendBecomeTutor: builder.mutation({
      query: (data) => ({
        url: MESSAGE_URL + '/become-tutor',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendBecomeTutorMutation } = messageApiSlice;

import { useEffect, useState } from 'react';
import CommentForm from './CommentForm';
import StarSelect from './StarSelect';
import {
  useAddOrUpdateLikeMutation,
  useAddOrUpdateUnlikeMutation,
  useAddReviewMutation,
  useGetReviewsQuery,
} from '../../redux/slices/reviewApiSlice';
import { formatRelativeTimeManual } from '../../utils/utils';
import Loading from '../loading/Loading';

const ReviewsSection = ({ token, id, courseId }) => {
  const {
    data: reviews,
    isLoading: reviewsLoading,
    isSuccess: reviewSuccess,
    isError: errorLoading,
    refetch,
  } = useGetReviewsQuery(courseId);
  const [visibleReviewsCount, setVisibleReviewsCount] = useState(5);
  const [filteredReview, setFilteredReview] = useState(
    reviews?.reviews[0]?.reviews || []
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [ratingFilter, setRatingFilter] = useState('Toutes les notes');
  const [newComment, setNewComment] = useState('');
  const [star, setStar] = useState();
  const [addReview, { isLoading, isSuccess, isError }] = useAddReviewMutation();

  const [
    addOrUpdateUnlike,
    {
      isLoading: unlikeLoading,
      isSuccess: unlikeSuccess,
      isError: unlikeError,
    },
  ] = useAddOrUpdateUnlikeMutation();

  const [
    addOrUpdateLike,
    { isLoading: likeLoading, isSuccess: likeSuccess, isError: likeError },
  ] = useAddOrUpdateLikeMutation();

  useEffect(() => {
    if (isSuccess) {
      setNewComment('');
      setStar('');
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if ((unlikeSuccess && !unlikeLoading) || (likeSuccess && !likeLoading)) {
      refetch();
    }
  }, [unlikeSuccess, likeSuccess]);

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addReview({
        courseId: courseId,
        star: star,
        comment: newComment,
        token,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const unlikeHandler = async (reviewId) => {
    try {
      await addOrUpdateUnlike({ token, courseId, reviewId }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const likeHandler = async (reviewId) => {
    try {
      await addOrUpdateLike({ token, courseId, reviewId }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  const filterHandler = () => {
    let filtered = reviews?.reviews[0]?.reviews || [];
    if (searchTerm.trim()) {
      filtered = filtered.filter((review) =>
        review.comment.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (ratingFilter !== 'Toutes les notes') {
      filtered = filtered.filter(
        (review) => review.star === parseInt(ratingFilter, 10)
      );
    }

    setFilteredReview(filtered);
  };

  const loadMoreReviews = () => setVisibleReviewsCount((prev) => prev + 5);
  useEffect(() => {
    filterHandler();
  }, [reviews]);

  const handleSearch = () => {
    filterHandler();
  };

  if (reviewsLoading) {
    return <Loading />;
  }

  return (
    <div className='container mx-auto p-4'>
      <div className='mb-8'>
        <h2 className='font-semibold text-2xl mb-2'>Retour des étudiants</h2>
        <div className='flex items-center'>
          <div className='text-4xl font-bold mr-4'>
            {reviews?.reviews[0]?.rating ?? 0}
          </div>
          <div>
            {Array.from({ length: 5 }, (_, index) => (
              <span
                key={index}
                className={`text-xl ${
                  index < reviews?.reviews[0]?.rating ?? 0
                    ? 'text-yellow-500'
                    : 'text-gray-500'
                }`}
              >
                ★
              </span>
            ))}
            <div className='text-gray-500'>Évaluation du cours</div>
          </div>
        </div>
        <div className='mt-4'>
          {reviews?.percentages?.map((pourcentage, index) => (
            <div key={index} className='flex items-center space-x-1 mb-1'>
              <span>{5 - index} </span>
              <span className='text-yellow-500'>★★★★★</span>
              <div className='w-full bg-gray-200 h-2 mx-2 relative'>
                <div
                  className='bg-yellow-500 h-2'
                  style={{ width: `${pourcentage}%` }}
                ></div>
              </div>
              <span className='text-gray-500'>{pourcentage}%</span>
            </div>
          ))}
        </div>
      </div>

      <div className='mb-8'>
        <h3 className='font-semibold text-xl mb-2'>Avis</h3>
        <div className='flex mb-4'>
          <input
            type='text'
            placeholder='Rechercher des avis'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='border border-gray-300 px-4 py-2 w-full max-w-lg'
          />
          <button
            onClick={handleSearch}
            className='bg-black text-white px-4 py-2 ml-2'
          >
            Rechercher
          </button>
        </div>

        <StarSelect
          isfilter={true}
          rating={ratingFilter}
          setRating={setRatingFilter}
        />

        {filteredReview?.slice(0, visibleReviewsCount)?.map((review, index) => (
          <div key={index} className='border-b border-gray-300 py-4'>
            <div className='flex items-center mb-2'>
              <div className='bg-gray-300 rounded-full w-10 h-10 flex items-center justify-center mr-4'>
                {review.user.name

                  .split(' ')
                  .map((n) => n[0])
                  .join('')}
              </div>
              <div>
                <p className='font-bold'>{review.user.name}</p>
                <p className='text-gray-500 text-sm'>
                  {formatRelativeTimeManual(review.createdAt)}
                </p>
              </div>
            </div>
            <div className='text-yellow-500 mb-2'>
              {'★'.repeat(review.star)}
            </div>
            <p className='mb-4'>{review.comment}</p>
            <div className='flex space-x-4'>
              <button
                onClick={() => likeHandler(review._id)}
                className='flex items-center space-x-1'
              >
                <span>👍</span>
                <span>{review.like.length}</span>
              </button>
              <button
                onClick={() => unlikeHandler(review._id)}
                className='flex items-center space-x-1'
              >
                <span>👎</span>
                <span>{review.unLike.length}</span>
              </button>
            </div>
          </div>
        ))}

        {filteredReview.length > visibleReviewsCount && (
          <div>
            <button
              onClick={loadMoreReviews}
              className='hover:bg-blue-100 mt-2 font-normal rounded-full text-blue-950 px-4 py-1'
            >
              Afficher plus d'avis
            </button>
          </div>
        )}
      </div>
      <CommentForm
        setStar={setStar}
        star={star}
        isSelect={true}
        newComment={newComment}
        setNewComment={setNewComment}
        handleAddComment={handleAddComment}
      />
    </div>
  );
};

export default ReviewsSection;
